import { useContext, useEffect, useState } from "react";
import { useSelection } from "contexts/Schedules";
import { tTransportCard } from "types/transport";
import { tInstructionCard } from "types/instruction";
import { MstDataContext } from "contexts/Mst";
import { Container, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DragTransportCardArea } from "components/TransportCard";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tInstruction, tInstSearch } from "types/instruction";
import {
  getInstructionCards,
  getTempInstructionList,
} from "functions/api/instruction";
import {
  getCharWeekday,
  changeDateFromTypeDate,
  getCharWeekdayENG,
  isSameDate,
} from "functions/time";
import instSearchDefault from "const/instruction/search";
import Loading from "atoms/Loading";
import * as InstCard from "components/instruction/InstructionCard";
import * as TmpInstCard from "components/instruction/Card";
import InstrcutionThemeProvider from "themes/InstructionTheme";
import initInstruction from "const/instruction";
import InstructionModal from "components/instruction/Modal";
import * as InstButton from "components/instruction/Button";
import { ListItem } from "types/index";
import { tTransportMethod } from "types/mst";

const ddType = "TransportCard";

export default function Main() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { transportCards: transports } = useSelection();

  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            flexGrow: 1, // 残りの高さを占める
            transition: "height 0.3s ease",
            overflow: "auto", // 高さが足りない時にスクロールバーを表示
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: theme.spacing(2),
              m: theme.spacing(2),
            }}
          >
            <InstButton.InsertUpdate
              transports={transports}
              callbackSuccess={() => {
                //navigate("/operation/index");
              }}
              callbackError={() => {}}
            />
          </Box>
          <DriverArea />
        </Box>
        {/* Bのエリア: クリックで高さが0 -> 100pxに変わる */}
        <Container
          sx={{
            height: "auto",
            backgroundColor: theme.palette.common.white,
            transition: "height 0.3s ease",
            //overflow: "hidden", // 高さが0の時にコンテンツが見えないように
          }}
        >
          <DragTransportCardArea
            ddType={ddType}
            transportCards={transports}
            optionNode={
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/full-screen/schedules");
                }}
              >
                予定表へ
              </Button>
            }
          />
        </Container>
      </Box>
    </DndProvider>
  );
}

function DriverArea() {
  const theme = useTheme();

  const { drivers, tranMethods, SYSTEM } = useContext(MstDataContext);

  const [instructions, setInstructions] = useState<tInstructionCard[]>([]);
  const [tmpInstructions, setTmpInstructions] = useState<tInstruction[]>([]);
  const [openInstModal, setOpenInstModal] = useState(false);
  const [dropInst, setDropInst] = useState<tInstruction>();

  const [searchInst, setSearchInst] = useState<tInstSearch>(instSearchDefault);

  const instOrder = [
    { field: "start_datetime", direction: "asc" },
    { field: "tm_id", direction: "asc" },
    { field: "driver_id", direction: "asc" },
    { field: "c_id", direction: "asc" },
    { field: "end_datetime", direction: "asc" },
    { field: "pj_id", direction: "asc" },
    { field: "no", direction: "asc" },
    { field: "order", direction: "asc" },
  ];

  const [operations, setOperations] = useState<{
    [key: number]: tTransportCard;
  }>({});

  const handleDrop = (item: tInstruction) => {
    // 運行指示入力モーダルを表示
    setOpenInstModal(true);
    setDropInst(item);
  };

  const closeInstModal = () => {
    setOpenInstModal(false);
    getTempInstructionList(
      (data) => {
        setTmpInstructions(data);
      },
      () => {},
      [],
      []
    );
  };

  useEffect(() => {
    if (!openInstModal) {
      setDropInst(undefined);
    }
  }, [openInstModal]);

  useEffect(() => {
    // 運行指示取得
    getInstructionCards(
      (data: tInstructionCard[]) => setInstructions(data),
      () => {},
      searchInst,
      instOrder
    );

    // 作成途中があれば取得
    getTempInstructionList(
      (data) => {
        setTmpInstructions(data);
      },
      () => {},
      [],
      []
    );
  }, []);

  // 行ヘッダの日付
  const [dateRange, setDateRange] = useState<string[]>([]);
  useEffect(() => {
    const startDate = new Date(searchInst.start_datetime_from || "");
    const endDate = new Date(searchInst.start_datetime_to || "");
    const dates: string[] = [];

    // 日付範囲をループして配列に追加
    for (
      let date = startDate;
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      dates.push(changeDateFromTypeDate(new Date(date))); // YYYY-MM-DD形式
    }

    setDateRange(dates);
  }, [searchInst.start_datetime_from, searchInst.start_datetime_to]);

  return (
    <InstrcutionThemeProvider>
      <Box>
        <InstructionModal
          open={openInstModal}
          onClose={closeInstModal}
          pjId={dropInst?.pj_id || 0}
          tranNo={dropInst?.no || 0}
          info={dropInst}
          callbackNomal={() => {
            closeInstModal();
            // 表示するtmpデータに追加する
          }}
          callbackError={() => {}}
        />
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className="day row-head">日付</TableCell>
                {drivers?.map((driver) => {
                  return (
                    <TableCell
                      key={`head-driver-${driver.id}`}
                      className="driver"
                    >
                      {driver.label}
                    </TableCell>
                  );
                })}
                {tranMethods
                  ?.filter((tm) => tm.id !== SYSTEM?.tranMethod.own.id)
                  .map((tm) => {
                    return (
                      <TableCell
                        key={`head-tranMethod-${tm.id}`}
                        className="driver"
                      >
                        {tm.abbreviation}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {dateRange.map((date) => {
                const rowData = instructions.filter((inst) => {
                  return isSameDate(inst.start_datetime, date);
                });
                const rowTmpData = tmpInstructions.filter((inst) => {
                  return isSameDate(inst.start_datetime, date);
                });

                return (
                  <DayRow
                    key={`dayrow-${date}`}
                    day={date}
                    data={rowData}
                    tmpData={rowTmpData}
                    callbackDrop={handleDrop}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </InstrcutionThemeProvider>
  );
}

const DayRow = ({
  day,
  data,
  tmpData,
  callbackDrop,
}: {
  day: string;
  data: tInstructionCard[];
  tmpData: tInstruction[];
  callbackDrop: (item: tInstruction) => void;
}) => {
  const weekdayJP = getCharWeekday(day); // 曜日
  const weekdayENG = getCharWeekdayENG(day); // 曜日(英語)

  const { loading, drivers, tranMethods, SYSTEM } = useContext(MstDataContext);

  const DataCellDriver = ({
    driver,
    driverData,
    driverTmpData,
  }: {
    driver: ListItem;
    driverData: tInstructionCard[];
    driverTmpData: tInstruction[];
  }) => {
    const [{ isOver }, drop] = useDrop(() => ({
      accept: ddType,
      drop: (item: tTransportCard) => {
        console.log("drop");
        const defInst: tInstruction = {
          ...initInstruction,
          pj_id: item.pj_id,
          no: item.tran_no,
          start_datetime: `${day} 00:00:00`,
          tm_id: SYSTEM?.tranMethod.own.id || 1,
          user_id: driver.id,
        };
        callbackDrop(defInst);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }));

    return (
      <TableCell
        className={`${driver.label} ${isOver ? "isOver" : ""}`}
        ref={drop}
        sx={{
          ...(isOver && { backgroundColor: "rgba(0, 128, 0, 0.2)" }), // ドロップ中の視覚的な変化を追加
        }}
      >
        <Box>
          {driverData.map((inst: tInstructionCard) => {
            return (
              <InstCard.WithToolchip
                type="line"
                labelType="driver"
                key={`inst-${inst.id}`}
                instruction={inst}
                flgOmit={false}
                toolchipChildren={
                  <>
                    <Typography>{`${inst.start_name}`}</Typography>
                    <Typography>{`${inst.end_name}`}</Typography>
                  </>
                }
              />
            );
          })}
          {driverTmpData.map((inst: tInstruction) => {
            return (
              <TmpInstCard.WithToolchip
                type="line"
                labelType="driver"
                key={`tmpinst-${inst.id}`}
                instruction={inst}
                flgOmit={false}
                toolchipChildren={
                  <>
                    <Typography>{`${inst.start_name}`}</Typography>
                    <Typography>{`${inst.end_name}`}</Typography>
                  </>
                }
              />
            );
          })}
        </Box>
      </TableCell>
    );
  };

  const DataCellMethod = ({
    method,
    methodData,
    methodTmpData,
  }: {
    method: tTransportMethod;
    methodData: tInstructionCard[];
    methodTmpData: tInstruction[];
  }) => {
    const [{ isOver }, drop] = useDrop(() => ({
      accept: ddType,
      drop: (item: tTransportCard) => {
        console.log("drop");
        const defInst: tInstruction = {
          ...initInstruction,
          pj_id: item.pj_id,
          no: item.tran_no,
          start_datetime: `${day} 00:00:00`,
          tm_id: method.id,
        };
        callbackDrop(defInst);
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }));

    return (
      <TableCell
        className={`head-tranMethod-${method.id} ${isOver ? "isOver" : ""}`}
        ref={drop}
        sx={{
          ...(isOver && { backgroundColor: "rgba(0, 128, 0, 0.2)" }), // ドロップ中の視覚的な変化を追加
        }}
      >
        <Box>
          {methodData.map((inst: tInstructionCard) => {
            return (
              <InstCard.WithToolchip
                type="line"
                labelType="driver"
                key={`inst-${inst.id}`}
                instruction={inst}
                flgOmit={false}
                toolchipChildren={
                  <>
                    <Typography>{`${inst.start_name}`}</Typography>
                    <Typography>{`${inst.end_name}`}</Typography>
                  </>
                }
              />
            );
          })}
          {methodTmpData.map((inst: tInstruction) => {
            return (
              <TmpInstCard.WithToolchip
                type="line"
                labelType="driver"
                key={`tmpinst-${inst.id}`}
                instruction={inst}
                flgOmit={false}
                toolchipChildren={
                  <>
                    <Typography>{`${inst.start_name}`}</Typography>
                    <Typography>{`${inst.end_name}`}</Typography>
                  </>
                }
              />
            );
          })}
        </Box>
      </TableCell>
    );
  };

  return (
    <TableRow className={`${weekdayENG}`}>
      <TableCell className="day row-head">
        <Typography>{`${day}(${weekdayJP})`}</Typography>
        <Loading flg={loading || !drivers ? true : false} />
      </TableCell>
      {drivers?.map((driver) => {
        const driverData = data.filter((inst) => {
          return inst.driver_id === driver.id;
        });
        const driverTmpData = tmpData.filter((inst) => {
          return inst.user_id === driver.id;
        });

        return (
          <DataCellDriver
            key={`head-driver-${driver.id}`}
            driver={driver}
            driverData={driverData}
            driverTmpData={driverTmpData}
          />
        );
      })}

      {tranMethods
        ?.filter((tm) => tm.id !== SYSTEM?.tranMethod.own.id)
        .map((tm) => {
          const methodData = data.filter((inst) => {
            return inst.tm_id === tm.id;
          });
          const methodTmpData = tmpData.filter((inst) => {
            return inst.tm_id === tm.id;
          });
          return (
            <DataCellMethod
              key={`head-tranMethod-${tm.id}`}
              method={tm}
              methodData={methodData}
              methodTmpData={methodTmpData}
            />
          );
        })}
    </TableRow>
  );
};
