import { useState, useEffect } from "react";
import { tProject } from "types/project";
import { useNavigate } from "react-router-dom";
import { getProjects } from "functions/api/project";
import {
  Grid,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  strDateTimeOrigin,
  strDateOrigin,
  getPreviousMonday,
  MathDateTime,
} from "functions/time";
import {
  getTorisakiName,
  getLoadName,
  getUnloadName,
} from "functions/project/index";
import { getStatusLabel } from "functions/project/validate";
import * as cProject from "components/project/Index";
import * as cSProject from "components/project/Search";
import PageNation from "components/Pagenation";
import * as cMst from "components/mst/Index";
import { projectStatusQuotation } from "const/project/index";
import * as ProjectSearchItem from "components/project/Search";

interface searchProps {
  status: number | null;
  load_date_from: string;
  load_date_to: string;
  unload_date_from: string;
  unload_date_to: string;
  c_id: number | null;
  p_id: number | null;
  l_id: number | null;
}

export default function Main() {
  const [projects, setProjects] = useState<tProject[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const [search, setSearch] = useState<searchProps>({
    status: projectStatusQuotation.id,
    load_date_from: MathDateTime(new Date(), [0, -1, 0, 0, 0, 0])
      .toISOString()
      .split("T")[0],
    load_date_to: MathDateTime(new Date(), [0, 1, 0, 0, 0, 0])
      .toISOString()
      .split("T")[0],
    unload_date_from: MathDateTime(new Date(), [0, -1, 0, 0, 0, 0])
      .toISOString()
      .split("T")[0],
    unload_date_to: MathDateTime(new Date(), [0, 1, 0, 0, 0, 0])
      .toISOString()
      .split("T")[0],
    c_id: null,
    p_id: null,
    l_id: null,
  });

  const getSearch = async (page: number) => {
    await getProjects(page, search).then((res: any) => {
      setProjects(res.data.data);
      setCurrentPage(res.data.current_page);
      setLastPage(res.data.last_page);
      setTotalPages(res.data.last_page);
    });
  };

  useEffect(() => {
    getSearch(currentPage);
  }, []);

  return (
    <Grid container spacing={2}>
      {/* 検索条件 */}
      <Grid item xs={12} lg={6}>
        <cSProject.RangeLoadDatetime values={search} setValues={setSearch} />
      </Grid>

      <Grid item xs={12} lg={6}>
        <cSProject.RangeUnloadDatetime values={search} setValues={setSearch} />
      </Grid>

      <ProjectSearchItem.Status values={search} setValues={setSearch} />

      <cMst.Freewords values={search} setValues={setSearch} />
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            getSearch(1);
          }}
        >
          検索
        </Button>
      </Grid>

      {/* 一覧 */}
      <Grid item xs={12}>
        <TableContainer component={Paper} sx={{ margin: "0 auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>取引先</TableCell>
                <TableCell>初回積地情報</TableCell>
                <TableCell>最終卸地情報</TableCell>
                <TableCell>ステータス</TableCell>
                <TableCell>区分</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects.map((project) => {
                return (
                  <TableRow
                    key={`project-list-${project.id}`}
                    onDoubleClick={() =>
                      navigate(`/project/edit/${project.id}`)
                    }
                  >
                    <TableCell>{getTorisakiName(project)}</TableCell>
                    <TableCell>
                      <li>{strDateOrigin(project.load_date)}</li>
                      <li>{getLoadName(project)}</li>
                    </TableCell>
                    <TableCell>
                      <li>{strDateOrigin(project.unload_date)}</li>
                      <li>{getUnloadName(project)}</li>
                    </TableCell>
                    <TableCell>{getStatusLabel(project.status)}</TableCell>
                    <TableCell>{project.project_kind?.name}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <PageNation
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
            getSearch(value)
          }
        />
      </Grid>
    </Grid>
  );
}
