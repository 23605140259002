import { tTransportMethod } from "types/mst";

export const startLabel = (data: tTransportMethod) => {
  if (data.start_name) {
    return data.start_name;
  } else {
    return (
      data.start_prefectures +
      data.start_city +
      data.start_street +
      data.start_building
    );
  }
};

export const endLabel = (data: tTransportMethod) => {
  if (data.end_name) {
    return data.end_name;
  } else {
    return (
      data.end_prefectures + data.end_city + data.end_street + data.end_building
    );
  }
};
