import React, { useContext } from "react";
import { SelectChangeEvent } from "@mui/material";
import { List, typeHashValue } from "types/index";
import {
  GridItemTextField,
  GridItemTextFieldCustom,
  GridItemDateField,
} from "components/GridItem";
import { tUser } from "types/mst";
import { handleChangeValues } from "functions/handles";
import { AutocompleteTextField } from "atoms/TextField";
import { GridItemSelect } from "atoms/Select";
import { MstDataContext } from "contexts/Mst";
import { changeDateFromTypeDate } from "functions/time";

interface ItemFieldProps {
  label: string;
  name: string;
  value: typeHashValue;
  setValues: React.Dispatch<React.SetStateAction<tUser>>;
  size?: Record<string, number> | null;
}
const ItemTextFiled = ({
  label,
  name,
  value,
  setValues,
  size,
}: ItemFieldProps) => (
  <GridItemTextField
    label={label}
    value={value}
    name={name}
    handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
      handleChangeValues(e.target.value, e.target.name, setValues)
    }
    size={size}
  />
);

const ItemDateFiled = ({
  label,
  name,
  value,
  setValues,
  size,
}: ItemFieldProps) => (
  <GridItemDateField
    label={label}
    value={value as string}
    name={name}
    handleChangeValues={(newDate: string) =>
      handleChangeValues(newDate, name, setValues)
    }
    size={size}
  />
);
interface ItemSelectProps {
  labelId: string;
  id: string;
  label: string;
  selectedValue: number;
  items: List;
  setValues: React.Dispatch<React.SetStateAction<tUser>>;
}
const ItemSelect = ({
  labelId,
  id,
  label,
  selectedValue,
  items,
  setValues,
}: ItemSelectProps) => (
  <GridItemSelect
    labelId={labelId}
    id={id}
    label={label}
    selectedValue={selectedValue}
    items={items}
    handleChangeValues={(
      e: SelectChangeEvent<number | string>,
      child: React.ReactNode
    ) => {
      handleChangeValues(e.target.value, id, setValues);
    }}
  />
);

interface GridItemFieldProps {
  values: tUser;
  setValues: React.Dispatch<React.SetStateAction<tUser>>;
}
export const Email = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="メールアドレス"
    name="email"
    value={values.email}
    setValues={setValues}
    size={{ xs: 6, lg: 4, xl: 4 }}
  />
);
export const Password = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="パスワード"
    name="password"
    value={values.password}
    setValues={setValues}
    size={{ xs: 6, lg: 4, xl: 4 }}
  />
);

export const FName = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="姓"
    name="family_name"
    value={values.family_name}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const FNameKana = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="セイ"
    name="family_name_kana"
    value={values.family_name_kana}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const GName = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="名"
    name="given_name"
    value={values.given_name}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const GNameKana = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="ナマエ"
    name="given_name_kana"
    value={values.given_name_kana}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Birthday = ({ values, setValues }: GridItemFieldProps) => (
  <ItemDateFiled
    label="生年月日"
    name="birthday"
    value={changeDateFromTypeDate(values.birthday as unknown as Date)}
    setValues={setValues}
    size={{ xs: 12, lg: 4, xl: 3 }}
  />
);

export const Tell = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="電話番号"
    name="tell"
    value={values.tell}
    setValues={setValues}
    size={{ xs: 12, lg: 3, xl: 3 }}
  />
);

export const PostNumber = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="郵便番号"
    name="post_number"
    value={values.post_number}
    setValues={setValues}
    size={{ xs: 12, lg: 3, xl: 2 }}
  />
);

export const Address = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="住所"
    name="address"
    value={values.address}
    setValues={setValues}
    size={{ xs: 12, lg: 6, xl: 4 }}
  />
);

export const Role = ({ values, setValues }: GridItemFieldProps) => {
  const { loading, roles } = useContext(MstDataContext);
  if (loading || !roles) return <div>Loading...</div>;

  const options = roles?.map((role) => ({
    id: role.id,
    label: role.name,
  })) as List;

  return (
    <ItemSelect
      labelId="roleId-label"
      id="role_id"
      label="権限"
      selectedValue={Number(values.role_id)}
      items={options}
      setValues={setValues}
    />
  );
};

export const Vehicle = ({ values, setValues }: GridItemFieldProps) => {
  const { loading, vehicles } = useContext(MstDataContext);
  console.log("vehicles", vehicles);

  if (loading || !vehicles) return <div>Loading...</div>;

  const options = vehicles.map((vehicle) => ({
    id: vehicle.id,
    label: `${vehicle.plate}`,
  }));

  return (
    <ItemSelect
      labelId="vId-label"
      id="v_id"
      label="車両"
      selectedValue={Number(values.v_id)}
      items={options}
      setValues={setValues}
    />
  );
};

export const HireDate = ({ values, setValues }: GridItemFieldProps) => (
  <ItemDateFiled
    label="雇用日"
    name="hire_date"
    value={changeDateFromTypeDate(values.hire_date as unknown as Date)}
    setValues={setValues}
    size={{ xs: 12, lg: 4, xl: 3 }}
  />
);
