import { Text } from "@react-pdf/renderer";
import SheetTheme from "themes/SheetTheme";

export const CustomText = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: any;
}) => {
  if (children === null || children === undefined) {
    return <Text style={[SheetTheme.defaultFont, style]}> </Text>;
  } else if (typeof children === "string" && children === "") {
    return <Text style={[SheetTheme.defaultFont, style]}> </Text>;
  }

  return (
    <Text style={[SheetTheme.defaultFont, style]} wrap={false}>
      {children?.toString().replace(/\r?\n|\r/g, " ")}
    </Text>
  );
};

export const EmphaText = ({
  children,
  style,
}: {
  children: React.ReactNode;
  style?: any;
}) => {
  return <Text style={[SheetTheme.EmphaFont, style]}>{children}</Text>;
};
