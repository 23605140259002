import { strDateTimeOrigin } from "functions/time";

export type tValidationRules = {
  required?: boolean;
  numberGreaterBelow?: number;
  numberGreaterOver?: number;
  email?: boolean;
  minLength?: number;
  maxLength?: number;
  dateBelow?: string;
  dateOver?: string;
  datePast?: boolean;
  dateFuture?: boolean;
};

export const defaultValidationRules: tValidationRules = {
  required: false,
  numberGreaterBelow: undefined,
  numberGreaterOver: undefined,
  email: false,
  minLength: undefined,
  maxLength: undefined,
  dateBelow: undefined,
  dateOver: undefined,
  datePast: undefined,
  dateFuture: undefined,
};

export const getNumberBelow = (label: string, number: number = 0): string => {
  return `${label}は${number}より大きい数を入力してください`;
};

export const getNumberOver = (
  label: string,
  number: number = 100000000
): string => {
  return `${label}は${number}より小さい数を入力してください`;
};

export const getRequiredMessage = (label: string): string => {
  return `${label}は必須項目です`;
};

export const getEmailMessage = (label: string): string => {
  return `${label}は有効なメールアドレスを入力してください`;
};

export const getMinLengthMessage = (
  label: string,
  minLength: number
): string => {
  return `${label}は${minLength}文字以上で入力してください`;
};

export const getMaxLengthMessage = (
  label: string,
  maxLength: number
): string => {
  return `${label}は${maxLength}文字以下で入力してください`;
};

export const getInvalidDateMessage = (label: string): string => {
  return `${label}は有効な日付を入力してください`;
};

export const getPastDateMessage = (label: string): string => {
  return `${label}は過去の日時を入力してください`;
};

export const getFutureDateMessage = (label: string): string => {
  return `${label}は未来の日時を入力してください`;
};

export const getDateBelow = (label: string, date: string): string => {
  return `${label}は${date}以降の日時を入力してください`;
};

export const getDateOver = (label: string, date: string): string => {
  return `${label}は${date}以前の日時を入力してください`;
};

export const validateCheck = (
  fieldName: string,
  value: string | number | Date | null,
  type: string,
  rules: tValidationRules = defaultValidationRules
): string[] => {
  let errors: string[] = [];

  if (rules.required && !value) {
    errors.push(getRequiredMessage(fieldName));
  }

  if (type === "string") {
    errors = errors.concat(validateTextFiled(fieldName, String(value), rules));
  } else if (type === "number") {
    errors = errors.concat(validateNumber(fieldName, Number(value), rules));
  } else if (type === "date") {
    errors = errors.concat(validateDate(fieldName, String(value), rules));
  }

  return errors;
};

function validateTextFiled(
  fieldName: string,
  value: string,
  rules = defaultValidationRules
) {
  const errors: string[] = [];

  if (rules.email && !/\S+@\S+\.\S+/.test(value)) {
    errors.push(getEmailMessage(fieldName));
  }
  if (rules.minLength !== undefined && value.length < rules.minLength) {
    errors.push(getMinLengthMessage(fieldName, rules.minLength));
  }
  if (rules.maxLength !== undefined && value.length > rules.maxLength) {
    errors.push(getMaxLengthMessage(fieldName, rules.maxLength));
  }

  return errors;
}

const validateNumber = (
  fieldName: string,
  value: number,
  rules: tValidationRules = defaultValidationRules
): string[] => {
  const errors: string[] = [];

  if (
    rules.numberGreaterBelow !== undefined &&
    value <= rules.numberGreaterBelow
  ) {
    errors.push(getNumberBelow(fieldName, rules.numberGreaterBelow));
  }
  if (
    rules.numberGreaterOver !== undefined &&
    value >= rules.numberGreaterOver
  ) {
    errors.push(getNumberOver(fieldName, rules.numberGreaterOver));
  }

  return errors;
};

const validateDate = (
  fieldName: string,
  value: string,
  rules: tValidationRules = defaultValidationRules
): string[] => {
  const errors: string[] = [];

  // 入力値のDate型
  const date = new Date(value);

  // 有効な形か
  if (isNaN(date.getTime())) {
    errors.push(getInvalidDateMessage(fieldName));
  }

  // 過去ではないか
  if (rules.dateFuture && date < new Date(new Date().setHours(0, 0, 0, 0))) {
    errors.push(getFutureDateMessage(fieldName));
  }

  // 未来ではないか
  if (rules.datePast && date < new Date(new Date().setHours(23, 59, 59, 999))) {
    errors.push(getPastDateMessage(fieldName));
  }

  // 指定日以下
  if (rules.dateBelow) {
    const dateBelow = new Date(rules.dateBelow);
    if (!isNaN(dateBelow.getTime())) {
      if (date < dateBelow) {
        errors.push(getDateBelow(fieldName, strDateTimeOrigin(dateBelow)));
      }
    }
  }

  // 指定日以上
  if (rules.dateOver) {
    const dateOver = new Date(rules.dateOver);
    if (!isNaN(dateOver.getTime())) {
      if (date > dateOver) {
        errors.push(getDateOver(fieldName, strDateTimeOrigin(dateOver)));
      }
    }
  }

  return errors;
};
