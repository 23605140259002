import React, { createContext, useState, useEffect } from "react";
import Axios from "axios";
import * as env from "functions/env";
import { sleep } from "functions/index";
import { ZoomIn } from "@mui/icons-material";
export const API_URL = {
  base: env.API_ROOT_URL(),
  sanctum: env.API_URL_BASE() + "/sanctum/csrf-cookie",
  login: env.API_ROOT_URL() + "/login",
  logout: env.API_ROOT_URL() + "/logout",
  checkToken: env.API_ROOT_URL() + "/token-check",
  register: env.API_ROOT_URL() + "/register",
  ProjectKind: env.API_ROOT_URL() + "/project-kind/index",
};

// グローバル変数としてCSRFトークンを保持
let csrfToken = "";

/**
 * CSRFトークンがない場合に取得する同期関数
 */
export async function getCsrf() {
  if (!csrfToken) {
    const instance = Axios.create({
      baseURL: API_URL.base,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true,
      timeout: 60000,
      maxRedirects: 5,
    });

    await instance.get(API_URL.sanctum, { withCredentials: true });
  }
}

function getCookieXsrfToken(key: string = "XSRF-TOKEN=") {
  const csrfCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith(key));
  if (csrfCookie) {
    //csrfToken = csrfCookie.split("=")[1]; // デコードする
    setCsrfToken(csrfCookie.split("=")[1]);
  } else {
    throw new Error("CSRF token not found in cookies");
  }
  return "";
}

function setCsrfToken(token: string) {
  let metaTag = document.querySelector('meta[name="csrf-token"]');
  if (!metaTag) {
    metaTag = document.createElement("meta");
    metaTag.setAttribute("name", "csrf-token");
    metaTag.setAttribute("content", token);
    document.head.appendChild(metaTag);
  } else {
    metaTag.setAttribute("content", token);
  }
  csrfToken = token;
}

export function create() {
  //getCookieXsrfToken();
  let instance = Axios.create({
    baseURL: API_URL.base,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("token"),
      /*
      "X-CSRF-TOKEN": csrfToken,
      Referer: document.location.href,
      */
    },
    //xsrfHeaderName: "X-CSRF-TOKEN",
    withCredentials: true,
    //withXSRFToken: true,
    timeout: 60000,
    maxRedirects: 5,
  });

  return instance;
}

export async function login(auth: any, callbacker: any | null) {
  // await getCsrf(); // CSRFトークンを取得
  const instance = await create();
  instance
    .post(API_URL.login, { auth })
    .then((res) => {
      // 成功でtokenをローカルストレージに保存
      localStorage.setItem("token", res.data.token);
      callbacker(res, true);
    })
    .catch((error) => {
      console.error("Error during login:", error);
      callbacker(null, false);
    });
}

export async function logout(callbacker: any | null = null) {
  // await getCsrf(); // CSRFトークンを取得
  const ins = await create();
  return ins
    .post(API_URL.logout, {})
    .then((res) => {
      // 成功でlocalStorageをクリア
      localStorage.clear();
      if (callbacker) {
        callbacker(res);
      }
    })
    .catch((error) => {
      console.error("Error during logout:", error);
      if (callbacker) {
        callbacker(error.response);
      }
    });
}
