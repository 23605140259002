// リダイレクト
import React, { useEffect, useState, useContext, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { SchedulesThemeProvider } from "themes/SchedulesTheme";
import {
  Typography,
  Checkbox,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Link,
  Container,
} from "@mui/material";
import { initWaypoints, initProject, tProject } from "types/project";
import { styled } from "@mui/system";
import { palette } from "themes/Base";
import Modal from "atoms/Modal";
import { MstDataContext } from "contexts/Mst";
import { typeHash } from "types";
import {
  isSameDate,
  MathDateTime,
  changeDateFromTypeDate,
  getCharWeekday,
  strDateOrigin,
} from "functions/time";
import { initialProjectKind, tProjectKind } from "types/mst";
import { ClientCompany } from "components/project/Index";
import { RangeLoadDatetime } from "components/project/Search";
import { PrimaryButton, SecondButton } from "atoms/Button";
import { getTransportCards } from "functions/api/transport";
import { tTransportCard } from "types/transport";
import { useSelection } from "contexts/Schedules";
import { TransportLineNomal } from "components/TransportCard";
import { tTransportSearch } from "types/transport";
import { tProjectSearch } from "types/project";
import { tDailyMemo } from "types/dailymemo";
import { getDailyMemosToDate } from "functions/dailymemo/index";
import { SmallTextField } from "atoms/TextField";
import { initDailyMemo } from "const/dailyMemo/index";
import { storeDailyMemo, getDailyMemoList } from "functions/api/dailymemo";
import { truncateString } from "functions/index";
import { projectStatus, projectStatusOrdered } from "const/project";
import { checkInTransportCard } from "functions/transport";
import { Toolchip } from "atoms/Toolchip";
import PJModal from "components/project/Modal";
import PJSearchModal from "components/project/Search";
import { cardType } from "types/index";

const ContentsArea = styled("div")({
  "& .head": {
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
    //border: "1px solid #e0e0e0",
    position: "sticky",
    top: 0,
    zIndex: 95,
    "& .cell": {
      textAlign: "center",
      "& .MuiFormControlLabel-root": {
        margin: "auto",
      },
      "&.day": {
        position: "sticky",
        left: 0,
        zIndex: 99,
        margin: "none",
        backgroundColor: "inherit",
      },
    },
  },
  "& .cell": {
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    "&.day": {
      width: "150px",
      //border: "1px solid #e0e0e0",
      textAlign: "center",
    },
    "&.kind": {
      /*
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      */
      width: "100px",
      //border: "1px solid #e0e0e0",
      "&.active": {
        minWidth: "220px",
        borderLeft: "1px solid e0e0e0",
        borderRight: "1px solid e0e0e0",
      },
    },
  },

  "& .row": {
    display: "inline-flex",
    border: "1px solid black",
    minWidth: "100%",
  },

  "& .row.data": {
    "&.Monday": {
      ".kind.odd": { backgroundColor: "rgba(255, 211, 44, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(255, 211, 44, 0.6)" },
    },
    "&.Tuesday": {
      ".kind.odd": { backgroundColor: "rgba(240, 107, 68, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(240, 107, 68, 0.6)" },
    },
    "&.Wednesday": {
      ".kind.odd": { backgroundColor: "rgba(93, 127, 204, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(93, 127, 204, 0.6)" },
    },
    "&.Thursday": {
      ".kind.odd": { backgroundColor: "rgba(93, 170, 102, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(93, 170, 102, 0.6)" },
    },
    "&.Friday": {
      ".kind.odd": { backgroundColor: "rgba(204, 170, 51, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(204, 170, 51, 0.6)" },
    },
    "&.Saturday": {
      ".kind.odd": { backgroundColor: "rgba(139, 103, 61, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(139, 103, 61, 0.6)" },
    },
    "&.Sunday": {
      ".kind.odd": { backgroundColor: "rgba(255, 135, 63, 0.3)" },
      ".kind.even": { backgroundColor: "rgba(255, 135, 63, 0.6)" },
    },
    "& .cell.day": {
      backgroundColor: "#f5f5f5",
      display: "flex",
      justifyItems: "center",
      alignItems: "center",
      position: "sticky",
      left: 0,
      zIndex: 91,
      "& *": {
        margin: "auto",
      },
    },
    "& .cell.kind": {
      "& .status-complete": {
        backgroundColor: palette.glayDark,
        color: palette.white,
      },
      "& .status-transport": {
        //backgroundColor: useTheme.palette.primary.main,
      },
      "& .status-no": {
        backgroundColor: palette.white,
      },
    },
  },
});

/**
 * 案件情報の積み日範囲を必須にするためカスタム型を作成
 */
type tProjectSearchCustom = tProjectSearch & {
  load_date_from: string;
  load_date_to: string;
};

export default function Mian() {
  const theme = useTheme();
  const navigate = useNavigate();

  const { transportCards: transports } = useSelection();
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedPJ, setSelectedPJ] = useState<tProject["id"]>(); // 案件詳細表示対象の案件ID

  useEffect(() => {
    if (transports.length > 0) {
      setIsExpanded(true);
    }
  }, [transports]);

  return (
    <SchedulesThemeProvider>
      <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
        <ContentsArea
          sx={{
            flexGrow: 1, // 残りの高さを占める
            transition: "height 0.3s ease",
            overflow: "auto", // 高さが足りない時にスクロールバーを表示
          }}
        >
          <PJModal
            open={selectedPJ !== undefined}
            onClose={() => setSelectedPJ(undefined)}
            pjId={selectedPJ ? selectedPJ : 0}
            callbackNomal={() => {
              setSelectedPJ(undefined);
              navigate("/full-screen/schedules");
            }}
            callbackError={() => {}}
          />

          <Contents setSelectedPJ={setSelectedPJ} />
        </ContentsArea>
        {/* Bのエリア: クリックで高さが0 -> 100pxに変わる */}
        <Container
          sx={{
            height: isExpanded ? "auto" : "0px",
            backgroundColor: theme.palette.common.white,
            transition: "height 0.3s ease",
            //overflow: "hidden", // 高さが0の時にコンテンツが見えないように
          }}
        >
          <SelectedArea
            transportCards={transports}
            setSelectedPJ={setSelectedPJ}
            optionNode={
              <Button
                variant="contained"
                onClick={() => {
                  navigate(
                    "/operation/instructions" /*"/operation/base-transport"*/
                  );
                }}
              >
                配車手配
              </Button>
            }
          />
        </Container>
      </Box>
    </SchedulesThemeProvider>
  );
}

/**
 * コンテンツ
 * @param param0
 * @returns
 */
function Contents({
  setSelectedPJ,
}: {
  setSelectedPJ: React.Dispatch<React.SetStateAction<number | undefined>>;
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [openSearch, setOpenSearch] = useState(false);

  // メモ機能
  const [memoDate, setMemoDate] = useState<string>("");
  const [dailyMemos, setDailyMemos] = useState<tDailyMemo[]>([]);
  const [selectedMemoId, setSelectedMemoId] = useState<number | null>(null);
  const [selectedMemo, setSelectedMemo] = useState<tDailyMemo>(initDailyMemo);

  const [dateTransports, setDateTransports] = useState<
    Record<string, tTransportCard[]>
  >({});

  const { loading, projectKinds } = useContext(MstDataContext);
  const [viewPK, setViewPK] = useState<tProjectKind[]>([]);
  const [checkedKind, setCheckedKind] = useState<Record<number, boolean>>({});

  const [openCreatePJModal, setOpenCreatePJModal] = useState(false);
  const [initPJCustom, setInitPJCustom] = useState<tProject>(initProject);

  const [searchTran, setSearchTran] = useState<tTransportSearch>({
    status: null,
  });
  const [searchPJ, setSearchPJ] = useState<tProjectSearchCustom>({
    status: projectStatus
      .filter((status) => status.id >= projectStatusOrdered.id)
      .map((status) => status.id),
    load_date_from: changeDateFromTypeDate(
      MathDateTime(new Date(), [0, 0, -7, 0, 0, 0])
    ),
    load_date_to: changeDateFromTypeDate(
      MathDateTime(new Date(), [0, 0, 7, 0, 0, 0])
    ),
    unload_date_from: null,
    unload_date_to: null,
    c_id: null,
  });

  // 検索モーダル用
  //const [searchTranModal, setSearchTranModal] =
  //  useState<tTransportSearch>(searchTran);
  const [searchPJModal, setSearchPJModal] =
    useState<tProjectSearchCustom>(searchPJ);

  // 検索処理
  const [viewDate, setViewDate] = useState<Record<string, typeHash>>({});
  const searchProject = async (
    searchTran: tTransportSearch,
    searchPJ: tProjectSearchCustom
  ) => {
    const res = await getTransportCards({
      transport: searchTran,
      project: searchPJ,
    });
    // 対象の日付を取得
    const tmpViewDate = getWeekDatesWithDays(
      searchPJ.load_date_from,
      searchPJ.load_date_to
    );

    const transports = res.data; // 検索条件に一致するもの

    // データを格納
    setDateTransports((prev) => {
      let temp: Record<string, tTransportCard[]> = {};
      Object.keys(tmpViewDate).forEach((date) => {
        // 1日分のデータを取得
        const targets: tTransportCard[] = transports.filter(
          (transport: tTransportCard) => {
            if (!transport.pj_id) {
              // プロジェクト情報がない場合は何も返さない
              return false;
            }

            if (!isSameDate(transport.load_date, date)) {
              return false;
            }

            return true;
          }
        );

        const dateTransportCard: tTransportCard[] = targets.filter(
          (target) => target.pj_id > 0
        );

        temp[date] = dateTransportCard;
      });

      return {
        ...temp,
      };
    });

    setViewDate(tmpViewDate);
  };

  /**
   * 日付セルダブルクリック
   * @param date
   */
  const handleDayCellDoubleClick = (date: string) => {
    console.log("handleDayCellDoubleClick", date);
    setMemoDate(date);
    setSelectedMemo({ ...initDailyMemo, datetime: `${date} 00:00:00` });
  };

  /**
   * 案件表示セルダブルクリック
   * @param date
   * @param kbn
   */
  const handlePJCellDoubleClick = (date: string, kbn: number) => {
    // 積地の初期値を設定
    const ways = initWaypoints;
    ways[0].datetime = date;
    ways[1].datetime = date;

    setInitPJCustom({
      ...initProject,
      waypoints: ways,
      kind: kbn,
      status: projectStatusOrdered.id,
    });
    setOpenCreatePJModal(true);
  };

  /**
   * メモダブルクリック
   * @param date
   * @param id
   */
  const handleDayDoubleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    date: string,
    id: number
  ) => {
    // 後続のイベントを抑制する
    event.stopPropagation();
    const obj = dailyMemos.find((dm) => dm.id === id);
    if (obj) {
      console.log(obj);
      setMemoDate(date);
      setSelectedMemo(obj);
    } else {
      alert("対象のメモを取得できませんでした。");
    }
  };

  /**
   * デイリーメモ変更イベント
   * @param name
   * @param value
   */
  const handleDailyMemoChange = (name: string, value: any) => {
    setSelectedMemo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleDailyMemoStoreClick = () => {
    // メモの保存処理
    storeDailyMemo(selectedMemo)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("登録に失敗しました。");
        }

        getDailyMemoList().then((res) => {
          if (res.status !== 200) {
            throw new Error("");
          }
          setMemoDate("");
          setDailyMemos(res.data);
        });
      })
      .catch((error: any) => {
        // エラーがある場合は、ログアウトしてログインページにリダイレクト
        navigate("登録に失敗しました。");
      });
  };

  /**
   * 検索実行処理
   */
  useEffect(() => {
    const fetchData = async (
      searchTran: tTransportSearch,
      searchPJ: tProjectSearchCustom
    ) => {
      searchProject(searchTran, searchPJ);
      getDailyMemoList().then((res) => {
        if (res.status !== 200) {
          throw new Error("");
        }
        setDailyMemos(res.data);
      });
    };

    // 初回データ取得
    fetchData(searchTran, searchPJ);

    // 定期的にデータを更新する
    const interval = setInterval(() => {
      fetchData(searchTran, searchPJ);
    }, 20000); // 20秒ごとにデータを更新

    // コンポーネントがアンマウントされた時にクリーンアップ
    return () => clearInterval(interval);
  }, [searchTran, searchPJ]);

  useEffect(() => {
    if (!projectKinds) {
      return;
    }

    const newPK = [...projectKinds, initialProjectKind];
    const tmpCheck: Record<number, boolean> = {};
    newPK.map((kind) => {
      tmpCheck[Number(kind.id)] = true;
    });

    setViewPK(newPK);
    setCheckedKind(tmpCheck);
  }, [projectKinds]);

  // 日付が変更されたときにスクロールを発生させる
  const refTodate = useRef<HTMLDivElement>(null); // 対象への参照
  useEffect(() => {
    console.log("refTodate", refTodate);
    if (refTodate.current) {
      refTodate.current.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  }, [refTodate.current]); // viewDateが変更されたときにスクロールを発生させる

  if (loading || !projectKinds) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <PJSearchModal
        terms={searchPJModal}
        setTerms={setSearchPJModal}
        open={openSearch}
        setOpen={setOpenSearch}
        callback={() => setSearchPJ(searchPJModal)}
      />
      <PJModal
        open={openCreatePJModal}
        onClose={() => setOpenCreatePJModal(false)}
        pjId={0}
        initCustom={initPJCustom}
        callbackNomal={() => {
          setOpenCreatePJModal(false);
          searchProject(searchTran, searchPJ);
        }}
        callbackError={() => {}}
      />

      <Modal
        title={`メモ:${memoDate}`}
        open={!!memoDate}
        onClose={() => setMemoDate("")}
        content={
          <ModalMemo
            memo={selectedMemo}
            setMemo={setSelectedMemo}
            callbackChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleDailyMemoChange(e.target.name, e.target.value)
            }
          />
        }
        actions={
          <>
            <PrimaryButton label="保存" onClick={handleDailyMemoStoreClick} />
            <SecondButton
              label="閉じる"
              onClick={() => {
                setMemoDate("");
                setSelectedMemoId(null);
              }}
            />
          </>
        }
      />

      <div className="row head">
        <div className="cell day">
          <Button onClick={() => setOpenSearch(true)}>検索</Button>
        </div>
        {viewPK.map((projectKind, index) => (
          <div
            key={`schedule-rowhead-${projectKind.id}`}
            className={`cell kind ${
              checkedKind[projectKind.id] ? "active" : ""
            }`}
          >
            <Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    onChange={(e) =>
                      setCheckedKind({
                        ...checkedKind,
                        [projectKind.id]: e.target.checked,
                      })
                    }
                    value={checkedKind[Number(projectKind.id)]}
                  />
                }
                label={
                  checkedKind[Number(projectKind.id)] ? projectKind.name : ""
                }
              />
            </Typography>
          </div>
        ))}
      </div>

      {Object.keys(dateTransports).map((date, dIndex) => {
        const dateMemos: tDailyMemo[] = getDailyMemosToDate(
          dailyMemos,
          strDateOrigin(date)
        );

        return (
          <div
            id={`${date}`}
            ref={
              isSameDate(date, changeDateFromTypeDate(new Date()))
                ? refTodate
                : null
            }
            key={`schedule-column-${date}`}
            className={`row data ${viewDate[date].weekday}`}
            style={{ minHeight: "75px" }}
          >
            <Box
              className="cell day"
              onDoubleClick={() => {
                handleDayCellDoubleClick(date);
              }}
              sx={{
                display: "flex",
                flexFlow: "column",
                gap: theme.spacing(0.5),
              }}
            >
              <Link href={`/project/input?date=${date}`}>
                <Typography>{`${date}(${getCharWeekday(date)})`}</Typography>
              </Link>
              {dateMemos?.map((memo: tDailyMemo) => (
                <Typography
                  key={memo.id}
                  onDoubleClick={(
                    event: React.MouseEvent<HTMLButtonElement>
                  ) => {
                    handleDayDoubleClick(event, date, memo.id);
                  }}
                >
                  {`${truncateString(memo.memo, 7)}`}
                </Typography>
              ))}
            </Box>

            {viewPK?.map((kind, index) => {
              const categoryTransports = dateTransports[date].filter(
                (project) => {
                  return project.kind === kind.id;
                }
              );

              //console.log("categoryTransports", categoryTransports);

              return (
                <div
                  key={`schedule-column-${date}-${kind.id}`}
                  className={`cell kind ${
                    checkedKind[Number(kind.id)] ? "active" : ""
                  } ${index % 2 === 0 ? "even" : "odd"}`}
                  onDoubleClick={() => {
                    handlePJCellDoubleClick(date, kind.id);
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-evenly",
                    }}
                  >
                    {categoryTransports.map((transport, i) => {
                      return (
                        <TransportCardToSchedule
                          key={`schedule-column-${date}-${kind.id}-${i}`}
                          transportCard={transport}
                          flgOmit={!checkedKind[Number(kind.id)]}
                          setSelectedPJ={setSelectedPJ}
                          type="line"
                        />
                      );
                    })}
                  </Box>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
}

/**
 * 予定表用
 * @param param0
 * @returns
 */
const TransportCardToSchedule = ({
  transportCard, // 輸送情報
  flgOmit = true, // 略称表示かどうか
  switchCtrl = true, // 選択機能の有無
  flgAttributeLabel = true, // 属性ラベルの表示
  setSelectedPJ,
  type = "box",
}: {
  transportCard: tTransportCard;
  flgOmit?: any;
  switchCtrl?: boolean;
  flgAttributeLabel?: boolean;
  setSelectedPJ: React.Dispatch<
    React.SetStateAction<tProject["id"] | undefined>
  >;
  type?: cardType;
}) => {
  const { transportCards: transports } = useSelection();
  const { addSelection, removeSelection } = useSelection(); // グローバル状態の関数

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); // ポップオーバーのアンカー
  const [selected, setSelected] = useState(false);

  const [flgInstModal, setFlgInstModal] = useState(false);

  /**
   * クリック時の処理
   * @param event
   */
  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    // event.currentTargetを変数に保存する
    const targetElement = event.currentTarget;

    console.log("click", event);
    setAnchorEl(targetElement); // クリックした要素をアンカーに設定
  };

  const handleToolchipClose = () => {
    setAnchorEl(null); // ポップオーバーを閉じる
  };

  const handleDetailPJ = () => {
    setSelectedPJ(transportCard.pj_id);
    handleToolchipClose();
  };

  /**
   * 選択処理
   * @param type
   */
  const handleSelectedTran = () => {
    addSelection({ transportCard }); // グローバル状態に選択を追加
    handleToolchipClose(); // 選択後にメニューを閉じる
  };

  /**
   * 解除処理
   * @param type
   */
  const handleDeselectTran = () => {
    removeSelection({ transportCard });
    handleToolchipClose(); // 選択後にメニューを閉じる
  };

  /**
   * 選択輸送情報が表示されているかをチェック
   * @returns
   */
  const checkSelectedTransports = () => {
    return checkInTransportCard(transports, transportCard);
  };

  // 選択状態の確認
  useEffect(() => {
    setSelected(checkSelectedTransports());
  }, [transports]);

  const open = Boolean(anchorEl);

  return (
    <Box className={`TransportCardToInstruction`} sx={{ display: "contents" }}>
      <TransportLineNomal
        transportCard={transportCard}
        callbackClick={handleCardClick}
        flgOmit={flgOmit}
        type={type}
      >
        {flgAttributeLabel && (
          <>
            {checkSelectedTransports() && (
              <Typography className="stamp secondary">{`選`}</Typography>
            )}
          </>
        )}
      </TransportLineNomal>
      <Toolchip
        open={open}
        anchorEl={anchorEl}
        handleClose={handleToolchipClose}
      >
        {!selected ? (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDetailPJ}
              sx={{ marginRight: 1, marginTop: 1 }}
            >
              案件詳細
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleSelectedTran()}
              sx={{ marginRight: 1, marginTop: 1 }}
            >
              選択
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setFlgInstModal(true);
              }}
              sx={{ marginRight: 1, marginTop: 1 }}
            >
              輸送情報
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleDeselectTran()}
              sx={{ marginRight: 1, marginTop: 1 }}
            >
              解除
            </Button>
          </>
        )}
      </Toolchip>
    </Box>
  );
};

type tViewDate = Record<string, typeHash>;

/**
 *
 * @param startDate
 * @param endDate
 * @returns
 */
function getWeekDatesWithDays(startDate: string, endDate: string): tViewDate {
  const daysOfWeek: string[] = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let weekDates: tViewDate = {};

  // startDateからendDateまでの日付を取得
  let currentDate = new Date(startDate);
  const finalDate = new Date(endDate);

  while (currentDate <= finalDate) {
    // 日付をキーに曜日を値とするオブジェクトを作成
    const dateKey: string = currentDate.toISOString().split("T")[0];
    const dayOfWeek: string = daysOfWeek[currentDate.getDay()];

    weekDates[dateKey] = { weekday: dayOfWeek };

    // １日加算
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return weekDates;
}

function ModalMemo({
  memo,
  setMemo,
  callbackChange,
}: {
  memo: tDailyMemo;
  setMemo: React.Dispatch<React.SetStateAction<tDailyMemo>>;
  callbackChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  console.log("ModalMemo", memo);
  return (
    <Box>
      <Box>
        {memo.id > 0 ? (
          <Typography>{memo.memo}</Typography>
        ) : (
          <SmallTextField
            props={{
              multiline: true,
              rows: 4,
              name: "memo",
              value: memo.memo,
              onChange: callbackChange,
            }}
          />
        )}
      </Box>
    </Box>
  );
}

/**
 * 配車手配エリア
 * @param param0
 * @returns
 */
const SelectedArea = ({
  transportCards,
  optionNode,
  setSelectedPJ,
}: {
  transportCards: tTransportCard[];
  optionNode: React.ReactNode;
  setSelectedPJ: React.Dispatch<
    React.SetStateAction<tProject["id"] | undefined>
  >;
}) => {
  return (
    <Container className="selected-area">
      {transportCards.length !== 0 && (
        <Grid container>
          <Grid item xs={11}>
            <Box
              sx={{
                display: "flex",
                flexFlow: "wrap",
              }}
            >
              {transportCards.map((transportCard, index) => {
                return (
                  <TransportCardToSchedule
                    key={`TransportCard-InstructionTargetArea-${index}`}
                    transportCard={transportCard}
                    flgOmit={false}
                    flgAttributeLabel={false}
                    setSelectedPJ={() => setSelectedPJ(transportCard.pj_id)}
                  />
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={1}>
            {optionNode}
          </Grid>
        </Grid>
      )}
    </Container>
  );
};
