import * as React from "react";
import { useEffect, useState } from "react";
import Header from "components/Header";
import { ThemeProviderCustom } from "themes/Base";
import { Outlet } from "react-router-dom";
import * as axiosCustom from "functions/axios";
import { Console_log } from "functions/index";
import { useNavigate } from "react-router-dom";

export default function Main() {
  //const [currentPage, setCurrentPage] = useState('');
  const navigate = useNavigate();

  // 認証チェック
  const isAuthenticated = () => {
    // トークンが有効か☑️
    let axios = axiosCustom.create();
    axios
      .get(axiosCustom.API_URL.checkToken, { params: {} })
      .then((res: any) => {
        Console_log("get:checkToken", res.data);
        if (res.status !== 200) {
          // エラーがある場合は、ログアウトしてログインページにリダイレクト
          navigate("/login");
        }
      })
      .catch((error: any) => {
        Console_log("error checkToken", error);
        // エラーがある場合は、ログアウトしてログインページにリダイレクト
        navigate("/logout");
      });
  };

  useEffect(() => {
    isAuthenticated();
  }, []);

  return (
    <ThemeProviderCustom>
      <Header />
      <Outlet />
    </ThemeProviderCustom>
  );
}
