// theme.ts
import { StyleSheet, Font } from "@react-pdf/renderer";

// 日本語フォントを登録
Font.register({
  family: "NotoSansJP",
  src: "/fonts/NotoSansJP-VariableFont_wght.ttf", // 公開ディレクトリに配置したフォントのパス
});

const SheetTheme = StyleSheet.create({
  defaultFont: {
    fontFamily: "NotoSansJP",
    fontSize: 12,
    color: "black",
    width: "100%",
  },
  EmphaFont: {
    fontFamily: "NotoSansJP",
    fontSize: 18,
    fontWeight: "bold",
    color: "black",
  },
  // 他の共通スタイル
});

export default SheetTheme;
