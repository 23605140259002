import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { tCompany, tPerson } from "types/mst";
import { useParams } from "react-router-dom";
import { tProject } from "types/project";
import {
  Grid,
  Typography,
  Box,
  Paper,
  Checkbox,
  dialogActionsClasses,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { PrimaryButton, SecondButton } from "atoms/Button";
import { useTheme } from "@mui/material/styles";
import { getCompany, getCompanyPerson } from "functions/api/mst";
import { getProjects } from "functions/api/project";
import { projectStatusChecked } from "const/project/index";
import { getLoadName, getUnloadName } from "functions/project/index";
import { calculateTotalPrice } from "functions/project/validate";
import {
  strDateOrigin,
  getSameDayPreviousMonth,
  formatYearMonth,
  MathDateTime,
  formatMonthDay,
} from "functions/time";
import { closingDateTypeUnload } from "const/comapny/closingDateType";
import { FlexBox, FlexColumnBox } from "atoms/Box";
import PageNation from "components/Pagenation";
import * as ClosingComponent from "components/closing/Index";
import { tInvoice, tInvoiceDetail } from "types/invoice";
import { tAddress } from "types/mst";
import { List } from "types/index";
import { formatNumber } from "functions/index";
import { storeInvoice } from "functions/api/paperwork";
import Modal from "atoms/Modal";
import SheetInvoice, { PDFDownload, PDFPreview } from "sheets/invoice/type01";
import { getInvocie } from "functions/api/paperwork";
import { MstDataContext } from "contexts/Mst";
import LoadingCircular from "atoms/Loading";

type tCompanyCustom = tCompany & { address: tAddress };

export default function Main() {
  const { c_id, p_id } = useParams();
  const { loading, selfInfomation } = useContext(MstDataContext);

  const navigate = useNavigate();
  const theme = useTheme();
  const [company, setCompany] = useState<tCompanyCustom>();
  const [person, setPerson] = useState<tPerson | null>(null);
  const [projects, setProjects] = useState<tProject[]>();

  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [invoice, setInvoice] = useState<tInvoice>();
  const [selectedProjectId, setSelectedProjectId] = useState<number[]>([]);
  const [projectMemo, setProjctMemo] = useState<List>([]);

  const [sheetModal, setSheetModal] = useState(false);
  const [sheetName, setSheetName] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState<tInvoice>();
  const [details, setDetails] = useState<tInvoiceDetail[]>([]);

  // データ取得処理
  const getSearch = async (page: number = 0) => {
    let filter: any = {
      c_id: Number(c_id),
      status: projectStatusChecked.id,
    };

    if (p_id && p_id !== "0") {
      getCompanyPerson(Number(p_id)).then((res: any) => {
        setPerson(res.data);
      });

      // filterに担当者IDを追加
      filter = {
        ...filter,
        p_id: Number(p_id),
      };
    } else {
      setPerson(null);
    }

    getCompany(Number(c_id)).then((res: any) => {
      setCompany(res.data);

      getProjects(page, filter, [
        {
          field: "load_date",
          direction: "asc",
        },
        {
          field: "unload_date",
          direction: "asc",
        },
      ]).then((res: any) => {
        setProjects(res.data.data);
        setCurrentPage(res.data.current_page);
        setLastPage(res.data.last_page);
        setTotalPages(res.data.last_page);
      });
    });
  };

  const handleSelectedProject = (project: tProject) => {
    // seslectedProjectIdに含まれているかを判定
    if (selectedProjectId.includes(project.id)) {
      setSelectedProjectId((prev: number[]) => {
        return prev.filter((id) => id !== project.id);
      });
    } else {
      setSelectedProjectId((prev: number[]) => {
        return [...prev, project.id];
      });
    }
  };

  /**
   * 締め処理ボタンクリック時の処理
   */
  const handleClosingClick = () => {
    if (selectedProjectId.length === 0) {
      alert("案件を選択してください。");
      return;
    }

    const details = selectedProjectId.map((pj_id: number) => {
      const memoItem = projectMemo[pj_id]; // キーが存在するかチェック
      if (memoItem) {
        return { pj_id: pj_id, memo: memoItem }; // メモが見つかった場合の処理
      } else {
        return { pj_id: pj_id, memo: "" };
      }
    });

    const params = {
      invoice: invoice,
      details: details,
    };

    storeInvoice(params)
      .then((res: any) => {
        if (res.status !== 200) {
          throw new Error("error");
        }

        // 画面更新
        getSearch(1);

        // 請求書を発行するかを確認
        if (
          window.confirm(
            "請求書デーだ作成に成功しました。¥nこのまま請求書を発行しますか？"
          )
        ) {
          getInvocie(res.data.id)
            .then((res: any) => {
              if (res.status !== 200) throw new Error("error");
              setSelectedInvoice(res.data.invoice);
              setDetails(res.data.details);
              setSheetModal(true);
              setSheetName(`請求書_${res.data.invoice.invoice_number}.pdf`);
            })
            .catch((error: any) => {
              alert(error);
              setSelectedInvoice(undefined);
              setDetails([]);
              setSheetModal(false);
              setSheetName(``);
            });
        } else {
          navigate(`/paperwork/closing`);
        }
      })
      .catch((error: any) => {
        alert("請求書の作成に失敗しました。");
      });
  };

  useEffect(() => {
    getSearch(currentPage);
  }, [currentPage]);

  /**
   * 取得した案件情報を元に、締め日による案件選択を行う
   */
  useEffect(() => {
    if (!company || !projects) {
      return;
    }

    const closingDate = getSameDayPreviousMonth(company.closing_date);
    // 締め日の一月前の翌日を取得
    const closingDateFrom = MathDateTime(
      getSameDayPreviousMonth(company.closing_date, closingDate),
      [0, 0, 1, 0, 0, 0]
    );

    const title = `${formatYearMonth(closingDate)}分（${formatMonthDay(
      closingDateFrom
    )}〜${formatMonthDay(closingDate)}）`;

    const { totalPrice, totalPriceTax } = calculateTotalPrice(projects);

    let tempInvoice: tInvoice = {
      id: 0,
      invoice_number: "",
      title: `${title}`,
      total_amount: totalPrice,
      tax: totalPriceTax,
      billing_amount: totalPrice + totalPriceTax,
      detail_count: projects.length,
      c_id: company.id,
      c_invoice_no: company.invoice_no,
      c_name: company.name,
      c_postal_code: company.post_number,
      c_address1: company.address1,
      c_address2: company.address2,
      pj_in_charge_name: "",
    };

    // 担当者名を取得
    if (person) {
      tempInvoice.pj_in_charge_name = `${person.family_name} ${person.given_name}`;
    }

    // 今月の会社マスタ.締め日を取得
    const thisClosingDate = getSameDayPreviousMonth(company.closing_date);

    setSelectedProjectId([]); // 初期化
    projects.map((project: tProject) => {
      let targetDate = project.load_date;
      if (company.closing_date_type === closingDateTypeUnload.id) {
        targetDate = project.unload_date;
      }

      // 会社マスタに従った締め日以降の案件は選択対象外
      if (new Date(targetDate) > thisClosingDate) {
        setSelectedProjectId((prev: number[]) => {
          return prev.filter((id) => id !== project.id);
        });
      } else if (project.price === 0) {
        // 金額が0円の案件は選択対象外
        setSelectedProjectId((prev: number[]) => {
          return prev.filter((id) => id !== project.id);
        });
      } else {
        // 選択対象に追加
        setSelectedProjectId((prev: number[]) => {
          return [...prev, project.id];
        });
      }
    });

    setInvoice(tempInvoice);
  }, [projects, company]);

  if (loading || !selfInfomation || !company || !projects || !invoice) {
    return <LoadingCircular flg={true} />;
  }

  return (
    <FlexColumnBox gapSize={6}>
      <Modal
        open={sheetModal}
        onClose={() => setSheetModal(false)}
        title={sheetName}
        content={
          selectedInvoice &&
          details && (
            <PDFPreview
              invoice={selectedInvoice}
              details={details}
              mycompany={selfInfomation}
            />
          )
        }
        actions={
          <>
            <SecondButton
              onClick={() => {
                setSheetModal(false);
                navigate(`/paperwork/closing`);
              }}
              label="閉じる"
            />
            {selectedInvoice && details && (
              <PDFDownload
                invoice={selectedInvoice}
                details={details}
                mycompany={selfInfomation}
                name={sheetName}
              />
            )}
          </>
        }
      />
      <FlexBox>
        <Box sx={{ display: "flex", flexFlow: "row" }}>
          <PrimaryButton onClick={handleClosingClick} label="締め処理" />
        </Box>
      </FlexBox>

      <FlexBox>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">{invoice.c_name}</Typography>
          </Grid>
          <ClosingComponent.CompanyPostalCode
            values={invoice}
            setValues={setInvoice}
          />
          <ClosingComponent.CompanyAddress1
            values={invoice}
            setValues={setInvoice}
          />
          <ClosingComponent.CompanyAddress2
            values={invoice}
            setValues={setInvoice}
          />
          {p_id && p_id !== "0" && (
            <ClosingComponent.ProjectInChargeName
              values={invoice}
              setValues={setInvoice}
            />
          )}
          <Grid item xs={12}></Grid>
        </Grid>
      </FlexBox>

      <FlexBox>
        <TableContainer
          component={Paper}
          sx={{ maxWidth: theme.breakpoints.values.sm }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>合計</TableCell>
                <TableCell>消費税額</TableCell>
                <TableCell>請求金額</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography className="number">
                    {formatNumber(String(invoice.total_amount))}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="number">
                    {formatNumber(String(invoice.tax))}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className="number">
                    {formatNumber(String(invoice.billing_amount))}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </FlexBox>

      <FlexBox>
        {projects ? (
          <TableContainer component={Paper} sx={{ margin: "0 auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>対象</TableCell>
                  <TableCell>区分</TableCell>
                  <TableCell>初回積地情報</TableCell>
                  <TableCell>最終卸地情報</TableCell>
                  <TableCell>金額</TableCell>
                  <TableCell>備考</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projects.map((project) => {
                  return (
                    <TableRow key={`project-list-${project.id}`}>
                      <TableCell sx={{ textAlign: "center" }}>
                        <Checkbox
                          checked={selectedProjectId.includes(project.id)}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => handleSelectedProject(project)}
                        />
                      </TableCell>
                      <TableCell>{project.project_kind?.name}</TableCell>
                      <TableCell>
                        <li>{strDateOrigin(project.load_date)}</li>
                        <li>{getLoadName(project)}</li>
                      </TableCell>
                      <TableCell>
                        <li>{strDateOrigin(project.unload_date)}</li>
                        <li>{getUnloadName(project)}</li>
                      </TableCell>
                      <TableCell>
                        <Typography className="number">
                          {formatNumber(
                            String(
                              project.price +
                                project.price_tax +
                                project.price_others_sum
                            )
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <ClosingComponent.ProjectMemo
                          values={projectMemo}
                          setValues={setProjctMemo}
                          pj_id={project.id}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <PageNation
              totalPages={totalPages}
              currentPage={currentPage}
              handlePageChange={(
                e: React.ChangeEvent<unknown>,
                value: number
              ) => getSearch(value)}
            />
          </TableContainer>
        ) : (
          <Typography>案件情報はありません</Typography>
        )}
      </FlexBox>
    </FlexColumnBox>
  );
}
