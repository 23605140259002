import { tProject } from "types/project";
import * as constproject from "const/project/index";
import { tErrMsg, ErrMsgBox } from "components/ErrorMessage";

export function checkClosingProject(project: tProject): tErrMsg {
  let err: tErrMsg = {};

  const pjStatus = [];
  const pjPrice = [];
  // ステータスが満た無い
  /*
  if (project.status < constproject.projectStatusPending.id) {
    err.status.push(
      `ステータスが${constproject.projectStatusPending.label}未満です`
    );
  }
    */
  // 今だけ2024/10/10
  if (project.status < constproject.projectStatusOrdered.id) {
    pjStatus.push(
      `ステータスが${constproject.projectStatusOrdered.label}未満です`
    );
  }

  // ステータスが満た無い
  if (project.status >= constproject.projectStatusClosed.id) {
    pjStatus.push(`処理済みです`);
  }

  if (project.price === 0) {
    pjPrice.push(`金額が0です`);
  }

  if (pjStatus.length > 0) {
    err.status = pjStatus;
  }
  if (pjPrice.length > 0) {
    err.price = pjPrice;
  }

  return err;
}
