import React, { useContext } from "react";
import { PrimaryButton, SecondButton } from "atoms/Button";
import { tAddress } from "types/mst";
import { tInstruction } from "types/instruction";
import { useValidate } from "functions/instruction/validate";
import { tErrMsg } from "components/ErrorMessage";
import ErrModal from "components/ErrModal";
import { initialAddress } from "types/mst";
import { MstDataContext } from "contexts/Mst";
import {
  confirmTempInstructions,
  storeTempInstructions,
  destroyTempInstructions,
} from "functions/api/instruction";
import { tTransport, tTransportKey, tTransportCard } from "types/transport";
import { useNavigate } from "react-router-dom";

interface ConfirmProps {
  values: any;
  startAddress: tAddress;
  endAddress: tAddress;
  pjId: number;
  tranNo: number;
  setInstructions: React.Dispatch<React.SetStateAction<tInstruction[]>>;
  callbackNomal: () => void;
  callbackError: () => void;
}

/**
 * setInstructionsに入力内容を追加するボタン
 * @param param0
 * @returns
 */
export const TmpConfirm = ({
  values,
  startAddress,
  endAddress,
  pjId,
  tranNo,
  setInstructions,
  callbackNomal,
  callbackError,
}: ConfirmProps) => {
  const { SYSTEM } = useContext(MstDataContext);
  const { validate } = useValidate();
  const [showModal, setShowModal] = React.useState(false);
  const [validationMsg, setValidationMsg] = React.useState<tErrMsg>({});

  /**
   * 入力内容を確定し、送信内容に追加
   * @param pj_id
   * @param no
   * @param values
   * @param loadAddress
   * @param unloadAddress
   * @param setInstructions
   * @returns
   */
  const hadleClick = (
    pj_id: number,
    no: number,
    values: any,
    loadAddress: tAddress,
    unloadAddress: tAddress,
    setInstructions: React.Dispatch<React.SetStateAction<tInstruction[]>>
  ) => {
    console.log("SecondButton instruction", values);

    // loadAddressをvaluesに追加
    values.start_a_id = loadAddress.id;
    values.start_name = loadAddress.name;
    values.start_prefectures = loadAddress.prefectures;
    values.start_city = loadAddress.city;
    values.start_street = loadAddress.street;
    values.start_building = loadAddress.building;

    // unloadAddressをvaluesに追加
    values.end_a_id = unloadAddress.id;
    values.end_name = unloadAddress.name;
    values.end_prefectures = unloadAddress.prefectures;
    values.end_city = unloadAddress.city;
    values.end_street = unloadAddress.street;
    values.end_building = unloadAddress.building;

    // バリデーション
    let errMsg: tErrMsg = {};
    errMsg = validate(values);

    // エラーメッセージ
    if (Object.keys(errMsg).length > 0) {
      setValidationMsg(errMsg);
      setShowModal(true);
      return;
    }

    // 送信用データに追加
    setInstructions((prev) => {
      return [
        ...prev,
        {
          ...values,
          pj_id: pj_id || 0,
          no: no || 0,
          order: prev.length + 1,
        },
      ];
    });
    callbackNomal();
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <SecondButton
        label="次へ"
        onClick={() => {
          hadleClick(
            pjId,
            tranNo,
            values,
            startAddress,
            endAddress,
            setInstructions
          );
        }}
      />
    </>
  );
};

interface InsertProps {
  instructions: tInstruction[];
  pjId: number;
  tranNo: number;
  CallbackSuccess: () => void;
}
/**
 * データ登録ボタン
 * ※運行指示は全て新規登録、更新はない
 */
export const TmpInsert = ({
  instructions,
  pjId,
  tranNo,
  CallbackSuccess,
}: InsertProps) => {
  /**
   * 確定処理
   */
  const handleClick = async () => {
    if (instructions.length === 0) {
      // 削除処理
      destroyTempInstructions({
        pj_id: pjId || 0,
        no: tranNo || 0,
      })
        .then((res) => {
          if (res.status !== 200) throw new Error("データ削除に失敗しました");
          CallbackSuccess();
        })
        .catch((err) => {
          console.error(err);
          alert("データ削除に失敗しました");
        });
    } else {
      // tmpテーブルに登録
      storeTempInstructions(instructions)
        .then((res) => {
          if (res.status !== 200) throw new Error("データ登録に失敗しました");
          CallbackSuccess();
        })
        .catch((err) => {
          console.error(err);
          alert("データ登録に失敗しました");
        });
    }
  };

  return <PrimaryButton label="確定" onClick={handleClick} />;
};

export const InsertUpdate = ({
  transports,
  callbackSuccess,
  callbackError,
}: {
  transports: tTransportCard[];
  callbackSuccess: () => void;
  callbackError: () => void;
}) => {
  const handleConfirmClick = () => {
    if (transports.length === 0) {
      alert("運行指示を選択してください");
      return;
    }

    const tmp: tTransportKey[] = [];

    if (transports.length >= 0) {
      transports.map((transport: tTransportCard) => {
        tmp.push({ pj_id: transport.pj_id, no: transport.tran_no });
      });
    }

    confirmTempInstructions(tmp)
      .then((res) => {
        if (res.status !== 200) throw new Error("データ登録に失敗しました");
        alert("運行指示を作成しました");
        callbackSuccess();
      })
      .catch((err) => {
        console.error(err);
        alert("データ登録に失敗しました");
        callbackError();
      });
  };

  return <PrimaryButton label="運行指示作成" onClick={handleConfirmClick} />;
};
