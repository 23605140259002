import { useContext, useEffect, useState } from "react";
import { useSelection } from "contexts/Schedules";
import { tTransportCard } from "types/transport";
import { MstDataContext } from "contexts/Mst";
import { Container, Button, Typography } from "@mui/material";
import { useTheme, Theme } from "@mui/material/styles";
import { DragTransportCardArea } from "components/TransportCard";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { tUser } from "types/mst";
import { TransportCardBlank } from "components/TransportCard";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getTempInstructionListByDriver } from "functions/api/instruction";
import { getTransportCard } from "functions/api/transport";
import { tProjectCard } from "types/project";
import ProjectCard from "components/project/Card";
import InstructionModal from "components/instruction/Modal";
import * as InstButton from "components/instruction/Button";
import initInstruction from "const/instruction";

const ddType = "TransportCard";

type tPjCardCustom = tProjectCard & { tran_no: number };
type tOperation = tUser & { vie_driver_tmp_inst_project: tPjCardCustom[] };

export default function Main() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { transportCards } = useSelection();

  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            flexGrow: 1, // 残りの高さを占める
            transition: "height 0.3s ease",
            overflow: "auto", // 高さが足りない時にスクロールバーを表示
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: theme.spacing(2),
              m: theme.spacing(2),
            }}
          >
            <InstButton.InsertUpdate
              transports={transportCards}
              callbackSuccess={() => {
                //navigate("/operation/index");
              }}
              callbackError={() => {}}
            />
          </Box>
          <DriverArea />
        </Box>
        {/* Bのエリア: クリックで高さが0 -> 100pxに変わる */}
        <Container
          sx={{
            height: "auto",
            backgroundColor: theme.palette.common.white,
            transition: "height 0.3s ease",
            //overflow: "hidden", // 高さが0の時にコンテンツが見えないように
          }}
        >
          <DragTransportCardArea
            ddType={ddType}
            transportCards={transportCards}
            optionNode={
              <Button
                variant="contained"
                onClick={() => {
                  navigate("/full-screen/schedules");
                }}
              >
                予定表へ
              </Button>
            }
          />
        </Container>
      </Box>
    </DndProvider>
  );
}

function DriverArea() {
  const theme = useTheme();
  const { users, roles, SYSTEM } = useContext(MstDataContext);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTransport, setSelectedTransport] = useState<tTransportCard>();
  const [selectedDriver, setSelectedDriver] = useState<tUser>();

  const [operations, setOperations] = useState<tOperation[]>([]);

  // データ取得処理
  const handleLoadOperations = () => {
    getTempInstructionListByDriver()
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("データの取得に失敗しました");
        }
        console.log("getTempInstructionListByDriver", res.data);
        setOperations(res.data);
      })
      .catch((err) => {
        console.error(err);
        alert("データの取得に失敗しました");
      });
  };

  /**
   * 案件カードを運行指示にドロップ
   * @param driver
   * @param item
   */
  const handleDrop = (driver: tUser, item: tTransportCard) => {
    console.log("handleDrop", item);

    // 運行指示入力モーダルを表示
    setOpenModal(true);
    setSelectedTransport(item);
    setSelectedDriver(driver);
  };

  const handlePjCardClick = (driver: tUser, pj: tPjCardCustom) => {
    // pjから運行指示を取得する
    getTransportCard(pj.pj_id, pj.tran_no)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("データの取得に失敗しました");
        }
        console.log("getTransportCard", res.data);
        setSelectedTransport(res.data);
      })
      .catch((err) => {
        console.error(err);
        alert("データの取得に失敗しました");
      });

    // 運行指示入力モーダルを表示
    setOpenModal(true);
    setSelectedDriver(driver);
  };

  const closeInstModal = () => {
    setOpenModal(false);
    setSelectedTransport(undefined);
    setSelectedDriver(undefined);
  };

  useEffect(() => {
    if (!openModal) {
      setSelectedDriver(undefined);
    }
  }, [openModal]);

  useEffect(() => {
    handleLoadOperations();
  }, []);

  return (
    <Box>
      <InstructionModal
        open={selectedTransport !== undefined ? true : false}
        onClose={closeInstModal}
        pjId={selectedTransport?.pj_id || 0}
        tranNo={selectedTransport?.tran_no || 0}
        info={{ ...initInstruction, user_id: selectedDriver?.id || 0 }}
        callbackNomal={() => {
          closeInstModal();
          handleLoadOperations(); // 再読み込み
        }}
        callbackError={() => {}}
      />

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "150px" }}>運転手</TableCell>
              <TableCell>
                <Typography>案件情報</Typography>
                <Typography></Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {operations?.map((driver: any) => {
              return (
                <Driver
                  key={`driver-${driver.id}`}
                  driver={driver}
                  callbackDrop={handleDrop}
                  callbackClick={handlePjCardClick}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function Driver({
  driver,
  callbackDrop,
  callbackClick,
}: {
  driver: tOperation;
  callbackDrop: (driver: tUser, item: tTransportCard) => void;
  callbackClick: (driver: tUser, item: tPjCardCustom) => void;
}) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ddType,
    drop: (item: tTransportCard) => {
      callbackDrop(driver, item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <TableRow>
      <TableCell>{`${driver.family_name}${driver.given_name}`}</TableCell>

      <TableCell>
        {driver.vie_driver_tmp_inst_project?.map((pjCard: tPjCardCustom) => {
          return (
            <ProjectCard
              key={`project-${pjCard.pj_id}`}
              projectCard={pjCard}
              flgOmit={false}
              callbackClick={(event) => {
                callbackClick(driver, pjCard);
              }}
            />
          );
        })}
        <TransportCardBlank
          ref={drop}
          flgOmit={false}
          label="新"
          callbackDoubleClick={(event) => {
            //callbackClick(driver);
          }}
          callbackClick={(event) => {}}
          isOver={isOver}
        />
      </TableCell>
    </TableRow>
  );
}
