/**
 * 運行指示一覧表示
 *
 *  */
import React, { useEffect, useState } from "react";
import { tProject } from "types/project";
import { MathDateTime, changeDateFromTypeDate } from "functions/time";
import { tInstruction, tInstSearch } from "types/instruction";
import { tInstructionCard } from "types/instruction";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { PrimaryButton } from "atoms/Button";
import PJModal from "components/project/Modal";
import PJSearchModal from "components/project/Search";
import InstructionModal from "components/instruction/Modal";
import { getInstructionCards } from "functions/api/instruction";
import instSearchDefault from "const/instruction/search";

export default function Main() {
  const theme = useTheme();

  const [instructions, setInstructions] = useState<tInstructionCard[]>([]);

  const [selectedPJID, setSelectedPJID] = useState<tProject["id"]>();

  const [openSearch, setOpenSearch] = useState(false);
  const [searchInst, setSearchInst] = useState<tInstSearch>(instSearchDefault);

  const procSearch = () => {
    getInstructionCards(
      (data: tInstructionCard[]) => setInstructions(data),
      () => {},
      searchInst,
      [
        { field: "start_datetime", direction: "asc" },
        { field: "driver_id", direction: "asc" },
        { field: "pj_id", direction: "asc" },
        { field: "no", direction: "asc" },
        { field: "order", direction: "asc" },
      ]
    );
  };

  useEffect(() => {
    procSearch();
  }, []);

  return (
    <Box sx={{ display: "flex", flexFlow: "column", gap: theme.spacing(2) }}>
      <Box>
        <PrimaryButton label="検索" onClick={() => setOpenSearch(true)} />
      </Box>
      <PJModal
        open={selectedPJID !== undefined}
        onClose={() => setSelectedPJID(undefined)}
        pjId={selectedPJID ? selectedPJID : 0}
        callbackNomal={procSearch}
        callbackError={() => {}}
      />
      <Box sx={{ display: "flex", flexFlow: "column", gap: theme.spacing(2) }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>輸送方法</TableCell>
                <TableCell>開始情報</TableCell>
                <TableCell>終了情報</TableCell>
                <TableCell>ステータス</TableCell>
                <TableCell>指示日</TableCell>
                <TableCell>実績</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {instructions.map((instruction: tInstructionCard) => {
                return (
                  <TableRow key={`instruction-${instruction.pj_id}`}>
                    <TableCell>
                      <Typography>{`${instruction.tm_id}`}</Typography>
                      <Typography>{`${instruction.driver_name}`}</Typography>
                      <Typography>{`${instruction.c_id}`}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{`${instruction.start_datetime}`}</Typography>
                      <Typography>{`${instruction.start_name}`}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{`${instruction.end_datetime}`}</Typography>
                      <Typography>{`${instruction.end_name}`}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{`${instruction.status}`}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{`${instruction.instruction_datetime}`}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {`${instruction.execute_datetime}`}
                      </Typography>
                      <Typography>
                        {`${instruction.complete_datetime}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
}
