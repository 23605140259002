import React, { act, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface MainProps {
  title: string;
  content: React.ReactNode;
  actions?: React.ReactNode;
  open: boolean;
  onClose: () => void;
  width?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}
export default function Main({
  title,
  content,
  actions,
  open,
  onClose,
  width = false,
}: MainProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={width}
      fullWidth={width !== false ? true : false}
    >
      <DialogTitle component={"h3"} variant="h3">
        {title}
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
}

// 閉じる処理
/*
const handleClose = (
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  callback?: () => void
) => {
  setOpen(false);
  if (!callback) return;
  callback();
};
*/
