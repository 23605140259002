import { tTransportCard } from "types/transport";

/**
 * 輸送情報がリストに含まれているか確認
 * @returns
 */
export const checkInTransportCard = (
  list: tTransportCard[],
  target: tTransportCard
) => {
  return list.some(
    (card) => card.pj_id === target.pj_id && card.tran_no === target.tran_no
  );
};

/**
 * load_dateの日付部分をキーにして、tTransportCardの配列をグループ化する関数
 *
 * @param transportCard - tTransportCard
 * @returns 日付をキーにした連想配列
 */
export function groupTransportCardsByDate(
  transportCards: tTransportCard[]
): Record<string, tTransportCard[]> {
  return transportCards.reduce(
    (acc: Record<string, tTransportCard[]>, tranCard) => {
      // load_dateから日付部分を取得 (Y-m-d 部分のみ)
      const dateKey = tranCard.load_date.split(" ")[0]; // "Y-m-d H:i:s" -> "Y-m-d"

      // accに既にdateKeyがあれば、その配列に追加し、なければ新しい配列を作成
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(tranCard);

      return acc;
    },
    {} as Record<string, tTransportCard[]>
  );
}
