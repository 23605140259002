import { create, API_URL, getCsrf } from "functions/axios";
import {
  tRole,
  tAddress,
  tCompany,
  tLuggage,
  tPerson,
  tProjectKind,
  tUser,
  tVehicle,
  tVehicleType,
  tSelfInformation,
  tTransportMethod,
} from "types/mst";

/**
 * ユーザマスタ
 * @returns
 */
export function getUsers(page: number, filter: any = {}) {
  const ins = create();
  return ins.get(API_URL.base + "/user/index", {
    params: { page: page, filter: filter },
  });
}
export function getUserList(params: any = {}) {
  const ins = create();
  return ins.get(API_URL.base + "/user/list", { params: params });
}
export function storeUser(data: tUser) {
  const ins = create();
  return ins.post(API_URL.base + "/user/store", data);
}
export function getUser(id: number) {
  const ins = create();
  return ins.get(API_URL.base + "/user/show/" + id);
}
export function updateUser(data: tUser) {
  const ins = create();
  return ins.post(API_URL.base + "/user/update/" + data.id, data);
}
export function deleteUser(id: number) {
  const ins = create();
  return ins.post(API_URL.base + "/user/destroy/" + id);
}

/**
 * 権限マスタ
 * @returns
 */
export function getRoles(page: number, filter: any = {}) {
  const ins = create();
  return ins.get(API_URL.base + "/role/index", {
    params: { page: page, filter: filter },
  });
}
export function getRoleList() {
  const ins = create();
  return ins.get(API_URL.base + "/role/list");
}
export function storeRole(data: tRole) {
  const ins = create();
  return ins.post(API_URL.base + "/role/store", data);
}
export function getRole(id: number) {
  const ins = create();
  return ins.get(API_URL.base + "/role/show/" + id);
}
export function updateRole(data: tRole) {
  const ins = create();
  return ins.post(API_URL.base + "/role/update/" + data.id, data);
}
export function deleteRole(id: number) {
  const ins = create();
  return ins.post(API_URL.base + "/role/destroy/" + id);
}

/**
 * 案件区分
 * @returns
 */
export function getProjectKinds(page: number, filter: any = {}) {
  const ins = create();
  return ins.get(API_URL.base + "/project-kind/index", {
    params: { page: page, filter: filter },
  });
}
export function getProjectKindList() {
  const ins = create();
  return ins.get(API_URL.base + "/project-kind/list");
}
export function storeProjectKind(data: tProjectKind) {
  const ins = create();
  return ins.post(API_URL.base + "/project-kind/store", data);
}
export function getProjectKind(id: number) {
  const ins = create();
  return ins.get(API_URL.base + "/project-kind/show/" + id);
}
export function updateProjectKind(data: tProjectKind) {
  const ins = create();
  return ins.post(API_URL.base + "/project-kind/update/" + data.id, data);
}
export function deleteProjectKind(id: number) {
  const ins = create();
  return ins.post(API_URL.base + "/project-kind/destroy/" + id);
}

/**
 * 取引先マスタ
 * @returns
 */
export function getCompanies(page: number, filter: any = {}, order: any = []) {
  if (order.length === 0) {
    order = [
      { field: "name", direction: "asc" },
      { field: "abbreviation", direction: "asc" },
    ];
  }
  const ins = create();
  return ins.get(API_URL.base + "/company/index", {
    params: { page: page, filter: filter },
  });
}
export function getCompanyList(filter: any = {}, order: any = []) {
  if (order.length === 0) {
    order = [
      { field: "name", direction: "asc" },
      { field: "abbreviation", direction: "asc" },
    ];
  }
  const ins = create();
  return ins.get(API_URL.base + "/company/list");
}
export function storeCompany(data: tCompany) {
  const ins = create();
  return ins.post(API_URL.base + "/company/store", data);
}
export function getCompany(id: number) {
  const ins = create();
  return ins.get(API_URL.base + "/company/show/" + id);
}
export function updateCompany(data: tCompany) {
  const ins = create();
  return ins.post(API_URL.base + "/company/update/" + data.id, data);
}
export function deleteCompany(id: number) {
  const ins = create();
  return ins.post(API_URL.base + "/company/destroy/" + id);
}
/**
 * 案件情報のステータスに一致する取引先を取得
 */
export function getProjectStatusCompanies(
  page: number,
  filter: any = {},
  orders: any = {}
) {
  const ins = create();
  return ins.get(API_URL.base + "/company/project-status/index", {
    params: { page: page, filter: filter, order: orders },
  });
}

/**
 * 人物マスタ
 */
export function getCompanyPersonList(cId: number) {
  const ins = create();
  return ins.get(API_URL.base + "/person/list", {
    params: { c_id: cId },
  });
}
export function getCompanyPersons(
  page: number,
  limit: number,
  cId: number,
  filter: any = {}
) {
  const ins = create();
  return ins.get(API_URL.base + "/person/index", {
    params: { page: page, limit: limit, filter: { c_id: cId, ...filter } },
  });
}
export function storeCompanyPerson(data: tPerson) {
  const ins = create();
  return ins.post(API_URL.base + "/person/store", data);
}
export function getCompanyPerson(id: number) {
  const ins = create();
  return ins.get(API_URL.base + "/person/show/" + id);
}
export function updateCompanyPerson(data: tPerson) {
  const ins = create();
  return ins.post(API_URL.base + "/person/update/" + data.id, data);
}
export function deletePerson(id: number) {
  const ins = create();
  return ins.post(API_URL.base + "/person/destroy/" + id);
}

/**
 * 荷物種類マスタ
 */
export function getLuggages(page: number, filter: any = {}) {
  const ins = create();
  return ins.get(API_URL.base + "/luggage/index", {
    params: { page: page, filter: filter },
  });
}
export function getLuggageList() {
  const ins = create();
  return ins.get(API_URL.base + "/luggage/list", {});
}
export function getLuggage(id: number) {
  const ins = create();
  return ins.get(API_URL.base + "/luggage/show/" + id, {});
}
export function postLuggage(data: tLuggage) {
  const ins = create();
  return ins.post(API_URL.base + "/luggage/store", data);
}
export function updateLuggage(data: tLuggage) {
  const ins = create();
  return ins.post(API_URL.base + "/luggage/update/" + data.id, data);
}
export function deleteLuggage(id: number) {
  const ins = create();
  return ins.post(API_URL.base + "/luggage/destroy/" + id);
}

/**
 * 車種マスタ
 */
export function getCarModel() {
  const ins = create();
  return ins.get(API_URL.base + "/car-model/index", {});
}

/**
 * 車両マスタ
 */
export function getVehicles(
  page: number,
  filter: any = {},
  order: any = [
    "CAST(plate_number AS UNSIGNED) ASC",
    { field: "plate_branch", direction: "asc" },
    "CAST(plate_kind AS UNSIGNED) ASC",
    { field: "plate_hiragana", direction: "asc" },
  ]
) {
  const ins = create();
  return ins.get(API_URL.base + "/vehicle/index", {
    params: { page: page, filter: filter, order: order },
  });
}
export function getVehicleList(
  filter: any = {},
  order: any = [
    "CAST(plate_number AS UNSIGNED) ASC",
    { field: "plate_branch", direction: "asc" },
    "CAST(plate_kind AS UNSIGNED) ASC",
    { field: "plate_hiragana", direction: "asc" },
  ]
) {
  const ins = create();
  return ins.get(API_URL.base + "/vehicle/list", {
    params: {
      filter: filter,
      order: order,
    },
  });
}
export function storeVehicle(data: tVehicle) {
  const ins = create();
  return ins.post(API_URL.base + "/vehicle/store", data);
}
export function getVehicle(id: number) {
  const ins = create();
  return ins.get(API_URL.base + "/vehicle/show/" + id);
}
export function updateVehicle(data: tVehicle) {
  const ins = create();
  return ins.post(API_URL.base + "/vehicle/update/" + data.id, data);
}
export function deleteVehicle(id: number) {
  const ins = create();
  return ins.post(API_URL.base + "/vehicle/destroy/" + id);
}

/**
 * 車両種マスタ
 */
export function getVehicleTypes(page: number, filter: any = {}) {
  const ins = create();
  return ins.get(API_URL.base + "/vehicleType/index", {
    params: { page: page, filter: filter },
  });
}
export function getVehicleTypeList() {
  const ins = create();
  return ins.get(API_URL.base + "/vehicleType/list", {});
}
export function storeVehicleType(data: tVehicleType) {
  const ins = create();
  return ins.post(API_URL.base + "/vehicleType/store", data);
}
export function getVehicleType(id: number) {
  const ins = create();
  return ins.get(API_URL.base + "/vehicleType/show/" + id);
}
export function updateVehicleType(data: tVehicleType) {
  const ins = create();
  return ins.post(API_URL.base + "/vehicleType/update/" + data.id, data);
}
export function deleteVehicleType(id: number) {
  const ins = create();
  return ins.post(API_URL.base + "/vehicleType/destroy/" + id);
}

/**
 * 住所マスタ
 */
export function getAddresses(page: number, filter: any = {}) {
  const ins = create();
  return ins.get(API_URL.base + "/address/index", {
    params: { page: page, filter: filter },
  });
}
export function getAddressList() {
  const ins = create();
  return ins.get(API_URL.base + "/address/list", {});
}
export function storeAddress(data: tAddress) {
  const ins = create();
  return ins.post(API_URL.base + "/address/store", data);
}
export function getAddress(id: number) {
  const ins = create();
  return ins.get(API_URL.base + "/address/show/" + id);
}
export function updateAddress(data: tAddress) {
  const ins = create();
  return ins.post(API_URL.base + "/address/update/" + data.id, data);
}
export function deleteAddress(id: number) {
  const ins = create();
  return ins.post(API_URL.base + "/address/destroy/" + id);
}

/**
 * 自社情報マスタ
 * @returns
 */
export function storeSelfInfomation(data: tSelfInformation) {
  const ins = create();
  return ins.post(API_URL.base + "/self-infomation/store", data);
}
export function getSelfInfomation() {
  const ins = create();
  return ins.get(API_URL.base + "/self-infomation/show");
}
export function upOrCrSelfInfomation(data: tSelfInformation) {
  const ins = create();
  return ins.post(API_URL.base + "/self-infomation/update-or-create/", data);
}

/**
 * 輸送手段マスタ
 */
export function getTransportMethods(page: number, filter: any = {}) {
  const ins = create();
  return ins.get(API_URL.base + "/transport-method/index", {
    params: { page: page, filter: filter },
  });
}
export function getTransportMethodList() {
  const ins = create();
  return ins.get(API_URL.base + "/transport-method/list", {});
}
export function storeTransportMethod(data: tTransportMethod) {
  const ins = create();
  return ins.post(API_URL.base + "/transport-method/store", data);
}
export function getTransportMethod(id: number) {
  const ins = create();
  return ins.get(API_URL.base + "/transport-method/show/" + id);
}
export function updateTransportMethod(data: tTransportMethod) {
  const ins = create();
  return ins.post(API_URL.base + "/transport-method/update/" + data.id, data);
}
export function deleteTransportMethod(id: number) {
  const ins = create();
  return ins.post(API_URL.base + "/transport-method/destroy/" + id);
}
