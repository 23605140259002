import React, { useState, useEffect, useContext } from "react";
import Modal from "atoms/Modal";
import InstructionDetail from "components/instruction/Detail";
import { tInstruction } from "types/instruction";
import { SecondButton } from "atoms/Button";
import * as InstButton from "components/instruction/Button";
import { tAddress } from "types/mst";
import { getTempInstructionList } from "functions/api/instruction";
import { initialAddress } from "types/mst";
import initInstruction from "const/instruction/index";
import { MstDataContext } from "contexts/Mst";
import { tUser } from "types/mst";

interface MainProps {
  open: boolean;
  onClose: () => void;
  pjId: number;
  tranNo: number;
  info: tInstruction | undefined;
  callbackNomal: () => void;
  callbackError: () => void;
}

export default function Main({
  open,
  onClose,
  pjId,
  tranNo,
  info,
  callbackNomal,
  callbackError,
}: MainProps) {
  const { SYSTEM } = useContext(MstDataContext);

  const initInstCustom = {
    ...initInstruction,
    tm_id: info?.tm_id || initInstruction.tm_id,
    user_id: info?.user_id || initInstruction.user_id,
    start_datetime: info?.start_datetime || initInstruction.start_datetime,
  };

  // 入力内容
  const [values, setValues] = useState<tInstruction>(initInstCustom);

  //
  const [startAddress, setStartAddress] = useState<tAddress>(initialAddress);
  const [endAddress, setEndAddress] = useState<tAddress>(initialAddress);

  const [instructions, setInstructions] = useState<tInstruction[]>([]);

  /**
   * ドライバーが変更された場合の処理
   */
  useEffect(() => {
    setValues((prev) => {
      return {
        ...prev,
        tm_id: info?.tm_id || initInstruction.tm_id,
        user_id: info?.user_id || initInstruction.user_id,
        start_datetime: info?.start_datetime || initInstruction.start_datetime,
      };
    });
  }, [info, SYSTEM]);

  /**
   * 初期表示、データ取得処理
   */
  useEffect(() => {
    if (!open || !pjId || !tranNo) {
      setValues(initInstCustom);
      setInstructions([]);
      setStartAddress(initialAddress);
      setEndAddress(initialAddress);
      return;
    }
    getTempInstructionList(
      (data) => {
        setInstructions(data);
      },
      () => {},
      { pj_id: pjId, no: tranNo }
    );
  }, [pjId, tranNo]);

  /**
   * 次へボタンクリック時の処理
   */
  const handleConfirmClick = () => {
    // 入力欄の初期化
    setStartAddress({ ...endAddress });
    setValues((prev: any) => {
      return {
        ...prev,
        tm_id: SYSTEM?.tranMethod.own.id || 0,
        start_datetime: values.end_datetime,
        start_a_id: values.end_a_id,
        start_name: values.end_name,
        start_prefectures: values.end_prefectures,
        start_city: values.end_city,
        start_street: values.end_street,
        start_building: values.end_building,
        start_trailer_v_id: values.end_trailer_v_id,

        end_datetime: values.end_datetime,
        end_a_id: 0,
        end_name: "",
        end_prefectures: "",
        end_city: "",
        end_street: "",
        end_building: "",
        end_trailer_v_id: null,
      };
    });
    setEndAddress(initialAddress);
  };

  return (
    <Modal
      width={"lg"}
      title={`運行指示`}
      content={
        <InstructionDetail
          pjId={pjId}
          tranNo={tranNo}
          values={values}
          setValues={setValues}
          instructions={instructions}
          setInstructions={setInstructions}
          startAddress={startAddress}
          setStartAddress={setStartAddress}
          endAddress={endAddress}
          setEndAddress={setEndAddress}
          closeCallback={() => onClose}
        />
      }
      open={open}
      onClose={onClose}
      actions={
        <>
          <InstButton.TmpConfirm
            values={values}
            startAddress={startAddress}
            endAddress={endAddress}
            pjId={pjId}
            tranNo={tranNo}
            setInstructions={setInstructions}
            callbackNomal={handleConfirmClick}
            callbackError={callbackError}
          />
          <InstButton.TmpInsert
            instructions={instructions}
            pjId={pjId}
            tranNo={tranNo}
            CallbackSuccess={callbackNomal}
          />
          <SecondButton onClick={onClose} label={"閉じる"} />
        </>
      }
    />
  );
}
