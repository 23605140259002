import { ItemTextFiled, ItemCheckbox } from "components/Common";
import { tRole } from "types/mst";

interface GridItemFieldProps {
  values: tRole;
  setValues: React.Dispatch<React.SetStateAction<tRole>>;
}

export const Name = ({ values, setValues }: GridItemFieldProps) => (
  <ItemTextFiled
    label="名称"
    value={values.name}
    name="name"
    setValues={setValues}
  />
);

export const FlgAdmin = ({ values, setValues }: GridItemFieldProps) => (
  <ItemCheckbox
    label="管理者"
    name="admin"
    value={values.admin}
    setValues={setValues}
  />
);

export const FlgProject = ({ values, setValues }: GridItemFieldProps) => (
  <ItemCheckbox
    label="案件"
    name="project"
    value={values.project}
    setValues={setValues}
  />
);

export const FlgOperation = ({ values, setValues }: GridItemFieldProps) => (
  <ItemCheckbox
    label="配車"
    name="operation"
    value={values.operation}
    setValues={setValues}
  />
);

export const FlgPaperwork = ({ values, setValues }: GridItemFieldProps) => (
  <ItemCheckbox
    label="事務"
    name="paperwork"
    value={values.paperwork}
    setValues={setValues}
  />
);

export const FlgMst = ({ values, setValues }: GridItemFieldProps) => (
  <ItemCheckbox
    label="マスタ"
    name="mst"
    value={values.mst}
    setValues={setValues}
  />
);

export const FlgDriver = ({ values, setValues }: GridItemFieldProps) => (
  <ItemCheckbox
    label="ドライバー"
    name="driver"
    value={values.driver}
    setValues={setValues}
  />
);
