import { create, API_URL, getCsrf } from "functions/axios";

/**
 * デイリーメモリスト
 * @returns
 */
export function getDailyMemoList(filter: any = {}, order: any = []) {
  if (order.length === 0) {
    order = [
      { field: "datetime", direction: "asc" },
      { field: "id", direction: "asc" },
    ];
  }
  const ins = create();
  return ins.get(API_URL.base + "/dailyMemo/list", {
    params: { filter: filter, order: order },
  });
}

/**
 *
 * @param data
 * @returns
 */
export function storeDailyMemo(data: any = {}) {
  const ins = create();
  return ins.post(API_URL.base + "/dailyMemo/store", data);
}
