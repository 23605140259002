import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { tAddress } from "types/mst";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Grid,
  Button,
} from "@mui/material";
import { getAddresses } from "functions/api/mst";
import { PrimaryButton, SecondButton } from "atoms/Button";
import PageNation, { handlePageChange } from "components/Pagenation";
import * as cMst from "components/mst/Index";

export default function Main() {
  const navigate = useNavigate();
  const [addresses, setAddresses] = useState<tAddress[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState({});

  // データ取得処理
  const getSearch = async (page: number = 0) => {
    try {
      // ここにデータ取得処理を記述します
      getAddresses(page === 0 ? currentPage : page, search)
        .then((res) => {
          if (res.status !== 200) throw new Error("データ取得に失敗しました");
          setAddresses(res.data.data);
          setCurrentPage(res.data.current_page);
          setLastPage(res.data.last_page);
          setTotalPages(res.data.last_page);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSearch();
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={4} md={4} lg={2}>
            <SecondButton
              onClick={() => {
                getSearch(1);
              }}
              label="検索"
            />
          </Grid>
          <Grid item xs={4} md={4} lg={2}>
            <PrimaryButton
              onClick={() => {
                navigate("/mst/address/input");
              }}
              label="新規"
            />
          </Grid>
          <cMst.Freewords values={search} setValues={setSearch} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table sx={{ maxWidth: "680px" }}>
            <TableHead>
              <TableRow>
                <TableCell>場所名</TableCell>
                <TableCell>住所</TableCell>
                <TableCell>積地</TableCell>
                <TableCell>卸地</TableCell>
                <TableCell>事務所</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {addresses?.map((address) => {
                return (
                  <TableRow
                    key={`addresses-${address.id}`}
                    onDoubleClick={() =>
                      navigate(`/mst/address/edit/${address.id}`)
                    }
                  >
                    <TableCell>{address.name}</TableCell>
                    <TableCell>{`${address.prefectures}${address.city}${address.street}${address.building}`}</TableCell>
                    <TableCell>{address.flg_load ? "◯" : "×"}</TableCell>
                    <TableCell>{address.flg_unload ? "◯" : "×"}</TableCell>
                    <TableCell>{address.flg_office ? "◯" : "×"}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <PageNation
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
            getSearch(value)
          }
        />
      </Grid>
    </Grid>
  );
}
