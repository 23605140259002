import React, { forwardRef, useRef, useState } from "react";
import { Card, Box } from "@mui/material";
import { cardType } from "types/index";

/**
 * 輸送情報カード
 * @param param0
 * @returns
 */
const BaseCard = forwardRef<
  HTMLDivElement,
  {
    selected?: boolean;
    flgOmit?: boolean;
    callbackDoubleClick: (event: React.MouseEvent<HTMLElement>) => void;
    callbackClick: (event: React.MouseEvent<HTMLElement>) => void;
    ref?: any;
    children: React.ReactNode;
    className?: string;
    isOver?: boolean;
    otherClassName?: string;
    type?: cardType;
  }
>((props, ref) => {
  const {
    selected,
    flgOmit,
    callbackDoubleClick,
    callbackClick,
    children,
    className,
    isOver = false,
    otherClassName,
    type = "box",
  } = props;
  const clickTimeout = useRef<number | undefined>(undefined); // タイムアウトの管理

  return (
    <Card
      ref={ref}
      className={`card-custom ${selected ? "selected" : ""} ${
        flgOmit ? "omit" : ""
      } ${className || ""} ${isOver && "isover"} type-${type} ${
        otherClassName || ""
      }`}
      onDoubleClick={(event: React.MouseEvent<HTMLElement>) => {
        handleDoubleClick(event, clickTimeout, callbackDoubleClick);
      }}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        handleClick(event, clickTimeout, callbackClick);
      }}
    >
      {children}
    </Card>
  );
});

interface CardContentsProps {
  Labels: React.ReactNode;
  AttributeChip: React.ReactNode | null;
}
export const CardContents = ({ Labels, AttributeChip }: CardContentsProps) => {
  return (
    <>
      {Labels}
      <Box className="attribute-chip">{AttributeChip}</Box>
    </>
  );
};

const DOUBLE_CLICK_DELAY = 200; // ダブルクリックとシングルクリックを区別するための遅延時間

/**
 * クリック時の処理
 * @param event
 */
const handleClick = (
  event: React.MouseEvent<HTMLElement>,
  clickTimeout: React.MutableRefObject<number | undefined>,
  callbackClick: (event: React.MouseEvent<HTMLElement>) => void
) => {
  console.log("click");
  const target = event.currentTarget;
  clickTimeout.current = window.setTimeout(() => {
    callbackClick({ ...event, currentTarget: target });
  }, DOUBLE_CLICK_DELAY);
};

const handleDoubleClick = (
  event: React.MouseEvent<HTMLElement>,
  clickTimeout: React.MutableRefObject<number | undefined>,
  callbackDoubleClick: (event: React.MouseEvent<HTMLElement>) => void
) => {
  console.log("double click");
  if (clickTimeout.current) {
    clearTimeout(clickTimeout.current); // clickイベントをキャンセル
  }
  callbackDoubleClick(event);
};

export default BaseCard;
