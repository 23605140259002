import React, { useContext, useEffect } from "react";
import { ItemSelect, ItemAutoComplete, ItemTextFiled } from "components/Common";
import { List } from "types/index";
import { MstDataContext } from "contexts/Mst";
import { tTransportMethod } from "types/mst";
import { tInstruction } from "types/instruction";
import { DateInput } from "atoms/DateInput";
import { strDateCustom, strTimeOrigin } from "functions/time";
import { Typography } from "@mui/material";
import LoadingCircular from "atoms/Loading";

/**
 * 最初の車番を取得
 * @param tmpInstructions
 */
export function GetVehicle({
  instructions,
  tmpInstructions = [],
}: {
  instructions: tInstruction[];
  tmpInstructions?: tInstruction[];
}) {
  const margeInst = instructions.concat(tmpInstructions);

  // ドライバーの車番を取得
  const driver = margeInst.find((inst) => inst.v_id !== null);

  // 取得できなかった（自社車がない）場合は空文字を返す
  if (!driver) {
    return null;
  }

  let vehicleNumber = driver.vehicle?.plate_number || ""; // シャーシ番号がある場合はシャーシ番号を取得

  if (!driver.start_trailer_v_id) {
    // 最初の運行にシャーシがない場合は後ろを探す
    const chassi = margeInst.find(
      (inst) => inst.v_id !== null && inst.start_trailer_v_id !== null
    );

    if (chassi) {
      vehicleNumber = `${vehicleNumber}-${chassi.start_trailer?.plate_number}`; // シャーシがあった場合
    }
  } else {
    vehicleNumber = `${vehicleNumber}-${driver.start_trailer?.plate_number}`; // シャーシがあった場合
  }

  return <span>{vehicleNumber}</span>;
}

interface GridItemFieldProps {
  values: tInstruction;
  setValues: React.Dispatch<React.SetStateAction<tInstruction>>;
  size?: Record<string, number>;
}

export const SelectMethods = ({
  values,
  setValues,
  size = undefined,
}: GridItemFieldProps) => {
  const { loading, tranMethods } = useContext(MstDataContext);

  const options = tranMethods?.map((method: tTransportMethod) => ({
    id: method.id,
    label: method.name,
  })) as List;

  if (loading || !tranMethods) {
    return <LoadingCircular flg={true} />;
  }

  return (
    <ItemSelect
      labelId="tmId-label"
      id="tm_id"
      label="輸送方法"
      selectedValue={values.tm_id}
      items={options}
      setValues={setValues}
      size={size}
    />
  );
};

/**
 * ドライバー選択
 * ※実業務では車両を選択するが、本システムではドライバーを選択することで
 * その日ドライバーにも付いている車両を選択する
 * @param param0
 * @returns
 */
export const Driver = ({ values, setValues }: GridItemFieldProps) => {
  const { loading, users, drivers, vehicles } = useContext(MstDataContext);

  const selectedDriver = drivers?.find(
    (driver) => driver.id === values.user_id
  );
  const selectedVehicle = vehicles?.find(
    (vehicle) => vehicle.id === values.v_id
  );

  const [vehicleOptions, setVehicleOptions] = React.useState<List>([]);
  useEffect(() => {
    if (vehicles) {
      setVehicleOptions(
        vehicles.map((obj) => ({
          id: obj.id,
          label: obj.plate,
        }))
      );
    }
  }, [vehicles]);

  /**
   * ドライバーが変更された場合の処理
   */
  useEffect(() => {
    if (!users || values.user_id) {
      return;
    }

    const dv = users.find((user) => user.id === values.user_id);
    if (dv) {
      setValues({
        ...values,
        v_id: dv.v_id,
      });
    }
  }, [values.user_id, users]);

  //console.log("selectedDriver", selectedDriver);

  if (loading || !drivers) {
    return <LoadingCircular flg={true} />;
  }

  return (
    <>
      <ItemSelect
        labelId="user-label"
        id="user_id"
        label="ドライバー"
        selectedValue={selectedDriver ? Number(selectedDriver.id) : 0}
        items={drivers}
        setValues={setValues}
      />
      <ItemSelect
        labelId="vehicle-label"
        id="v_id"
        label="車番"
        selectedValue={selectedVehicle ? Number(selectedVehicle.id) : 0}
        items={vehicleOptions}
        setValues={setValues}
      />
    </>
  );
};

/**
 * シャーシ選択
 * @param param0
 * @returns
 */
export const StartTrailer = ({ values, setValues }: GridItemFieldProps) => {
  const { loading, trailer } = useContext(MstDataContext);

  const selected = trailer?.find((obj) => obj.id === values.start_trailer_v_id);

  const [options, setOptions] = React.useState<List>([]);

  useEffect(() => {
    if (trailer) {
      const trailers = trailer.map((obj) => ({
        id: obj.id,
        label: obj.plate,
      }));
      setOptions([{ id: 0, label: "(空)" }, ...trailers]);
    }
  }, [trailer]);

  //console.log("selectedDriver", selectedDriver);

  if (loading || !trailer) {
    return <LoadingCircular flg={true} />;
  }

  return (
    <ItemSelect
      labelId="trailer-label"
      id="start_trailer_v_id"
      label="シャーシ"
      selectedValue={selected ? Number(selected.id) : 0}
      items={options}
      setValues={setValues}
    />
  );
};

/**
 * 発日時
 * @param param0
 * @returns
 */
export const StartDatetime = ({ values, setValues }: GridItemFieldProps) => {
  const handleDateChange = (newDate: string) => {
    console.log("newDate", newDate);

    const strDatetime = `${newDate} ${strTimeOrigin(values.start_datetime)}`;

    // 入力日のに設定
    const inputDateTime = new Date(strDatetime);
    if (inputDateTime.toString() === "Invalid Date") {
      return;
    }
    setValues({
      ...values,
      start_datetime: strDatetime,
    });
  };
  return (
    <>
      <Typography variant="h6">発日</Typography>
      <DateInput
        type="date"
        value={strDateCustom(values.start_datetime)}
        name="start_datetime"
        onDateChange={handleDateChange}
        showSpinButtons={true} // スピンボタンのオンオフを制御
      />
    </>
  );
};

/**
 * 着日時
 * @param param0
 * @returns
 */
export const EndDatetime = ({ values, setValues }: GridItemFieldProps) => {
  const handleDateChange = (newDate: string) => {
    console.log("newDate", newDate);

    const strDatetime = `${newDate} ${strTimeOrigin(values.end_datetime)}`;
    // 入力日のに設定
    const inputDateTime = new Date(strDatetime);
    if (inputDateTime.toString() === "Invalid Date") {
      return;
    }
    setValues({
      ...values,
      end_datetime: strDatetime,
    });
  };

  useEffect(() => {
    console.log("values", values);
  }, [values.end_datetime]);
  return (
    <>
      <Typography variant="h6">着日</Typography>
      <DateInput
        type="date"
        value={strDateCustom(values.end_datetime)}
        name="end_datetime"
        onDateChange={handleDateChange}
        showSpinButtons={true} // スピンボタンのオンオフを制御
      />
    </>
  );
};

/**
 * 会社選択
 * @param param0
 * @returns
 */
export const ClientCompany = ({
  values,
  setValues,
  size = { xs: 12, lg: 3, xl: 3 },
}: GridItemFieldProps) => {
  const { loading, companies } = useContext(MstDataContext);
  if (loading) {
    return <div>Loading...</div>;
  }

  const options = companies?.map((company) => ({
    id: company.id,
    label: company.name,
  })) as List;

  const selectedOption = options?.find((option) => option.id === values.c_id);

  return (
    <ItemAutoComplete
      disableClearable={false}
      setValues={setValues}
      name="c_id"
      label="取引先"
      options={options}
      selectedOption={selectedOption}
      size={size}
    />
  );
};

/**
 * 傭車先車両情報
 * @param param0
 * @returns
 */
export const ClientVehicle = ({
  values,
  setValues,
  size = { xs: 12, lg: 3, xl: 3 },
}: GridItemFieldProps) => {
  return (
    <ItemTextFiled
      name="c_start_vehicle"
      label="車両情報"
      value={values.c_vehicle}
      setValues={setValues}
      size={size}
    />
  );
};
