import React, { useState, useEffect } from "react";
import { tSelfInformation } from "types/mst";
import { useNavigate, useParams } from "react-router-dom";
import { Grid, Box, useTheme } from "@mui/material";
import * as cSelfInfomation from "components/mst/SelfInfomation";
import { getSelfInfomation, upOrCrSelfInfomation } from "functions/api/mst";
import { PrimaryButton } from "atoms/Button";
import { tErrMsg, MsgBox } from "components/ErrorMessage";
import { FlexBox, FlexColumnBox } from "atoms/Box";
import { initSelfInformation } from "const/selfInfomation";

export default function Main() {
  const theme = useTheme();
  const [selfInfomation, setSelfInfomation] =
    useState<tSelfInformation>(initSelfInformation);

  // 警告
  const [warningMsg, setWarningMsg] = useState<tErrMsg>({});

  // 新規登録処理
  const handleClick = () => {
    upOrCrSelfInfomation(selfInfomation)
      .then((res) => {
        if (res.status !== 200) throw new Error("データ登録に失敗しました");
        alert("データを登録しました");
      })
      .catch((err) => {
        alert("データ登録に失敗しました");
      });
  };

  useEffect(() => {
    getSelfInfomation()
      .then((res) => {
        setSelfInfomation(res.data);
      })
      .catch((err) => {
        alert("自社情報の取得に失敗しました。");
      });
  }, []);

  return (
    <div>
      {Object.keys(warningMsg).length > 0 && (
        <Box sx={{ padding: 2 }}>
          <MsgBox errMsg={{}} warningMsg={warningMsg} />
        </Box>
      )}

      <FlexColumnBox gapSize={6}>
        <FlexBox>
          <>
            <PrimaryButton onClick={handleClick} label="登録" />
          </>
        </FlexBox>
        <Grid container spacing={2}>
          <cSelfInfomation.Name
            values={selfInfomation}
            setValues={setSelfInfomation}
          />
          <cSelfInfomation.CorporateNumber
            values={selfInfomation}
            setValues={setSelfInfomation}
          />
          <cSelfInfomation.Tel
            values={selfInfomation}
            setValues={setSelfInfomation}
          />
          <cSelfInfomation.Fax
            values={selfInfomation}
            setValues={setSelfInfomation}
          />
        </Grid>
        <Grid container spacing={2}>
          <cSelfInfomation.PostNumber
            values={selfInfomation}
            setValues={setSelfInfomation}
          />
          <cSelfInfomation.Address1
            values={selfInfomation}
            setValues={setSelfInfomation}
          />
          <cSelfInfomation.Address2
            values={selfInfomation}
            setValues={setSelfInfomation}
          />
        </Grid>
        <Grid container spacing={2}>
          <cSelfInfomation.BillingInfo
            values={selfInfomation}
            setValues={setSelfInfomation}
          />

          <cSelfInfomation.Notes
            values={selfInfomation}
            setValues={setSelfInfomation}
          />
        </Grid>
      </FlexColumnBox>
    </div>
  );
}
