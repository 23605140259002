import { create, API_URL, getCsrf } from "functions/axios";

/**
 * 差戻し登録
 * @param filter
 * @returns
 */
export function postSahimodoshi(filter: any = {}) {
  const ins = create();
  return ins.post(API_URL.base + "/project-remants/store", filter);
}

/**
 * 請求書登録
 * @param params
 * @returns
 */
export function storeInvoice(params: any) {
  const ins = create();
  return ins.post(API_URL.base + "/invoice/store", params);
}

/**
 * 請求書PDFダウンロード
 * @param i_id
 * @returns
 */
export function getInvocie(i_id: number) {
  const ins = create();
  return ins.get(`${API_URL.base}/invoice/show/${i_id}`);
}

/**
 * 請求書PDFダウンロード
 * @param i_id
 * @returns
 */
export function getInvocies(page: number, filter: any = {}, order: any = {}) {
  const ins = create();
  return ins.get(`${API_URL.base}/invoice/index`, {
    params: { page: page, filter: filter, order: order },
  });
}
