import MainFrame from "frames/MainFrame";

export default function App() {
  /*
  let token = (async () => {
    await axios.post(process.env['API_BASEURL_CENTRAL'] + "login", []);

  });
*/

  return <h2>MyPage</h2>;
}
