import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { tCompany } from "types/mst";
import { Grid, Box, Typography } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { PrimaryButton, SecondButton } from "atoms/Button";
import PageNation from "components/Pagenation";
import { useTheme } from "@mui/material/styles";
import { projectStatusChecked } from "const/project/index";
import { getProjectStatusCompanies } from "functions/api/mst";
import { closingDateTypeList } from "const/comapny/closingDateType";
import { FlexBox, FlexColumnBox } from "atoms/Box";

// tCompanyにproject_countとproject_sum_priceを追加
type tCompanyCustom = tCompany & {
  project_count: number;
  person_id: number | null;
  person_family_name: string | null;
  person_given_name: string | null;
};

export default function Main() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [companies, setCompanies] = useState<tCompanyCustom[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState({});
  const [sahimodoshi, setSashimodoshi] = useState<{ [key: number]: string }>(
    []
  );

  // データ取得処理
  const getSearch = async (page: number = 0) => {
    getProjectStatusCompanies(
      page,
      {
        status: [projectStatusChecked.id],
      },
      [
        {
          field: "name",
          direction: "asc",
        },
        {
          field: "created_at",
          direction: "asc",
        },
      ]
    ).then((res: any) => {
      console.log(res.data.data);
      setCompanies(res.data.data);
      setCurrentPage(res.data.current_page);
      setLastPage(res.data.last_page);
      setTotalPages(res.data.last_page);
    });
  };

  /**
   * 一覧から案件を削除
   * @param p_id
   */
  const removeCompanies = (id: number) => {
    const companyRow = document.querySelector(`.company-row-${id}`);
    if (companyRow) {
      companyRow.classList.add("removing");
    }

    setTimeout(() => {
      setCompanies((prev) => {
        return prev?.filter((company) => company.id !== id);
      });
    }, 500);
  };

  useEffect(() => {
    getSearch(currentPage);
  }, []);

  return (
    <FlexColumnBox gapSize={6}>
      <FlexBox>
        <TableContainer sx={{ maxWidth: theme.breakpoints.values.sm }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>取引先</TableCell>
                <TableCell>締め日</TableCell>
                <TableCell>件数</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies?.map((company) => {
                let personName = "";
                if (company.person_family_name) {
                  personName += company.person_family_name;
                }
                if (company.person_given_name) {
                  personName += company.person_given_name;
                }
                if (personName) {
                  personName = `(${personName})`;
                }

                return (
                  <TableRow
                    key={`company--${company.id}`}
                    className={`company-row-${company.id}`}
                    onDoubleClick={() =>
                      navigate(`/mst/company/edit/${company.id}`)
                    }
                  >
                    <TableCell>
                      <Typography>{company.id}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{`${company.abbreviation}${personName}`}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{`${
                        company.closing_date === 31
                          ? "末"
                          : company.closing_date
                      }日(${
                        closingDateTypeList[company.closing_date_type]
                      })`}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{`${company.project_count}`}</Typography>
                    </TableCell>
                    <TableCell>
                      <PrimaryButton
                        label={"明細"}
                        onClick={() => {
                          navigate(
                            `/paperwork/closing/company/${company.id}/person/${
                              company.person_id || 0
                            }/details`
                          );
                        }}
                      ></PrimaryButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <PageNation
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
              getSearch(value)
            }
          />
        </TableContainer>
      </FlexBox>
    </FlexColumnBox>
  );
}
