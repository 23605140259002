import { getAddAnyDay } from "functions/time";

const searchDefault = {
  pj_id: null,
  no: null,
  order: null,
  start_datetime_from: getAddAnyDay(-7, 0, 0),
  start_datetime_to: getAddAnyDay(7, 0, 0),
  end_datetime_from: "",
  end_datetime_to: "",
  tm_id: null,
  status: null,
  user_id: null,
  v_id: null,
  c_id: null,
};

export default searchDefault;
