import { styled } from "@mui/system";
import { Box } from "@mui/material";

export const FlexBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "gapSize", // gapSizeをDOMに渡さないようにする
})<{ gapSize?: number }>(({ theme, gapSize }) => ({
  display: "flex",
  flexFlow: "row",
  gap: gapSize ? theme.spacing(gapSize) : theme.spacing(2),
}));

export const FlexColumnBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "gapSize", // gapSizeをDOMに渡さないようにする
})<{ gapSize?: number }>(({ theme, gapSize }) => ({
  display: "flex",
  flexFlow: "column",
  gap: gapSize ? theme.spacing(gapSize) : theme.spacing(2),
}));
