export function API_URL_BASE(): string {
  return process.env.REACT_APP_API_BASEURL ?? "";
}

export function API_ROOT_URL(): string {
  return API_URL_BASE() + process.env.REACT_APP_API_ROOT ?? "";
}

export function API_LOGIN_USER(): string {
  return process.env.REACT_APP_API_USER ?? "";
}

export function API_LOGIN_PASS(): string {
  return process.env.REACT_APP_API_PASSWORD ?? "";
}

export function APP_ENV(): string {
  return process.env.REACT_APP_ENV ?? "production";
}
