import React, { useContext, useEffect, useState } from "react";
import { tProject, tWaypoint } from "types/project";
import { MstDataContext } from "contexts/Mst";
import { Typography, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { tAddress, tTransportMethod, tUser } from "types/mst";
import * as ComponentOperation from "components/instruction/Index";
import { tInstruction } from "types/instruction";
import { PrimaryButton, SecondButton } from "atoms/Button";
import { waypointKbnLoad, waypointKbnUnload } from "const/index";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { truncateString } from "functions/index";
import EastIcon from "@mui/icons-material/East";
import { getWaypointName } from "functions/project/index";
import { InputFormes } from "components/mst/Address";
import * as TmpInstCard from "components/instruction/Card";
import CardWithToolchip from "components/CardWithToolchip";
import {
  strDateOrigin,
  MathDateTime,
  changeDateFromTypeDate,
} from "functions/time";
import { getProject } from "functions/api/project";

interface MainProps {
  pjId: number;
  tranNo: number;
  driver?: tUser | undefined;
  values: tInstruction;
  setValues: React.Dispatch<React.SetStateAction<tInstruction>>;
  instructions: tInstruction[];
  setInstructions: React.Dispatch<React.SetStateAction<tInstruction[]>>;
  startAddress: tAddress;
  setStartAddress: React.Dispatch<React.SetStateAction<tAddress>>;
  endAddress: tAddress;
  setEndAddress: React.Dispatch<React.SetStateAction<tAddress>>;
  closeCallback: () => void;
}

/**
 * 運行指示入力モーダル
 * @param param0
 * @returns
 */
export default function Main({
  pjId,
  values,
  setValues,
  instructions,
  setInstructions,
  startAddress,
  setStartAddress,
  endAddress,
  setEndAddress,
  closeCallback,
}: MainProps) {
  const theme = useTheme();
  const { users, tranMethods, SYSTEM } = useContext(MstDataContext);

  // 案件情報の取得
  const [project, setProject] = useState<tProject>();
  useEffect(() => {
    if (!pjId) {
      setProject(undefined);
    } else {
      getProject(pjId)
        .then((res) => {
          if (res.status !== 200) {
            throw new Error("案件情報の取得に失敗しました");
          }
          setProject(res.data);
        })
        .catch((err) => {
          alert("案件情報の取得に失敗しました。");
        });
    }
  }, [pjId]);

  // 案件情報、運行指示情報を元に初期値をセット
  useEffect(() => {
    if (project === undefined) {
      return;
    }

    if (instructions.length === 0) {
      // 運行指示未作成の場合は案件情報の積卸情報をセット
      setValues((prev: tInstruction) => {
        return {
          ...prev,
          start_datetime: values.start_datetime || project.load_date || "",
          start_a_id: project.load_a_id || 0,
          start_name: project.load_name || "",
          start_prefectures: project.load_prefectures || "",
          start_city: project.load_city || "",
          start_street: project.load_street || "",

          end_datetime: project.unload_date || "",
          end_a_id: project.unload_a_id || 0,
          end_name: project.unload_name || "",
          end_prefectures: project.unload_prefectures || "",
          end_city: project.unload_city || "",
          end_street: project.unload_street || "",
        };
      });
    } else {
      // 運行指示がある場合は最後の運行指示の値をセット
      const lastInstruction = instructions[instructions.length - 1];
      setValues((prev: tInstruction) => {
        return {
          ...prev,
          start_datetime: lastInstruction.end_datetime,
          start_a_id: lastInstruction.end_a_id,
          start_name: lastInstruction.end_name,
          start_prefectures: lastInstruction.end_prefectures,
          start_city: lastInstruction.end_city,
          start_street: lastInstruction.end_street,
          start_building: lastInstruction.end_building,

          end_datetime:
            project.unload_date > lastInstruction.end_datetime
              ? project.unload_date
              : lastInstruction.end_datetime,
          end_a_id: project.unload_a_id,
          end_name: project.unload_name,
          end_prefectures: project.unload_prefectures,
          end_city: project.unload_city,
          end_street: project.unload_street,
          end_building: project.unload_building,
        };
      });
    }
  }, [project, instructions]);

  /**
   * 選択したドライバー情報を取得、セット
   */
  useEffect(() => {
    console.log("change useEffect values.user_id", values.user_id);
    // user_idのドライバーを取得
    const driver = users?.find((user) => user.id === values.user_id);
    setValues((prev) => {
      return { ...prev, v_id: driver?.v_id || null, driver: driver };
    });
  }, [values.user_id, users]);

  /**
   * 輸送方法選択時の処理
   */
  useEffect(() => {
    if (values.tm_id === SYSTEM?.tranMethod.own.id) {
      // 不要な項目のクリア
      setValues((prev) => {
        return {
          ...prev,
          c_id: null,
          c_vehicle: "",
        };
      });
      return;
    } else if (values.tm_id === SYSTEM?.tranMethod.charter.id) {
      // 不要な項目のクリア
      setValues((prev) => {
        return {
          ...prev,
          user_id: null,
        };
      });
      return;
    } else {
      const selected = tranMethods?.find(
        (tm: tTransportMethod) => tm.id === values.tm_id
      );

      if (!selected) {
        alert("選択した輸送手段が見つかりません。");
        return;
      }

      console.log("selected", selected);

      setStartAddress((prev) => {
        return {
          ...prev,
          id: selected?.start_a_id || 0,
          name: selected?.start_name || "",
        };
      });

      setEndAddress((prev) => {
        return {
          ...prev,
          id: selected?.end_a_id || 0,
          name: selected?.end_name || "",
        };
      });

      // 不要な項目のクリアと選択した情報から発着地を登録

      setValues((prev) => {
        let startDatetime = prev.start_datetime;
        let endDatetime = prev.end_datetime;

        if (selected.start_time) {
          startDatetime =
            strDateOrigin(prev.start_datetime) + " " + selected.start_time;
        }
        if (selected.end_time) {
          if (selected.start_time && selected.start_time > selected.end_time) {
            // 日を跨ぐ移動
            const a = MathDateTime(prev.start_datetime, [0, 0, 1, 0, 0, 0, 0]);
            const nextDay = changeDateFromTypeDate(a);
            endDatetime = nextDay + " " + selected.end_time;
          } else {
            endDatetime =
              strDateOrigin(prev.start_datetime) + " " + selected.end_time;
          }
        }

        return {
          ...prev,
          user_id: null,
          c_id: null,
          c_vehicle: "",
          start_datetime: startDatetime,
          end_datetime: endDatetime,
        };
      });
    }
  }, [values.tm_id, tranMethods]);

  const [selectedInstructionIndex, setSelectedInstructionIndex] = useState(
    instructions.length
  );

  /**
   * 積卸地点クリックで運行指示の値を変更
   * @param waypoint
   */
  const handleWaypointClick = (
    setState: React.Dispatch<React.SetStateAction<tAddress>>,
    waypoint: tWaypoint
  ) => {
    // 運行指示の値を変更
    setValues((prev: tInstruction) => {
      return {
        ...prev,
        datetime: waypoint.datetime,
      };
    });

    // 積卸地点の値を変更
    setState((prev: tAddress) => {
      return {
        ...prev,
        id: waypoint.a_id || 0,
        name: waypoint.a_name,
        post_number: waypoint.post_number,
        prefectures: waypoint.prefectures,
        city: waypoint.city,
        street: waypoint.street,
        building: waypoint.building,
      };
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/* 案件情報 */}
        <Box
          sx={{
            display: "flex",
            flexFlow: "rows",
            flexWrap: "no-wrap",
            overflowX: "auto",
            gap: theme.spacing(2),
          }}
        >
          <Waypoints
            waypoints={project?.waypoints || []}
            setLoadAddress={setStartAddress}
            setUnloadAddress={setEndAddress}
            handleWaypointClick={handleWaypointClick}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {/* 輸送情報 */}
        <Box
          key={`tmpInstructions`}
          sx={{
            display: "flex",
            flexFlow: "rows",
            flexWrap: "no-wrap",
            overflowX: "auto",
            justifyContent: "left",
            alignItems: "center",
            gap: theme.spacing(2),
          }}
        >
          <Instructioned
            instructions={instructions}
            setInstructions={setInstructions}
            selectedInstructionIndex={selectedInstructionIndex}
          />
        </Box>
      </Grid>
      <Grid item xs={4}>
        <ComponentOperation.StartDatetime
          values={values}
          setValues={setValues}
        />
      </Grid>
      <Grid item xs={4}>
        <ComponentOperation.EndDatetime values={values} setValues={setValues} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">発地</Typography>
        <Box sx={{ display: "flex", flexFlow: "rows", gap: theme.spacing(1) }}>
          <InputFormes values={startAddress} setValues={setStartAddress} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">着地</Typography>
        <Box sx={{ display: "flex", flexFlow: "rows", gap: theme.spacing(1) }}>
          <InputFormes values={endAddress} setValues={setEndAddress} />
        </Box>
      </Grid>
      <ComponentOperation.SelectMethods
        values={values}
        setValues={setValues}
        size={{ xs: 12, sm: 6, md: 4, lg: 3 }}
      />
      {values?.tm_id === SYSTEM?.tranMethod.own.id && (
        <ComponentOperation.Driver values={values} setValues={setValues} />
      )}
      {values?.tm_id === SYSTEM?.tranMethod.charter.id && (
        <>
          <ComponentOperation.ClientCompany
            values={values}
            setValues={setValues}
          />
          <ComponentOperation.ClientVehicle
            values={values}
            setValues={setValues}
          />
        </>
      )}
      <ComponentOperation.StartTrailer values={values} setValues={setValues} />
    </Grid>
  );
}

interface WaypointsProps {
  waypoints: tWaypoint[];
  setLoadAddress: React.Dispatch<React.SetStateAction<tAddress>>;
  setUnloadAddress: React.Dispatch<React.SetStateAction<tAddress>>;
  handleWaypointClick: (
    setState: React.Dispatch<React.SetStateAction<tAddress>>,
    waypoint: tWaypoint
  ) => void;
}

/**
 * 経由地点表示コンポーネント
 * @param param0
 * @returns
 */
const Waypoints = ({
  waypoints,
  setLoadAddress,
  setUnloadAddress,
  handleWaypointClick,
}: WaypointsProps) => {
  const theme = useTheme();
  return (
    <React.Fragment key={`waypoints-component`}>
      {waypoints.map((waypoint: tWaypoint, index) => {
        return (
          <React.Fragment key={`waypoint-${index}`}>
            <CardWithToolchip
              toolchipChildren={
                <Box
                  sx={{
                    display: "flex",
                    flexFlow: "rows",
                    gap: theme.spacing(1),
                  }}
                >
                  <SecondButton
                    label="発"
                    onClick={() =>
                      handleWaypointClick(setLoadAddress, waypoint)
                    }
                  />
                  <PrimaryButton
                    label="着"
                    onClick={() =>
                      handleWaypointClick(setUnloadAddress, waypoint)
                    }
                  />
                </Box>
              }
            >
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "column",
                  justifyItems: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  //handleWaypointClick(waypoint);
                }}
              >
                {waypoint.kbn === waypointKbnLoad.id && <ArrowCircleUpIcon />}
                {waypoint.kbn === waypointKbnUnload.id && (
                  <ArrowCircleDownIcon />
                )}
                <Typography>
                  {truncateString(getWaypointName(waypoint), 7)}
                </Typography>
              </Box>
            </CardWithToolchip>
            {waypoints.length - 1 !== index && (
              <Box
                sx={{
                  display: "flex",
                  flexFlow: "column",
                  justifyItems: "center",
                  alignItems: "center",
                }}
              >
                <EastIcon />
              </Box>
            )}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

interface InstructionedProps {
  instructions: tInstruction[];
  setInstructions: React.Dispatch<React.SetStateAction<tInstruction[]>>;
  selectedInstructionIndex: number;
}

/**
 * 作成済み運行指示表示コンポーネント
 * @param param0
 * @returns
 */
const Instructioned = ({
  instructions,
  setInstructions,
  selectedInstructionIndex,
}: InstructionedProps) => {
  const theme = useTheme();
  return (
    <React.Fragment key={`tmpInstructions-component`}>
      {instructions.map((instruction: tInstruction, index) => {
        return (
          <React.Fragment
            key={`tmpInstruction-${instruction.pj_id}-${instruction.no}-${instruction.order}`}
          >
            <TmpInstCard.WithToolchip
              instruction={instruction}
              flgOmit={false}
              selected={selectedInstructionIndex === index}
              cardChildren={<></>}
              toolchipChildren={
                <Box
                  sx={{
                    display: "flex",
                    flexFlow: "row",
                    flexWrap: "no-wrap",
                    gap: theme.spacing(2),
                  }}
                >
                  {index !== 0 && (
                    <PrimaryButton
                      label="一つ前へ"
                      onClick={() => {
                        setInstructions((prev) => {
                          const newInstructions = [...prev];
                          const tmp = newInstructions[index];
                          newInstructions[index] = newInstructions[index - 1];
                          newInstructions[index - 1] = tmp;
                          return newInstructions;
                        });
                      }}
                    />
                  )}
                  <SecondButton
                    label="削除"
                    onClick={() => {
                      // instructionsから削除
                      setInstructions((prev) => {
                        return prev.filter((_, i) => i !== index);
                      });
                    }}
                  />
                  {index !== instructions.length - 1 && (
                    <PrimaryButton
                      label="一つ後へ"
                      onClick={() => {
                        // instructionsの順番を入れ替え
                        setInstructions((prev) => {
                          const newInstructions = [...prev];
                          const tmp = newInstructions[index];
                          newInstructions[index] = newInstructions[index + 1];
                          newInstructions[index + 1] = tmp;
                          return newInstructions;
                        });
                      }}
                    />
                  )}
                </Box>
              }
            />
            {instructions.length - 1 !== index && <EastIcon />}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
