import { Box } from "@mui/system";
import Modal from "atoms/Modal";
import { tErrMsg, ErrMsgBox } from "components/ErrorMessage";
import { SecondButton } from "atoms/Button";

interface Props {
  errMsg: tErrMsg;
  setErrMsg: React.Dispatch<React.SetStateAction<tErrMsg>>;
}

export default function ErrModal({ errMsg, setErrMsg }: Props) {
  return (
    <Modal
      open={Object.keys(errMsg).length > 0}
      onClose={() => {
        setErrMsg({});
      }}
      title="エラー"
      content={
        <Box sx={{ padding: 2 }}>
          <ErrMsgBox errMsg={errMsg} />
        </Box>
      }
      actions={
        <SecondButton
          label="閉じる"
          onClick={() => {
            setErrMsg({});
          }}
        />
      }
    />
  );
}
