import React, { useState, useEffect, useContext } from "react";
import {
  tCompany,
  initialCompany,
  tPerson,
  initialPerson,
  tAddress,
  initialAddress,
} from "types/mst";
import Modal from "atoms/Modal";
import { useNavigate, useParams } from "react-router-dom";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
  Button,
  Box,
  useTheme,
} from "@mui/material";
import * as cCompany from "components/mst/Company";
import * as cAddress from "components/mst/Address";
import {
  getCompany,
  storeCompany,
  updateCompany,
  deleteCompany,
  getCompanyPersons,
  storeCompanyPerson,
  updateCompanyPerson,
  deletePerson,
  storeAddress,
} from "functions/api/mst";
import { Console_log, sleep } from "functions";
import { GridItemTextField } from "components/GridItem";
import { PrimaryButton, SecondButton } from "atoms/Button";
import { MstDataContext } from "contexts/Mst";
import { tErrMsg, MsgBox } from "components/ErrorMessage";
import { FlexBox, FlexColumnBox } from "atoms/Box";

const initialCustomAddress = {
  ...initialAddress,
  flg_office: true,
};

export default function Main() {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [company, setCompany] = useState<tCompany>(initialCompany);
  const [persons, setPersons] = useState<tPerson[]>([]);
  const [address, setAddress] = useState<tAddress>(initialCustomAddress);
  const [editPerson, setEditPerson] = useState<tPerson>(initialPerson);
  const [openPerson, setOpenPerson] = useState(false);
  const [openAddress, setOpenAddress] = useState(false);
  const { companies, addresses, fetchData, loading } =
    useContext(MstDataContext);

  // 警告
  const [warningMsg, setWarningMsg] = useState<tErrMsg>({});

  // 会社マスタ登録
  const setPostRequestStoreCompany = () => {
    if (company === undefined) return;
    storeCompany(company)
      .then((res) => {
        if (res.status !== 200) throw new Error("データ登録に失敗しました");
        alert("データを登録しました");
        window.location.href = "/mst/company/edit/" + res.data.id;
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // 会社マスタ更新
  const setPostRequestUpdateCompany = () => {
    if (company === undefined) return;
    updateCompany(company)
      .then((res) => {
        if (res.status !== 200) throw new Error("データ更新に失敗しました");
        alert("データを更新しました");
        window.location.href = "/mst/company";
      })
      .catch((err) => {
        alert("データを更新に失敗しました。");
        console.error(err);
      });
  };

  // 会社マスタ削除
  const setPostRequestDelete = () => {
    if (!window.confirm(`${company.name}を削除します。よろしいでしょうか？`))
      return;
    deleteCompany(company.id)
      .then((res) => {
        if (res.status !== 200) throw new Error();
        alert("データを削除しました");
        window.location.href = "/mst/company";
      })
      .catch((err) => {
        alert(`${company.name}のデータ削除に失敗しました`);
        console.error(err);
      });
  };

  // 人マスタ更新
  const setPostRequestUpdatePerson = () => {
    editPerson.c_id = company.id;
    updateCompanyPerson(editPerson)
      .then((res) => {
        if (res.status !== 200) throw new Error("データ更新に失敗しました");
        alert("データを更新しました");
        window.location.href = "/mst/company/edit/" + company.id;
      })
      .catch((err) => {
        alert(`データ更新に失敗しました`);
        console.error(err);
      });
  };

  // 人マスタ新規登録
  const setPostRequestStorePerson = () => {
    editPerson.c_id = company.id;
    storeCompanyPerson(editPerson)
      .then((res) => {
        if (res.status !== 200) throw new Error();
        alert("データを登録しました");
        window.location.href = "/mst/company/edit/" + company.id;
      })
      .catch((err) => {
        alert(`データ登録に失敗しました`);
        console.error(err);
      });
  };

  // 人マスタ削除
  const setPostRequestDeletePerson = () => {
    if (!window.confirm("削除しますか？")) return;
    editPerson.c_id = company.id;
    deletePerson(editPerson.id)
      .then((res) => {
        if (res.status !== 200) throw new Error(res.statusText);
        alert("データを削除しました");
        window.location.href = "/mst/company/edit/" + company.id;
      })
      .catch((err) => {
        alert("データ削除に失敗しました");
        console.error(err);
      });
  };

  // 住所マスタ登録
  const setPostRequestStoreAddress = () => {
    storeAddress(address)
      .then(async (res) => {
        if (res.status !== 200) throw new Error("データ登録に失敗しました");
        alert("データを登録しました");

        console.log("res.data", res.data);

        await fetchData();

        // 会社に住所IDをセット
        setCompany({ ...company, office_a_id: res.data.id });
        setAddress(res.data as tAddress);

        setOpenAddress(false);
      })
      .catch((err) => {
        alert(`データ登録に失敗しました`);
        console.error(err);
      });
  };

  useEffect(() => {
    const fetchCompany = async (id: number) => {
      try {
        getCompany(id)
          .then((resCompany) => {
            if (resCompany.status !== 200)
              throw new Error("データ取得に失敗しました");
            //
            Console_log("resCompany.data", resCompany);
            setCompany(resCompany.data);

            // 人データ取得
            getCompanyPersons(1, 0, resCompany.data.id).then((res) => {
              if (res.status !== 200)
                throw new Error("人データ取得に失敗しました");
              //Console_log("persons.data", res.data);
              setPersons(res.data);
            });
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (error) {
        console.error(error);
      }
    };

    if (id) {
      fetchCompany(Number(id));
    }
  }, [id]);

  useEffect(() => {
    // 住所情報をセット
    setAddress(
      addresses?.find((a) => a.id === company.office_a_id) ??
        initialCustomAddress
    );
  }, [company.office_a_id, addresses]);

  useEffect(() => {
    if (companies === null || company.name === "") {
      // ワーニングの会社名を初期化
      setWarningMsg((prev) => {
        delete prev.会社名;
        return { ...prev };
      });
      return;
    }
    if (companies === null) return;
    // 会社名が変更されたら
    const likeCompanyName = companies
      .filter((c) => c.name.includes(company.name) && c.id !== company.id) // 条件に一致し、IDが異なる会社をフィルタリング
      .map((c) => c.name); // 一致する会社名を抽出

    if (likeCompanyName === undefined) {
      setWarningMsg((prev) => {
        delete prev.会社名;
        return { ...prev };
      });
      return;
    }

    // 類似している会社名があれば警告をセット
    if (likeCompanyName.length > 0) {
      setWarningMsg({
        会社名: [`類似している会社があります。[${likeCompanyName.join(", ")}]`],
      });
    } else {
      setWarningMsg((prev) => {
        delete prev.会社名;
        return { ...prev };
      });
    }

    //setWarningMsg({
  }, [company.id, company.name, companies]);

  useEffect(() => {
    setCompany({ ...company, office_a_id: address.id });
  }, [address.id]);

  return (
    <div>
      {Object.keys(warningMsg).length > 0 && (
        <Box sx={{ padding: 2 }}>
          <MsgBox errMsg={{}} warningMsg={warningMsg} />
        </Box>
      )}

      <FlexColumnBox gapSize={6}>
        <FlexBox>
          {id ? (
            <>
              <PrimaryButton
                onClick={setPostRequestUpdateCompany}
                label="更新"
              />
              <PrimaryButton onClick={setPostRequestDelete} label="削除" />
            </>
          ) : (
            <>
              <PrimaryButton
                onClick={setPostRequestStoreCompany}
                label="登録"
              />
            </>
          )}
        </FlexBox>
        <Grid container spacing={2}>
          <cCompany.Name values={company} setValues={setCompany} />
          <cCompany.Abbreviation values={company} setValues={setCompany} />
          <cCompany.Tell values={company} setValues={setCompany} />
          <cCompany.InvoiceNo values={company} setValues={setCompany} />
        </Grid>

        <Grid container spacing={2}>
          <cCompany.ClosingDate values={company} setValues={setCompany} />
          <cCompany.ClosingDateType values={company} setValues={setCompany} />
          <cCompany.FlgBillingPerson values={company} setValues={setCompany} />
        </Grid>

        <Grid container spacing={2}>
          <cCompany.PostNumber values={company} setValues={setCompany} />
          <cCompany.Address1 values={company} setValues={setCompany} />
          <cCompany.Address2 values={company} setValues={setCompany} />
        </Grid>

        {id && (
          <Grid container spacing={4}>
            <Modal
              title="人物"
              content={ModalPerson(editPerson, setEditPerson)}
              actions={
                <>
                  {editPerson.id === 0 ? (
                    <Button onClick={setPostRequestStorePerson}>登録</Button>
                  ) : (
                    <Button onClick={setPostRequestUpdatePerson}>更新</Button>
                  )}
                  <Button onClick={() => setOpenPerson(false)}>閉じる</Button>
                  <Button onClick={setPostRequestDeletePerson}>削除</Button>
                </>
              }
              open={openPerson}
              onClose={() => setOpenPerson(false)}
            />
            <Grid item xs={12}>
              <Box
                sx={{ display: "flex", flexFlow: "row", gap: theme.spacing(2) }}
              >
                <Typography variant="h5">人リスト</Typography>
                <SecondButton
                  onClick={() => {
                    setEditPerson(initialPerson);
                    setOpenPerson(true);
                  }}
                  label="新規"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Paper} sx={{ maxWidth: "680px" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>名称</TableCell>
                      <TableCell>電話番号</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {persons.map((person: any) => {
                      return (
                        <TableRow
                          key={`companyEdit-persons-${person.id}`}
                          onDoubleClick={() => {
                            setEditPerson(person);
                            setOpenPerson(true);
                          }}
                        >
                          <TableCell>{`${person.family_name} ${person.given_name}`}</TableCell>
                          <TableCell>{`${person.tell}`}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </FlexColumnBox>
    </div>
  );
}

function ModalAddress(
  data: tAddress,
  setData: React.Dispatch<React.SetStateAction<tAddress>>
) {
  //console.log("data", data);
  return (
    <Grid container spacing={4}>
      <cAddress.Name values={data} setValues={setData} />
      <cAddress.Prefectures values={data} setValues={setData} />
      <cAddress.City values={data} setValues={setData} />
      <cAddress.Street values={data} setValues={setData} />
      <cAddress.Building values={data} setValues={setData} />
    </Grid>
  );
}

function ModalPerson(
  data: tPerson,
  setData: React.Dispatch<React.SetStateAction<tPerson>>
) {
  return (
    <Grid container spacing={4}>
      <GridItemTextField
        name={"family_name"}
        label="姓"
        value={data.family_name}
        handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
          setData({ ...data, family_name: e.target.value })
        }
      />

      <GridItemTextField
        name={"given_name"}
        label="名"
        value={data.given_name}
        handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
          setData({ ...data, given_name: e.target.value })
        }
      />

      <Grid item xs={12}></Grid>

      <GridItemTextField
        name={"family_name_kana"}
        label="姓（カナ）"
        value={data.family_name_kana}
        handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
          setData({ ...data, family_name_kana: e.target.value })
        }
      />

      <GridItemTextField
        name={"given_name_kana"}
        label="名（カナ）"
        value={data.given_name_kana}
        handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
          setData({ ...data, given_name_kana: e.target.value })
        }
      />

      <Grid item xs={12}></Grid>

      <GridItemTextField
        name={"tell"}
        label="電話番号"
        value={data.tell}
        handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
          setData({ ...data, tell: e.target.value })
        }
        size={{ xs: 12, lg: 4, xl: 4 }}
      />
      <GridItemTextField
        name={"email"}
        label="メールアドレス"
        value={data.email}
        handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
          setData({ ...data, email: e.target.value })
        }
        size={{ xs: 12, lg: 6, xl: 6 }}
      />
    </Grid>
  );
}
