import { tTransportMethod } from "types/mst";

export const initTransportMethod: tTransportMethod = {
  id: 0,
  domain: "",
  name: "",
  abbreviation: "",
  name_chip: "",
  start_time: "00:00:00",
  start_a_id: 0,
  start_name: "",
  start_prefectures: "",
  start_city: "",
  start_street: "",
  start_building: "",
  end_time: "00:00:00",
  end_a_id: 0,
  end_name: "",
  end_prefectures: "",
  end_city: "",
  end_street: "",
  end_building: "",
};
