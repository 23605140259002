import React from "react";
import {
  TextField,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

interface DateInputProps {
  type: "date" | "datetime-local";
  label?: string;
  value: string;
  name: string;
  onDateChange?: (newDate: string) => void;
  showSpinButtons?: boolean; // スピンボタンの表示を制御
}

export const DateInput: React.FC<DateInputProps> = ({
  type,
  label = "",
  value,
  name,
  onDateChange,
  showSpinButtons = true, // デフォルトでスピンボタンをオン
}) => {
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    //setDateValue(newValue);
    if (onDateChange) {
      onDateChange(newValue);
    }
  };

  const incrementDate = () => {
    if (value === "") {
      value = new Date().toISOString().slice(0, type === "date" ? 10 : 16);
    }
    const date = new Date(value);
    date.setDate(date.getDate() + 1);
    const newValue = date.toISOString().slice(0, type === "date" ? 10 : 16);
    //setDateValue(newValue);
    if (onDateChange) {
      onDateChange(newValue);
    }
  };

  const decrementDate = () => {
    if (value === "") {
      value = new Date().toISOString().slice(0, type === "date" ? 10 : 16);
    }
    const date = new Date(value);
    date.setDate(date.getDate() - 1);
    const newValue = date.toISOString().slice(0, type === "date" ? 10 : 16);
    //setDateValue(newValue);
    if (onDateChange) {
      onDateChange(newValue);
    }
  };

  return (
    <>
      {label && <Typography variant="h6">{label}</Typography>}
      <TextField
        type={type}
        value={value}
        name={name}
        size="small"
        hiddenLabel
        onChange={handleDateChange}
        InputProps={{
          endAdornment: showSpinButtons ? (
            <InputAdornment position="end">
              <IconButton onClick={decrementDate} edge="end">
                <Remove />
              </IconButton>
              <IconButton onClick={incrementDate} edge="end">
                <Add />
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
    </>
  );
};
