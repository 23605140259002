// リダイレクト
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Main() {
  const navigate = useNavigate();

  useEffect(() => {
    // ここに条件を設定して遷移させます
    navigate(`/project/list`);
  }, [navigate]);

  return <h2>Redirecting...</h2>;
}
