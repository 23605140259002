import React from "react";
import themeBase from "themes/Base";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import deepmerge from "deepmerge";
import zIndex from "@mui/material/styles/zIndex";

let themeSchedules = createTheme(
  deepmerge(themeBase, {
    ...themeBase,
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          main: {
            height: "100vh",
          },
        },
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: "xl",
        },
        styleOverrides: {
          root: {
            "&.SlideArea": {
              position: "fixed",
              zIndex: 1000,
              backgroundColor: themeBase.palette.common.white,

              "&.SlideArea-bottom": {
                bottom: 0,
                left: 0,
                width: "100%",
                minHeight: "150px", // 自動で変えられるように
              },
            },
          },
        },
      },
    },
  })
);

interface ThemeProps {
  children: React.ReactNode;
}

export const SchedulesThemeProvider: React.FC<ThemeProps> = ({ children }) => {
  return (
    <ThemeProvider theme={themeSchedules}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
