import { List } from "types";

export const closingDateTypeLoad = { id: 1, label: "積日" };
export const closingDateTypeUnload = { id: 2, label: "卸日" };

export const closingDateType: List = [
  closingDateTypeLoad,
  closingDateTypeUnload,
];

export const closingDateTypeList = {
  [closingDateTypeLoad.id]: closingDateTypeLoad.label,
  [closingDateTypeUnload.id]: closingDateTypeUnload.label,
};
