import { tProject, tWaypoint } from "types/project";

/**
 * 取引先名取得処理
 * @param project
 * @returns
 */
export function getTorisakiName(project: tProject) {
  if (!project.company) {
    return `新規`;
  } else if (project.company.abbreviation) {
    return `${project.company.abbreviation}`;
  } else if (project.company.name) {
    return project.company.name;
  } else {
    return `新規`;
  }
}

/**
 * 積地名称
 * @param project
 * @returns
 */
export function getLoadName(project: tProject) {
  if (project.load_address?.abbreviation) {
    return `${project.load_address?.abbreviation}`;
  } else if (project.load_address?.name) {
    return project.load_address.name;
  } else {
    return `${project.load_prefectures}${project.load_city}`;
  }
}

/**
 * 卸地点名称
 * @param project
 * @returns
 */
export function getUnloadName(project: tProject) {
  if (project.unload_address?.abbreviation) {
    return `${project.unload_address?.abbreviation}`;
  } else if (project.unload_address?.name) {
    return project.unload_address.name;
  } else {
    return `${project.unload_prefectures}${project.unload_city}`;
  }
}

/**
 * 地点名
 * @param waypoint
 * @returns
 */
export function getWaypointName(waypoint: tWaypoint) {
  if (waypoint.a_name) {
    return waypoint.a_name;
  } else {
    return `${waypoint.prefectures}${waypoint.city}`;
  }
}
