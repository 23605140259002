import { create, API_URL, getCsrf } from "functions/axios";
import {
  tRole,
  tAddress,
  tCompany,
  tLuggage,
  tPerson,
  tProjectKind,
  tUser,
  tVehicle,
  tVehicleType,
} from "types/mst";
import { useNavigate } from "react-router-dom";

interface Props {
  callbackSuccess?: () => void;
  callbackError?: () => void;
}

// 認証チェック
export const isAuthenticated = ({
  callbackSuccess = undefined,
  callbackError = undefined,
}: Props) => {
  const ins = create();
  ins
    .get(API_URL.base + "/token-check", {})
    .then((res: any) => {
      if (res.status !== 200) {
        if (callbackSuccess) {
          callbackSuccess();
        } else {
          // エラーがある場合は、ログアウトしてログインページにリダイレクト
          window.location.href = "/logout";
        }
      }
    })
    .catch((error: any) => {
      if (callbackError) {
        callbackError();
      } else {
        // エラーがある場合は、ログアウトしてログインページにリダイレクト
        window.location.href = "/logout";
      }
    });
};

export default isAuthenticated;
