import React, { useState, useRef, useEffect, forwardRef } from "react";
import BaseCard from "atoms/Card";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  Popover,
  Typography,
  Button,
  Grid,
  Container,
} from "@mui/material";
import { useSelection } from "contexts/Schedules";
import { truncateString } from "functions/index";
import { isSameDate } from "functions/time";
import { tTransportCard } from "types/transport";
import { MailOutlineSharp } from "@mui/icons-material";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { tProjectCard } from "types/project";

/**
 * 運行指示作成用輸送情報カード
 * @param param0
 * @returns
 */
export const ProjectCardNomal = forwardRef<
  HTMLDivElement,
  {
    projectCard: tProjectCard;
    callbackClick: (event: React.MouseEvent<HTMLElement>) => void;
    flgOmit?: any;
    ref?: any;
    children?: React.ReactNode;
  }
>((props, ref) => {
  const { projectCard, callbackClick, flgOmit = true, children } = props;
  const navigate = useNavigate();

  const handleCardDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // 親への伝播を止める
    if (flgOmit) {
      return;
    }
    navigate(`/project/edit/${projectCard.pj_id}`);
  };

  const sameDate = isSameDate(projectCard.load_date, projectCard.unload_date);
  return (
    <>
      <BaseCard
        ref={ref}
        flgOmit={flgOmit}
        callbackDoubleClick={handleCardDoubleClick}
        callbackClick={callbackClick}
      >
        {children}
        {!flgOmit ? (
          <>
            <Typography>
              {`${truncateString(projectCard.company_name, 5)}`}
            </Typography>
            <Typography>
              <li>{truncateString(projectCard.load_name, 6)}</li>
              <li>{truncateString(projectCard.unload_name, 6)}</li>
            </Typography>
          </>
        ) : (
          ""
        )}
        <Box className="attribute-chip">
          <Typography className="chip waypoints">{`+${
            projectCard.waypoint_count - 2
          }`}</Typography>
        </Box>
      </BaseCard>
    </>
  );
});

export default ProjectCardNomal;
