import { tDailyMemo } from "types/dailymemo";

export const initDailyMemo: tDailyMemo = {
  id: 0,
  domain: "",
  insert_user_id: 0,
  update_user_id: 0,
  datetime: "",
  memo: "",
  to_user_id: 0,
  toUser: undefined,
  insertUser: undefined,
  updateUser: undefined,
};
