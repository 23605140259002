import React from "react";
import { Console_log } from "functions/index";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeLayout from "frames/HomeFrame";
import MainLayout from "frames/MainFrame";
import FullScreenFrame from "frames/FullScreenFrame";
import LogginLayout from "frames/LoginFrame";
import Top from "pages/Index";
import Login from "pages/Login";
import Logout from "pages/Logout";
import { menus } from "const/menu";
import { MstDataProvider } from "contexts/Mst";
import ProtectedRoute from "components/ProtectedRoute";
import FSSchedules from "pages/full-screen/Schedules";
import { ThemeProviderCustom } from "themes/Base";
import { SelectionProvider } from "contexts/Schedules";

////////////////

// トップDomを取得
const rootDom = document.getElementById("root");

if (rootDom) {
  const root = ReactDOM.createRoot(rootDom);

  root.render(
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
        <MstDataProvider>
          <SelectionProvider>
            <ThemeProviderCustom>
              <Routes>
                <Route element={<ProtectedRoute />}>
                  {menus.map((menu) => {
                    return (
                      <React.Fragment key={menu.path}>
                        <Route path="/" element={<HomeLayout />}>
                          <Route path="/" element={<Top />} />
                        </Route>
                        <Route
                          key={`${menu.path}-frame`}
                          element={<FullScreenFrame key={menu.path} />}
                        >
                          <Route
                            key={menu.path}
                            path="/full-screen/schedules"
                            element={<FSSchedules />}
                          />
                        </Route>
                        <Route
                          key={`${menu.path}-frame`}
                          element={<MainLayout key={menu.path} menu={menu} />}
                        >
                          <Route
                            key={`${menu.path}`}
                            path={`${menu.path}`}
                            element={menu.element}
                          />
                          {menu.subMenus.map((subMenu) => {
                            return (
                              <Route
                                key={`${menu.path}-${subMenu.path}`}
                                path={`${subMenu.path}`}
                                element={subMenu.element}
                              />
                            );
                          })}
                        </Route>
                      </React.Fragment>
                    );
                  })}
                  ;
                </Route>
              </Routes>
            </ThemeProviderCustom>
          </SelectionProvider>
        </MstDataProvider>
      </Router>
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
