import React from "react";
import { Typography } from "@mui/material";
import { typeHashValue } from "types/index";
import {
  GridItemTextField,
  GridItemDateField,
  GridItemTextFieldCustom,
  GridItemRowTextField,
  GridItemCheckbox,
} from "components/GridItem";
import { handleChangeValues } from "functions/handles";
import { SmallTextField } from "atoms/TextField";
import { tVehicleType } from "types/mst";

interface GridItemFieldProps {
  values: tVehicleType;
  setValues: React.Dispatch<React.SetStateAction<tVehicleType>>;
}
export const Name = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemTextField
    label="名称"
    name="name"
    value={values.name}
    handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
      handleChangeValues(e.target.value, e.target.name, setValues)
    }
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const FlgTrailer = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemCheckbox
    id={"vehicleType-FlgTrailer"}
    label="非牽引車"
    name="flg_trailer"
    value={values.flg_trailer}
    handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) => {
      console.log(e.target.checked);
      console.log(e.target.name);
      handleChangeValues(e.target.checked, e.target.name, setValues);
    }}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Description = ({ values, setValues }: GridItemFieldProps) => (
  <GridItemRowTextField
    label="説明文"
    name="description"
    value={values.description}
    handleChangeValues={(e: React.ChangeEvent<HTMLInputElement>) =>
      handleChangeValues(e.target.value, e.target.name, setValues)
    }
    size={{ xs: 12, lg: 6, xl: 5 }}
  />
);
/*
export const CmId = ({ values, setValues }: GridItemFieldProps) => {
  const { loading, carModels } = useContext(MstDataContext);
  if (loading || !carModels) return <Typography>loading...</Typography>;

  const options = carModels.map((cm) => ({
    id: cm.id,
    label: cm.name,
  }));
  const selectedOption = options.find(
    (option: any) => option.id === values.cm_id
  );

  return (
    <GridItemTextFieldCustom size={{ xs: 12, lg: 3, xl: 2 }}>
      <AutocompleteSelect
        name="cm_id"
        label={"車種"}
        options={options}
        value={selectedOption || null}
        onChange={(e: React.ChangeEvent<{}>, newValue: ListItem) =>
          handleChangeValues(newValue.id, "cm_id", setValues)
        }
      />
    </GridItemTextFieldCustom>
  );
};
*/
