import { useEffect, useState, useContext } from "react";
import { MstDataContext } from "contexts/Mst";
import { getInvocie, getInvocies } from "functions/api/paperwork";
import { Document, Page } from "@react-pdf/renderer";
import PageNation from "components/Pagenation";
import { FlexBox, FlexColumnBox } from "atoms/Box";
import { Typography, useTheme, Paper } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { tInvoice, tInvoiceDetail } from "types/invoice";
import Modal from "atoms/Modal";
import SheetInvoice, { PDFDownload, PDFPreview } from "sheets/invoice/type01";
import { PrimaryButton, SecondButton } from "atoms/Button";
import { CircularProgress, Box } from "@mui/material";
import LoadingCircular from "atoms/Loading";

export default function Main({ invoice_id }: any) {
  const theme = useTheme();
  const { loading, selfInfomation } = useContext(MstDataContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState({});
  const [invoices, setInvoices] = useState<tInvoice[]>([]);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<number | null>(
    null
  );
  const [invoice, setInvoice] = useState<tInvoice>();
  const [details, setDetails] = useState<tInvoiceDetail[]>([]);

  const getSearch = async (page: number = 0) => {
    getInvocies(page, [], [])
      .then((res: any) => {
        if (res.status !== 200) throw new Error("error");
        setInvoices(res.data.data);
        setCurrentPage(res.data.current_page);
        setLastPage(res.data.last_page);
        setTotalPages(res.data.last_page);
      })
      .catch((error: any) => {});
  };

  const getInvocieDetails = async (invoice_id: number) => {
    getInvocie(invoice_id)
      .then((res: any) => {
        if (res.status !== 200) throw new Error("error");
        setInvoice(res.data.invoice);
        setDetails(res.data.details);
      })
      .catch((error: any) => {
        alert(error);
        setInvoice(undefined);
        setDetails([]);
      });
  };

  const handleInvoiceDoubleClick = (invoice_id: number) => {
    setSelectedInvoiceId(invoice_id);
  };

  useEffect(() => {
    getSearch(currentPage);
  }, []);

  useEffect(() => {
    if (selectedInvoiceId) {
      getInvocieDetails(selectedInvoiceId);
    }
  }, [selectedInvoiceId]);

  let sheetName = "";
  if (invoice) {
    sheetName = `請求書_${invoice.invoice_number}.pdf`;
  }

  if (loading || !selfInfomation) {
    return <LoadingCircular flg={true} />;
  }

  return (
    <FlexColumnBox gapSize={6}>
      <Modal
        open={selectedInvoiceId !== null}
        onClose={() => setSelectedInvoiceId(null)}
        title={sheetName}
        content={
          invoice &&
          details && (
            <PDFPreview
              invoice={invoice}
              details={details}
              mycompany={selfInfomation}
            />
          )
        }
        actions={
          <>
            <SecondButton
              onClick={() => {
                setSelectedInvoiceId(null);
              }}
              label="閉じる"
            />
            {invoice && details && (
              <PDFDownload
                invoice={invoice}
                details={details}
                mycompany={selfInfomation}
                name={sheetName}
              />
            )}
          </>
        }
      />
      <FlexBox>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>請求書番号</TableCell>
                <TableCell>取引先</TableCell>
                <TableCell>期間</TableCell>
                <TableCell>件数</TableCell>
                <TableCell>請求金額</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices?.map((invoice) => {
                return (
                  <TableRow
                    key={`invoice-${invoice.id}`}
                    onDoubleClick={() => handleInvoiceDoubleClick(invoice.id)}
                  >
                    <TableCell>
                      <Typography>{invoice.invoice_number}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{invoice.c_name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{invoice.title}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="number">
                        {invoice.detail_count}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className="number">
                        {invoice.billing_amount}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <PageNation
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={(e: React.ChangeEvent<unknown>, value: number) =>
              getSearch(value)
            }
          />
        </TableContainer>
      </FlexBox>
    </FlexColumnBox>
  );
}
