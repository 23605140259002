import { typeColumn, typeRow } from "const/index";
import { ListItem } from "types/index";

export const transportColumns: typeColumn[] = [
  { field: "datetime", headerName: "日時", width: 150, editable: true },
  { field: "name", headerName: "名称", width: 150, editable: true },
  { field: "prefecture", headerName: "都道府県", width: 150, editable: true },
  { field: "city", headerName: "市区町村", width: 150, editable: true },
  { field: "address", headerName: "番地", width: 150, editable: true },
  { field: "building", headerName: "建屋・その他", width: 150, editable: true },
];

export const transportRows: typeRow[] = [
  { label: "積地", field: "load" },
  { label: "卸地", field: "unload" },
];

export const expensesColumns: typeColumn[] = [
  { field: "category", headerName: "種類", width: 150, editable: true },
  { field: "unitPrice", headerName: "単価", width: 150, editable: true },
  { field: "quantity", headerName: "数量", width: 150, editable: true },
  { field: "amount", headerName: "金額（税抜）", width: 150, editable: true },
  { field: "memo", headerName: "メモ", width: 150, editable: true },
];
