import * as React from "react";
import { useEffect, useState } from "react";
import { ThemeProviderCustom } from "themes/Base";
import { useTheme } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import Header from "components/Header";
import SubMenu from "components/SubMenu";
import * as axiosCustom from "functions/axios";
import { Console_log } from "functions/index";
import { useNavigate } from "react-router-dom";
import { typeMenu } from "const/menu";
import isAuthenticated from "functions/api/authenticates";

interface MainProps {
  menu: typeMenu;
}
export default function Main({ menu }: MainProps) {
  //const [currentPage, setCurrentPage] = useState('');
  const navigate = useNavigate();

  // 認証チェック
  /*
  const isAuthenticated = () => {
    // トークンが有効か☑️
    let axios = axiosCustom.create();
    axios
      .get(axiosCustom.API_URL.checkToken, { params: {} })
      .then((res: any) => {
        Console_log("get:checkToken", res.data);
        if (res.status !== 200) {
          // エラーがある場合は、ログアウトしてログインページにリダイレクト
          navigate("/logout");
        }
      })
      .catch((error: any) => {
        Console_log("error checkToken", error);
        // エラーがある場合は、ログアウトしてログインページにリダイレクト
        navigate("/logout");
      });
  };
  */

  const theme = useTheme();

  const styles = {
    headerContainer: {
      width: "100%",
      maxHeight: "40px",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    headerMenu: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "flex-start", // 左寄せに変更
      gap: theme.spacing(1), // リストアイテム間のスペースを追加（必要に応じて調整）
      padding: "0 16px", // 左右にパディングを追加（必要に応じて調整）
    },
    main: {
      height: "calc(100vh - 40px)",
      display: "flex",
      justifyContent: "space-between",
    },
    subMenu: {
      display: "flex",
      justifyContent: "space-between",
    },
    content: {
      flexGrow: 1 /* 残りの横幅を均等に分配する */,
      flexBasis: 0,
      minWidth: 0 /* 必要に応じて、文字がはみ出ないようにするために設定 */,
      padding: theme.spacing(2),
      overflow: "scroll",
    },
  };
  useEffect(() => {
    isAuthenticated({});
  }, []);

  return (
    <ThemeProviderCustom>
      <Header />
      <main style={styles.main}>
        <SubMenu menu={menu} />
        <div style={styles.content}>
          <Outlet />
        </div>
      </main>
    </ThemeProviderCustom>
  );
}
