import { tTransport } from "types/transport";
import { waypointKbn, waypointKbnLoad, waypointKbnUnload } from "const/index";
import { tAddress, tCompany, tProjectKind } from "./mst";
import { tInstruction } from "./instruction";

export type tWaypoint = {
  kbn: number; // 種別
  datetime: string; // 日時
  a_id?: tAddress["id"]; // 住所ID
  a_name: tAddress["name"]; // 住所名
  prefectures: tAddress["prefectures"]; // 都道府県
  city: tAddress["city"]; // 市区町村
  street: tAddress["street"]; // 番地
  building: tAddress["street"]; // 建屋
  post_number: tAddress["post_number"]; // 郵便番号
  memo?: string; // メモ
};
export type tWaypoints = tWaypoint[];

export type tProject = {
  id: number; // ID
  domain: string; // ドメイン
  created_at: string; // created_at
  updated_at: string; // updated_at
  deleted_at?: string; // deleted_at

  status: number; // ステータス
  kind: number; // 種別
  c_id: number; // 会社マスタID
  p_id?: number; // 担当者ID
  load_date: string; // 積：日時
  load_a_id: tAddress["id"]; // 積：住所ID
  load_name: tAddress["name"]; // 積：名称
  load_prefectures: tAddress["prefectures"]; // 積：都道府県
  load_city: tAddress["city"]; // 積：市区町村
  load_street: tAddress["street"]; // 積：番地
  load_building: tAddress["building"]; // 積：建屋
  load_post_number: tAddress["post_number"]; // 積：郵便番号
  load_tell: tAddress["tell"]; // 積：電話番号
  load_fax?: tAddress["fax"]; // 積：FAX
  waypoints: tWaypoints; // 経由地点
  unload_date: string; // 卸：日時
  unload_a_id: tAddress["id"]; // 卸：住所ID
  unload_name: tAddress["name"]; // 卸：名称
  unload_prefectures: tAddress["prefectures"]; // 卸：都道府県
  unload_city: tAddress["city"]; // 卸：市区町村
  unload_street: tAddress["street"]; // 卸：番地
  unload_building: tAddress["building"]; // 卸：建屋
  unload_post_number: tAddress["post_number"]; // 卸：郵便番号
  unload_tell: tAddress["tell"]; // 卸：電話番号
  unload_fax?: tAddress["fax"]; // 卸：FAX
  vt_id: number; // 車種ID（車種マスタID）
  cars: number; // 台数
  weight: number; // 重量
  l_id: number; // 荷物種類ID（荷物種類マスタID）
  l_volume: number; // 数量
  memo: string; // 荷物メモ
  price_unit_reference: number; // 参考単価
  price_unit: number; // 単価
  price: number; // 金額（税抜）
  tax_id: string; // 消費税マスタID
  price_tax: number; // 金額（税込）
  internal_memo: string; // 内部メモ

  // 以下、新たに追加
  transports?: tTransport[]; // 新たに追加
  company?: tCompany; // 新たに追加
  load_address?: tAddress; // 新たに追加
  unload_address?: tAddress; // 新たに追加
  project_kind?: tProjectKind; // 新たに追加

  // 2024/08/15追加
  in_charge: number; // 担当者ID
  price_others: tPriceOther[]; // その他の金額
  price_others_sum: number; // その他の金額合計
};

/**
 * 初期値を生成する
 * @returns
 */
export const initProject: tProject = {
    id: 0,
    domain: "",
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    deleted_at: undefined,

    status: 0,
    kind: 0,
    c_id: 0,
    p_id: 0,
    load_date: new Date().toISOString(),
    load_a_id: 0,
    load_name: "",
    load_prefectures: "",
    load_city: "",
    load_street: "",
    load_building: "",
    load_post_number: "",
    load_tell: "",
    load_fax: undefined,
    waypoints: [],
    unload_date: new Date().toISOString(),
    unload_a_id: 0,
    unload_name: "",
    unload_prefectures: "",
    unload_city: "",
    unload_street: "",
    unload_building: "",
    unload_post_number: "",
    unload_tell: "",
    unload_fax: undefined,
    vt_id: 0,
    cars: 1,
    weight: 0.0,
    l_id: 0,
    l_volume: 0,
    memo: "",
    price_unit_reference: 0,
    price_unit: 0,
    price: 0,
    tax_id: "",
    price_tax: 0,
    internal_memo: "",
    in_charge: 0,
    price_others: [],
    price_others_sum: 0,
    /*
    transports: [],
    company: {},
    load_address: {},
    unload_address: {},
    project_kind: {},
    */
};

export const initialWaypoint: tWaypoint = {
  kbn: waypointKbnLoad.id,
  datetime: new Date().toISOString().split("T")[0] + " 00:00:00",
  a_id: undefined,
  a_name: "",
  prefectures: "",
  city: "",
  street: "",
  building: "",
  post_number: "",
  memo: "",
};
export const initWaypoints: tWaypoints = [
  { ...initialWaypoint },
  { ...initialWaypoint, kbn: waypointKbnUnload.id },
];

export type tProjectCard = {
  driver_id: number;
  pj_id: number;
  company_name: tCompany["name"];
  company_abbreviation: tCompany["abbreviation"];

  load_date: tProject["load_date"];
  load_id: tProject["load_a_id"];
  load_name: tProject["load_name"];
  load_name_mst: tProject["load_name"];
  load_abbreviation_mst: tProject["load_name"];

  unload_date: tProject["unload_date"];
  unload_id: tProject["unload_a_id"];
  unload_name: tProject["unload_name"];
  unload_name_mst: tProject["unload_name"];
  unload_abbreviation_mst: string;

  cars: tProject["cars"];
  waypoint_count: number;
  status: number;
  kind: number;
  kind_name: string;

  tmp_instructions: tInstruction[];
  instructions: tInstruction[];
};

export type tProjectCardList = tProjectCard[];

export type tPriceOther = { label: string; value: number }; // その他の金額

export type tProjectSearch = {
  status: tProject["status"][] | null;
  load_date_from: tProject["load_date"] | null;
  load_date_to: tProject["load_date"] | null;
  unload_date_from: tProject["unload_date"] | null;
  unload_date_to: tProject["unload_date"] | null;
  c_id: tCompany["id"] | null;
};
