import { tInstruction } from "types/instruction";

export const checkTranMethod = (
  instructions: tInstruction[],
  tmpInstructions: tInstruction[],
  tmId: number
) => {
  const margeInst = instructions.concat(tmpInstructions);

  return margeInst.find((inst) => inst.tm_id === tmId);
};
