import { create, API_URL, getCsrf } from "functions/axios";

/**
 * 一括取得
 * @returns
 */
export function getConsts() {
  const ins = create();
  return ins.get(API_URL.base + "/const/index", {});
}
