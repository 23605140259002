import React from "react";
import Modal from "atoms/Modal";
import { Grid, Typography } from "@mui/material";
import { SmallTextField } from "atoms/TextField";
import { MathDateTime, changeDateFromTypeDate } from "functions/time";
import { ItemSelect } from "components/Common";
import { DateInput } from "atoms/DateInput";
import { ClientCompany } from "components/project/Index";
import {
  projectStatus,
  projectEditStatus,
  projectStatusInstrunctionCreated,
} from "const/project/index";
import { PrimaryButton, SecondButton, LinkPrimaryButton } from "atoms/Button";
import { tProject, tProjectSearch } from "types/project";

interface GridItemFieldProps {
  values: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
  size?: Record<string, number>;
}

interface ModalSearchProps {
  terms: tProjectSearch | any;
  setTerms: React.Dispatch<React.SetStateAction<tProjectSearch | any>>;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  callback: (terms: tProjectSearch) => void;
}

/**
 * 検索などで使う項目
 */
export default function Main({
  terms,
  setTerms,
  open,
  setOpen,
  callback,
}: ModalSearchProps) {
  return (
    <Modal
      title={"検索：案件情報"}
      content={
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <RangeLoadDatetime values={terms} setValues={setTerms} />
          </Grid>
          <ClientCompany
            values={terms}
            setValues={setTerms}
            size={{ xs: 12, lg: 4 }}
          />
          {/*
        <Status values={search} setValues={setSearch} size={{ xs: 12, lg: 4 }} />
        */}
          {/*
        <Status values={search} setValues={setSearch} size={{ xs: 12, lg: 4 }} />
        */}
        </Grid>
      }
      actions={
        <>
          <PrimaryButton
            label="検索"
            onClick={() => {
              callback(terms);
              setOpen(false);
            }}
          />
          <SecondButton onClick={() => setOpen(false)} label={"閉じる"} />

          <LinkPrimaryButton label="TOPへ" href={"/"} />
        </>
      }
      open={open}
      onClose={() => setOpen(false)}
    />
  );
}

/**
 * 初回積日時範囲
 * @param values
 * @param setValues
 * @returns
 */
export function RangeLoadDatetime({
  values,
  setValues,
}: {
  values: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
}) {
  return (
    <>
      <Typography variant="h6">積日範囲</Typography>
      <Grid container spacing={1}>
        <Grid item>
          <DateInput
            type="date"
            value={values.load_date_from || ""}
            name="load_date_from"
            onDateChange={(newDate: string) => {
              const Add7Day = MathDateTime(
                new Date(newDate),
                [0, 0, 7, 0, 0, 0]
              );
              setValues((prevValues: any) => ({
                ...prevValues,
                load_date_from: newDate,
                load_date_to: changeDateFromTypeDate(Add7Day),
              }));
            }}
          />
        </Grid>
        <Grid item>
          <Typography sx={{ textAlign: "center" }}>〜</Typography>
        </Grid>
        <Grid item>
          <DateInput
            type="date"
            value={values.load_date_to || ""}
            name="load_date_to"
            onDateChange={(newDate: string) => {
              setValues({ ...values, load_date_to: newDate });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

/**
 * 最終卸日時範囲
 * @param values
 * @param setValues
 * @returns
 */
export function RangeUnloadDatetime({
  values,
  setValues,
}: {
  values: any;
  setValues: React.Dispatch<React.SetStateAction<any>>;
}) {
  return (
    <>
      <Typography variant="h6">卸日範囲</Typography>
      <Grid container spacing={1}>
        <Grid item>
          <DateInput
            type="date"
            value={values.unload_date_from || ""}
            name="unload_date_from"
            onDateChange={(newDate: string) => {
              const Add7Day = MathDateTime(
                new Date(newDate),
                [0, 0, 7, 0, 0, 0]
              );
              setValues((prevValues: any) => ({
                ...prevValues,
                unload_date_from: newDate,
                unload_date_to: changeDateFromTypeDate(Add7Day),
              }));
            }}
          />
        </Grid>
        <Grid item>
          <Typography sx={{ textAlign: "center" }}>〜</Typography>
        </Grid>
        <Grid item>
          <DateInput
            type="date"
            value={values.unload_date_to || ""}
            name="unload_date_to"
            onDateChange={(newDate: string) => {
              setValues({ ...values, unload_date_to: newDate });
            }}
          />
        </Grid>
      </Grid>
    </>
  );
}

export const Status = ({
  values,
  setValues,
  size = undefined,
}: GridItemFieldProps) => {
  const options = projectStatus;

  return (
    <ItemSelect
      labelId="status-label"
      id="status"
      label="ステータス"
      selectedValue={values.status}
      items={options}
      setValues={setValues}
      size={size}
    />
  );
};
