import { useEffect, useState } from "react";
import { tLuggage } from "types/mst";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { getCsrf } from "functions/axios";
import {
  getLuggage,
  updateLuggage,
  postLuggage,
  deleteLuggage,
} from "functions/api/mst";
import { GridItemTextField } from "components/GridItem";
import { PrimaryButton } from "atoms/Button";

export default function Main() {
  const { id } = useParams();
  const [luggage, setLuggage] = useState<tLuggage>({
    id: 0,
    name: "",
    unit: "",
    volume: 0,
    cm_ids: [],
  });

  useEffect(() => {
    const fetchData = async (id: number) => {
      try {
        await getCsrf();
        getLuggage(id)
          .then((res) => {
            if (res.status !== 200) throw new Error("データ取得に失敗しました");
            setLuggage(res.data);
          })
          .catch((err) => {
            console.error(err);
          });
      } catch (error) {
        console.error(error);
      }
    };
    if (id) {
      fetchData(Number(id));
    }
  }, [id]);

  const setPostRequestUpdate = () => {
    updateLuggage(luggage)
      .then((res) => {
        if (res.status !== 200) throw new Error("データ更新に失敗しました");
        alert("データを更新しました");
        window.location.href = "/mst/luggage";
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const setPostRequestStore = () => {
    postLuggage(luggage)
      .then((res) => {
        if (res.status !== 200) throw new Error("データ更新に失敗しました");
        alert("データを更新しました");
        window.location.href = "/mst/luggage";
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const setPostRequestDelete = () => {
    if (!window.confirm("削除しますか？")) return;
    deleteLuggage(luggage.id)
      .then((res) => {
        if (res.status !== 200) throw new Error("データ削除に失敗しました");
        alert("データを削除しました");
        window.location.href = "/mst/luggage";
      })
      .catch((err) => {
        console.error(err);
      });
  };

  //if (!id) return <div>Error! No id.</div>;
  //if (!luggage) return <div>loading...</div>;

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {id ? (
              <>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton onClick={setPostRequestUpdate} label="更新" />
                </Grid>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton onClick={setPostRequestDelete} label="削除" />
                </Grid>
              </>
            ) : (
              <Grid item xs={4} md={4} lg={2}>
                <PrimaryButton onClick={setPostRequestStore} label="登録" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <GridItemTextField
          name={"name"}
          label="名称"
          value={luggage.name}
          handleChangeValues={(e) =>
            setLuggage({ ...luggage, name: e.target.value })
          }
        />
        <GridItemTextField
          name={"unit"}
          label="単位"
          value={luggage.unit}
          handleChangeValues={(e) =>
            setLuggage({ ...luggage, unit: e.target.value })
          }
        />
        <GridItemTextField
          name={"volume"}
          label="容量"
          value={luggage.volume}
          handleChangeValues={(e) =>
            setLuggage({ ...luggage, volume: Number(e.target.value) })
          }
        />
      </Grid>
    </div>
  );
}
