import { create, API_URL, getCsrf } from "functions/axios";
import { tInstruction, tInstructionCard } from "types/instruction";
import { tTransportKey } from "types/transport";

///////////////////////////// temp系

/**
 * 運行指示マスタ（仮）
 * @returns
 */
export function getTempInstructionList(
  callbackSuccess: (data: tInstruction[]) => void,
  callbackError: () => void,
  filter: any = {},
  order: any = {}
) {
  if (order.length === 0) {
    order = [
      { field: "pj_id", direction: "asc" },
      { field: "no", direction: "asc" },
      { field: "order", direction: "asc" },
    ];
  }
  const ins = create();
  return ins
    .get(API_URL.base + "/instruction/temp/list", {
      params: { filter: filter, order: order },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("データの取得に失敗しました");
      }
      console.log("getTempInstructionList", res.data);
      callbackSuccess(res.data);
    })
    .catch((err) => {
      console.error(err);
      alert("データの取得に失敗しました");
      callbackError();
    });
}

export function getTempInstructionListByDriver(
  filter: any = {},
  order: any = {}
) {
  if (order.length === 0) {
    order = [
      { field: "id", direction: "asc" },
      { field: "tmpInstructions.start_datetime", direction: "asc" },
      { field: "tmpInstructions.project.load_datetime", direction: "asc" },
    ];
  }
  const ins = create();
  return ins.get(API_URL.base + "/instruction/temp/list/by-driver", {
    params: { filter: filter, order: order },
  });
}

/**
 * 運行指示マスタ（仮）新規登録
 * @returns
 */
export function storeTempInstructions(data: tInstruction[] = []) {
  const ins = create();
  return ins.post(API_URL.base + "/instruction/temp/store", data);
}

/**
 * 運行指示マスタ（仮）新規登録
 * @returns
 */
export function confirmTempInstructions(data: tTransportKey[] = []) {
  const ins = create();
  return ins.post(API_URL.base + "/instruction/temp/confirm", data);
}

/**
 * 運行指示マスタ（仮）新規登録
 * @returns
 */
export function destroyTempInstructions(data: tTransportKey) {
  const ins = create();
  return ins.post(API_URL.base + "/instruction/temp/destroys", data);
}

///////////////////////////// 本データ系

/**
 * 運行指示マスタ（仮）
 * @returns
 */
export function getInstructionList(
  callbackSuccess: (data: tInstruction[]) => void,
  callbackError: () => void,
  filter: any = {},
  order: any = {},
  withs: any = []
) {
  if (order.length === 0) {
    order = [
      { field: "pj_id", direction: "asc" },
      { field: "no", direction: "asc" },
      { field: "order", direction: "asc" },
    ];
  }
  if (withs.length === 0) {
    withs = ["project"];
  }
  const ins = create();
  return ins
    .get(API_URL.base + "/instruction/list", {
      params: { filter: filter, order: order, withs: withs },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("データの取得に失敗しました");
      }
      console.log("getInstructionList", res.data);
      callbackSuccess(res.data);
    })
    .catch((err) => {
      console.error(err);
      alert("データの取得に失敗しました");
      callbackError();
    });
}

/**
 * 運行指示マスタ（仮）
 * @returns
 */
export function getInstructionCards(
  callbackSuccess: (data: tInstructionCard[]) => void,
  callbackError: () => void,
  filter: any = {},
  order: any = {},
  withs: any = []
) {
  if (order.length === 0) {
    order = [
      { field: "pj_id", direction: "asc" },
      { field: "no", direction: "asc" },
      { field: "order", direction: "asc" },
    ];
  }
  if (withs.length === 0) {
    withs = ["project"];
  }
  const ins = create();
  return ins
    .get(API_URL.base + "/instruction/card/list", {
      params: { filter: filter, order: order, withs: withs },
    })
    .then((res) => {
      if (res.status !== 200) {
        throw new Error("データの取得に失敗しました");
      }
      console.log("getInstructionCards", res.data);
      callbackSuccess(res.data);
    })
    .catch((err) => {
      console.error(err);
      alert("データの取得に失敗しました");
      callbackError();
    });
}
