import React, { useState, useEffect } from "react";
import PJDetail from "components/project/Detail";
import Modal from "atoms/Modal";
import {
  tProject,
  tWaypoints,
  initProject,
  initWaypoints,
} from "types/project";
import * as PJButton from "components/project/Button";
import { SecondButton } from "atoms/Button";
import { getProject } from "functions/api/project";

interface MainProps {
  open: boolean;
  onClose: () => void;
  pjId: number;
  initCustom?: tProject;
  callbackNomal: () => void;
  callbackError: () => void;
}

export default function Main({
  open,
  onClose,
  pjId,
  initCustom = initProject,
  callbackNomal,
  callbackError,
}: MainProps) {
  const [project, setProject] = useState<tProject>(initCustom);
  const [waypoints, setWaypoints] = useState<tWaypoints>(initWaypoints);

  useEffect(() => {
    console.log("initCustom", initCustom);
    if (pjId) {
      getProject(pjId)
        .then((res) => {
          setProject(res.data);
          setWaypoints(res.data.waypoints);
        })
        .catch((err) => {
          console.error(err);
          alert("データの取得に失敗しました");
        });
    } else {
      setProject(initCustom);
      setWaypoints(initCustom.waypoints);
    }
  }, [pjId, initCustom]);

  return (
    <Modal
      title={`案件情報`}
      content={
        <PJDetail
          values={project}
          setValues={setProject}
          waypoints={waypoints}
          setWaypoints={setWaypoints}
        />
      }
      open={open}
      onClose={onClose}
      actions={
        <>
          {pjId ? (
            <>
              <PJButton.Update
                projectInfo={project}
                waypoints={waypoints}
                callbackNomal={callbackNomal}
                callbackError={callbackError}
              />
              <PJButton.ModalInstruction pjId={pjId} />
            </>
          ) : (
            <PJButton.Insert
              label={"登録"}
              projectInfo={project}
              waypoints={waypoints}
              callbackNomal={callbackNomal}
              callbackError={callbackError}
            />
          )}
          <SecondButton onClick={onClose} label={"閉じる"} />
        </>
      }
    />
  );
}
