import { useEffect, useState } from "react";
import * as axios from "functions/axios";
import { Console_log } from "functions/index";

import Link from "@mui/material/Link";
import LoginFrame from "frames/LoginFrame";

export default function Expenses() {
  const [useMessage, setMessage] = useState("ログアウト中");

  // 初回レンダリング後と、useした値の更新後に自動で実行
  useEffect(() => {
    let mount = true; //マウントされているか
    if (mount) {
      axios.logout(function (res: any) {
        Console_log("callback", res);
        if (res.status === 200) {
          setMessage("ログアウトに成功しました。");
        } else {
          setMessage("ログアウトに失敗しました。");
        }
      });
    }

    return () => {
      mount = false;
    }; //クリーンアップでマウントフラグをfalseに
  }, []);

  return (
    <LoginFrame style={{ padding: "1rem 0" }}>
      <h2>{useMessage}</h2>
      <Link href={"/login"}>login</Link>
    </LoginFrame>
  );
}
