import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  Popover,
  Typography,
  Button,
  Grid,
  Container,
} from "@mui/material";
import { useSelection } from "contexts/Schedules";
import { truncateString } from "functions/index";
import { isSameDate } from "functions/time";
import { tTransportCard } from "types/transport";
import { MailOutlineSharp } from "@mui/icons-material";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  tInstruction,
  tInstructionCard,
  cardLabelType,
} from "types/instruction";
import { strDateTimeOrigin } from "functions/time";
import { MstDataContext } from "contexts/Mst";
import { tTransportMethod } from "types/mst";
import BaseCard, { CardContents } from "atoms/Card";
import { Toolchip } from "atoms/Toolchip";
import { cardType } from "types/index";

/**
 * 運行指示作成用輸送情報カード
 * @param param0
 * @returns
 */
export const Nomal = forwardRef<
  HTMLDivElement,
  {
    instruction: tInstructionCard;
    callbackClick: (event: React.MouseEvent<HTMLElement>) => void;
    flgOmit?: any;
    ref?: any;
    selected?: boolean;
    children?: React.ReactNode;
    type: cardType;
    labelType?: cardLabelType;
  }
>((props, ref) => {
  const {
    instruction,
    callbackClick,
    flgOmit = true,
    children,
    selected = false,
    type = "box",
    labelType = "index",
  } = props;
  const navigate = useNavigate();

  const { SYSTEM, tranMethods } = useContext(MstDataContext);

  const handleCardDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation(); // 親への伝播を止める
    if (flgOmit) {
      return;
    }
    //navigate(`/project/edit/${transportCard.pj_id}`);
  };

  return (
    <BaseCard
      ref={ref}
      selected={selected}
      flgOmit={flgOmit}
      type={type}
      callbackDoubleClick={handleCardDoubleClick}
      callbackClick={callbackClick}
      otherClassName={`size-auto`}
    >
      {children}
      <Labels type={labelType} flgOmit={flgOmit} instruction={instruction} />
      <AttributeChip instruction={instruction} flgOmit={flgOmit} />
    </BaseCard>
  );
});

/**
 * 輸送情報カード(空)
 * @param param0
 * @returns
 */
export const Blank = forwardRef<
  HTMLDivElement,
  {
    label: string;
    callbackDoubleClick: (event: React.MouseEvent<HTMLElement>) => void;
    callbackClick: (event: React.MouseEvent<HTMLElement>) => void;
    flgOmit?: any;
    ref?: any;
    isOver: boolean;
  }
>((props, ref) => {
  const {
    label,
    callbackDoubleClick,
    callbackClick,
    flgOmit = true,
    isOver = false,
  } = props;

  const handleCardDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    callbackDoubleClick(event);
  };

  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    callbackClick(event);
  };

  return (
    <Box className={`InstructionCardBlank`}>
      <BaseCard
        ref={ref}
        flgOmit={flgOmit}
        callbackDoubleClick={handleCardDoubleClick}
        callbackClick={handleCardClick}
        className={"blank"}
        isOver={isOver}
      >
        {!flgOmit && <Typography className="label">{`${label}`}</Typography>}
      </BaseCard>
    </Box>
  );
});

/**
 * ツールチップ付き運行指示作成用輸送情報カード
 */
export const WithToolchip = forwardRef<
  HTMLDivElement,
  {
    instruction: tInstructionCard;
    flgOmit?: any;
    ref?: any;
    selected?: boolean;
    cardChildren?: React.ReactNode;
    toolchipChildren: React.ReactNode;
    type?: cardType;
    labelType?: cardLabelType;
  }
>((props, ref) => {
  const {
    instruction,
    flgOmit = true,
    selected = false,
    cardChildren,
    toolchipChildren,
    type = "box",
    labelType = "index",
  } = props;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  //console.log("InstructionCardWithToolchip", instruction);

  /**
   * クリック時の処理
   * @param event
   */
  const handleCardClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
    // event.currentTargetを変数に保存する
    const targetElement = event.currentTarget;

    setAnchorEl(targetElement); // クリックした要素をアンカーに設定
  };

  const handleCardDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (flgOmit) {
      return;
    }
  };

  const handleToolchipClose = () => {
    setAnchorEl(null); // ポップオーバーを閉じる
  };

  return (
    <>
      <BaseCard
        ref={ref}
        selected={selected}
        flgOmit={flgOmit}
        type={type}
        callbackDoubleClick={handleCardDoubleClick}
        callbackClick={handleCardClick}
        otherClassName={`size-auto`}
      >
        {cardChildren}
        <Labels type={labelType} flgOmit={flgOmit} instruction={instruction} />
        <AttributeChip instruction={instruction} flgOmit={flgOmit} />
      </BaseCard>
      <Toolchip
        open={open}
        anchorEl={anchorEl}
        handleClose={handleToolchipClose}
      >
        {toolchipChildren}
      </Toolchip>
    </>
  );
});

const Labels = ({
  instruction,
  type,
  flgOmit,
}: LabelsTypeIndexProps & { type: cardLabelType }) => {
  switch (type) {
    case "index":
      return <LabelsTypeIndex instruction={instruction} flgOmit={flgOmit} />;
    case "driver":
      return <LabelsTypeDriver instruction={instruction} flgOmit={flgOmit} />;
    case "charter":
      return <LabelsTypeCharter instruction={instruction} flgOmit={flgOmit} />;
    case "method":
      return <LabelsTypeMethoed instruction={instruction} flgOmit={flgOmit} />;
    default:
      return <></>;
  }
};

interface LabelsTypeIndexProps {
  instruction: tInstructionCard;
  flgOmit: boolean;
}
const LabelsTypeIndex = ({ instruction, flgOmit }: LabelsTypeIndexProps) => {
  const { SYSTEM, tranMethods } = useContext(MstDataContext);

  /**
   * 運行指示カードのタイトル
   * @returns
   */
  const titleLabel = () => {
    if (instruction.tm_id === SYSTEM?.tranMethod.own.id) {
      return `${SYSTEM?.tranMethod.own.label}(${instruction.driver_name})`;
    } else if (instruction.tm_id === SYSTEM?.tranMethod.charter.id) {
      return `${SYSTEM?.tranMethod.charter.label}(${instruction.c_abbreviation})`;
    } else {
      const obj = tranMethods?.find(
        (tm: tTransportMethod) => tm.id === instruction.tm_id
      );
      return `${obj?.abbreviation}`;
    }
  };

  if (!flgOmit) {
    return (
      <>
        <Typography>{titleLabel()}</Typography>
        <Box>
          <Typography>{`${strDateTimeOrigin(
            new Date(instruction.start_datetime)
          )}`}</Typography>
          <Typography>
            {`${instruction.start_name}`}
            {`(${instruction.start_address})`}
          </Typography>
        </Box>

        <Box>
          <Typography>{`${strDateTimeOrigin(
            new Date(instruction.end_datetime)
          )}`}</Typography>
          <Typography>
            {`${instruction.end_name}`}
            {`(${instruction.end_address})`}
          </Typography>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

const LabelsTypeDriver = ({ instruction, flgOmit }: LabelsTypeIndexProps) => {
  const { SYSTEM, tranMethods } = useContext(MstDataContext);

  const statusObj = SYSTEM?.instruction.status.list?.find((item) => {
    return item.id === instruction.status;
  });

  if (!flgOmit) {
    return (
      <>
        <Box className={"contents"}>
          <Typography className={`status-${statusObj?.id}`}>
            {statusObj?.label}
          </Typography>
        </Box>
        <Box className={"contents"}>
          <Typography>
            {`${instruction.start_name}`}
            {`(${instruction.start_address})`}
          </Typography>

          <Typography>
            {`${instruction.end_name}`}
            {`(${instruction.end_address})`}
          </Typography>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

const LabelsTypeCharter = ({ instruction, flgOmit }: LabelsTypeIndexProps) => {
  const { SYSTEM } = useContext(MstDataContext);

  const statusObj = SYSTEM?.instruction.status.list?.find((item) => {
    return item.id === instruction.status;
  });

  /**
   * 運行指示カードのタイトル
   * @returns
   */
  const titleLabel = () => {
    return `${instruction.c_abbreviation}(${instruction.c_vehicle})`;
  };
  if (!flgOmit) {
    return (
      <>
        <Box className={"contents"}>
          <Typography className={`status-${statusObj?.id}`}>
            {statusObj?.label}
          </Typography>
          <Typography>{titleLabel()}</Typography>
        </Box>
        <Box className={"contents"}>
          <Typography>
            {`${instruction.start_name}`}
            {`(${instruction.start_address})`}
          </Typography>

          <Typography>
            {`${instruction.end_name}`}
            {`(${instruction.end_address})`}
          </Typography>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

const LabelsTypeMethoed = ({ instruction, flgOmit }: LabelsTypeIndexProps) => {
  const { SYSTEM, tranMethods } = useContext(MstDataContext);

  const statusObj = SYSTEM?.instruction.status.list?.find((item) => {
    return item.id === instruction.status;
  });

  /**
   * 運行指示カードのタイトル
   * @returns
   */
  const titleLabel = () => {
    const obj = tranMethods?.find(
      (tm: tTransportMethod) => tm.id === instruction.tm_id
    );
    return `${obj?.abbreviation}`;
  };

  if (!flgOmit) {
    return (
      <>
        <Box className={"contents"}>
          <Typography className={`status-${statusObj?.id}`}>
            {statusObj?.label}
          </Typography>
          <Typography>{titleLabel()}</Typography>
        </Box>
        <Box className={"contents"}>
          <Typography>
            {`${instruction.start_name}`}
            {`(${instruction.start_address})`}
          </Typography>
          <Typography>
            {`${instruction.end_name}`}
            {`(${instruction.end_address})`}
          </Typography>
        </Box>
      </>
    );
  } else {
    return <></>;
  }
};

const AttributeChip = ({
  instruction,
  flgOmit,
}: LabelsTypeIndexProps): React.ReactElement => {
  const sameDate = isSameDate(
    instruction.start_datetime,
    instruction.end_datetime
  );

  return (
    <Box className="attribute-chip">
      {sameDate && <Typography className="chip evening">当</Typography>}
    </Box>
  );
};
