import React, { createContext, useContext, useState, ReactNode } from "react";
import { tTransportCard } from "types/transport";

// グローバル状態のContextを作成
const SelectionContext = createContext<{
  transportCards: tTransportCard[];
  addSelection: ({ transportCard }: { transportCard: tTransportCard }) => void;
  removeSelection: ({
    transportCard,
  }: {
    transportCard: tTransportCard;
  }) => void;
} | null>(null);

// グローバル状態を管理するProviderコンポーネント
export const SelectionProvider = ({ children }: { children: ReactNode }) => {
  const [transports, setTransports] = useState<tTransportCard[]>([]);

  const addSelection = ({
    transportCard,
  }: {
    transportCard: tTransportCard;
  }) => {
    setTransports((prev) => [...prev, transportCard]);
  };

  const removeSelection = ({
    transportCard,
  }: {
    transportCard: tTransportCard;
  }) => {
    setTransports((prev) =>
      prev.filter((t) => t.pj_id !== transportCard.pj_id)
    );
  };

  return (
    <SelectionContext.Provider
      value={{ transportCards: transports, addSelection, removeSelection }}
    >
      {children}
    </SelectionContext.Provider>
  );
};

// グローバル状態を利用するためのカスタムフック
export const useSelection = () => {
  const context = useContext(SelectionContext);
  if (!context) {
    throw new Error("useSelection must be used within a SelectionProvider");
  }
  return context;
};
