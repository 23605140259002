import React, { useState, useEffect } from "react";
import { tVehicleType, initialVehicleType } from "types/mst";
import { Grid } from "@mui/material";
import * as cVehicleType from "components/mst/VehicleType";
import { useParams } from "react-router-dom";
import {
  getVehicleType,
  storeVehicleType,
  updateVehicleType,
  deleteVehicleType,
} from "functions/api/mst";
import { PrimaryButton } from "atoms/Button";

export default function Main() {
  const { id } = useParams();
  const [data, setData] = useState<tVehicleType>(initialVehicleType);

  useEffect(() => {
    const fetchData = async (id: number) => {
      try {
        getVehicleType(id).then((res) => {
          if (res.status !== 200) throw new Error("データ取得に失敗しました");
          setData(res.data);
        });
      } catch (error) {
        console.error(error);
      }
    };
    if (id) {
      fetchData(Number(id));
    }
  }, [id]);

  // マスタ更新
  const setPostRequestUpdate = () => {
    updateVehicleType(data)
      .then((res) => {
        if (res.status !== 200) throw new Error("データ更新に失敗しました");
        alert("データを更新しました");
        //window.location.href = "/mst/vehicleType";
      })
      .catch((err) => {
        console.error(err);
        alert("データ更新に失敗しました");
      });
  };

  // マスタ新規登録
  const setPostRequestStore = () => {
    storeVehicleType(data)
      .then((res) => {
        if (res.status !== 200) throw new Error("データ登録に失敗しました");
        alert("データを登録しました");
        window.location.href = "/mst/vehicleType";
      })
      .catch((err) => {
        console.error(err);
        alert("データ登録に失敗しました");
      });
  };

  // マスタ削除
  const setPostRequestDelete = () => {
    if (!window.confirm("削除しますか？")) return;
    deleteVehicleType(data.id)
      .then((res: any) => {
        if (res.status !== 200) throw new Error("データ削除に失敗しました");
        alert("データを削除しました");
        window.location.href = "/mst/vehicleType";
      })
      .catch((err) => {
        console.error(err);
        alert("データ削除に失敗しました");
      });
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Grid container spacing={4}>
            {id ? (
              <>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton onClick={setPostRequestUpdate} label="更新" />
                </Grid>
                <Grid item xs={4} md={4} lg={2}>
                  <PrimaryButton onClick={setPostRequestDelete} label="削除" />
                </Grid>
              </>
            ) : (
              <Grid item xs={4} md={4} lg={2}>
                <PrimaryButton onClick={setPostRequestStore} label="登録" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <cVehicleType.Name values={data} setValues={setData} />
        <cVehicleType.FlgTrailer values={data} setValues={setData} />
        <cVehicleType.Description values={data} setValues={setData} />
      </Grid>
    </div>
  );
}
