import { APP_ENV } from "functions/env";

export function Console_log(label: String, data: any): void {
  if (APP_ENV() != "production") {
    console.log(label);
    console.log(data);
  }
}

export function getGridSize(size: Record<string, number> | null | undefined) {
  let xs = 12;
  let sm = 6;
  let md = 4;
  let lg = 3;
  let xl = 2;
  if (size?.xs) {
    xs = size.xs;
  }
  if (size?.sm) {
    sm = size.sm;
  }
  if (size?.md) {
    md = size.md;
  }
  if (size?.lg) {
    lg = size.lg;
  }
  if (size?.xl) {
    xl = size.xl;
  }
  return [xs, sm, md, lg, xl];
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function toHalfWidth(str: string): string {
  return str
    .replace(/[！-～]/g, (s: string) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    })
    .replace(/　/g, " ")
    .replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s: string) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
}

// 3桁区切りにする関数
export const formatNumber = (numStr: string) => {
  const parts = numStr.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

/**
 * 文字列を指定文字数で省略する
 * @param str
 * @param maxLength
 * @returns
 */
export function truncateString(str: string, maxLength: number): string {
  if (str.length <= maxLength) {
    return str;
  }
  return str.slice(0, maxLength) + "...";
}

/**
 * 連想配列のキーでグループ化する
 * @param array
 * @param key
 * @returns
 */
export function groupBy<T>(array: T[], key: keyof T): { [key: string]: T[] } {
  return array.reduce((result, item) => {
    const groupKey = item[key] as string;

    if (!result[groupKey]) {
      result[groupKey] = [];
    }

    result[groupKey].push(item);

    return result;
  }, {} as { [key: string]: T[] });
}

/**
 * オブジェクトが空かどうかを判定する
 * @param obj
 * @returns
 */
export const isEmptyObject = (obj: Object) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};
