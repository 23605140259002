import React, { useState } from "react";
import { Button } from "@mui/material";
import Modal from "atoms/Modal";
import InstructionDetail from "components/instruction/Detail";
import { tProject, tWaypoints } from "types/project";
import {
  updateProject,
  checkUpdateProject,
  setProject,
  deleteProject,
} from "functions/api/project";
import { tErrMsg, ErrMsgBox } from "components/ErrorMessage";
import { validationCheck } from "functions/project/validate";
import { PrimaryButton, SecondButton } from "atoms/Button";
import { tTransport } from "types/transport";
import { isEmptyObject } from "functions/index";
import ErrModal from "components/ErrModal";
import { getTransportList } from "functions/api/transport";
import InstructionModal from "components/instruction/Modal";
import initInstruction from "const/instruction";

interface MainProps {
  projectInfo: tProject;
  waypoints: tWaypoints;
  callbackNomal?: () => void;
  callbackError?: () => void;
}

/**
 * 新規登録処理
 * @param flgReuse
 * @returns
 */
export const Insert = ({
  label,
  projectInfo,
  waypoints,
  callbackNomal,
  callbackError,
}: { label: string } & MainProps) => {
  const [showModal, setShowModal] = useState(false); // モーダルの表示状態
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  // エラーチェック＆登録処理
  const handleClick = () => {
    const validationErrors = validationCheck(projectInfo, waypoints);

    if (!isEmptyObject(validationErrors)) {
      setValidationMsg(validationErrors);
      setShowModal(true); // エラーがある場合モーダル表示
      return;
    }

    // エラーがない場合、積卸地の日時を設定して登録処理
    projectInfo.load_date = waypoints[0].datetime;
    projectInfo.unload_date = waypoints[waypoints.length - 1].datetime;

    setProject(projectInfo, waypoints)
      .then((res) => {
        if (res.status !== 200) throw new Error("登録に失敗しました");
        alert("登録しました");
        callbackNomal && callbackNomal();
      })
      .catch((err) => {
        alert("登録に失敗しました");
        callbackError && callbackError();
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <PrimaryButton onClick={handleClick} label={label} />
    </>
  );
};

/**
 * 更新処理
 * @returns
 */
export const Update = ({
  projectInfo,
  waypoints,
  callbackNomal,
  callbackError,
}: MainProps) => {
  const [showModal, setShowModal] = useState(false); // モーダルの表示状態
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  // エラーチェック＆登録処理
  const handleClick = () => {
    let validationMsg: tErrMsg = {};
    validationMsg = validationCheck(projectInfo, waypoints);
    if (!isEmptyObject(validationMsg)) {
      setValidationMsg(validationMsg);
      setShowModal(true); // エラーがある場合モーダル表示
      return;
    }

    // チェック処理
    checkUpdateProject(projectInfo, waypoints)
      .then((res) => {
        if (res.status !== 200) throw new Error("");

        try {
          const checkData = res.data;
          console.log(checkData);

          // メッセージがある場合のみ配列に追加
          const checkMsg: string[] = [];
          Object.keys(checkData).map((key) => {
            const obj = checkData[key];
            if (obj.status === false) {
              checkMsg.push(obj.message);
            }
          });

          // checkMsgが空でない場合にエラーメッセージをセット
          if (checkMsg.length > 0) {
            setValidationMsg({ 更新チェック: checkMsg });
            setShowModal(true); // エラーがある場合モーダル表示
            return;
          }

          // 更新処理
          updateProject(projectInfo, waypoints)
            .then((res) => {
              if (res.status !== 200) throw new Error("更新に失敗しました");
              alert("更新しました");
              if (callbackNomal) callbackNomal();
            })
            .catch((err) => {
              alert("更新に失敗しました");
              if (callbackError) callbackError();
            });
        } catch (error) {
          console.error(error);
        }
      })
      .catch((err) => {
        alert("更新チェック処理に失敗しました");
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <PrimaryButton onClick={handleClick} label="更新" />
    </>
  );
};

/**
 * 削除処理
 * @returns
 */
export const Delete = ({
  pjId,
  callbackNomal,
  callbackError,
}: {
  pjId: number;
  callbackNomal?: () => void;
  callbackError?: () => void;
}) => {
  const [showModal, setShowModal] = useState(false); // モーダルの表示状態
  const [validationMsg, setValidationMsg] = useState<tErrMsg>({});

  // エラーチェック＆登録処理
  const handleClick = () => {
    if (!window.confirm("削除しますか？")) return;
    deleteProject(pjId)
      .then((res: any) => {
        if (res.status !== 200) throw new Error("データ削除に失敗しました");
        alert("データを削除しました");
        if (callbackNomal) callbackNomal();
      })
      .catch((err) => {
        console.error(err);
        alert("データの削除に失敗しました。");
        if (callbackError) callbackError();
      });
  };

  return (
    <>
      {showModal && (
        <ErrModal
          errMsg={validationMsg}
          setErrMsg={(msg) => {
            setValidationMsg(msg);
            setShowModal(false); // モーダルを閉じる
          }}
        />
      )}
      <PrimaryButton onClick={handleClick} label="削除" />
    </>
  );
};

interface ModalInstructionProps {
  pjId: number;
}
export const ModalInstruction = ({ pjId }: ModalInstructionProps) => {
  const [flgInstModal, setFlgInstModal] = useState(false);
  const [selectTranNo, setSelectTranNo] = useState(1);
  const [flgInstSelectModal, setFlgInstSelectModal] = useState(false);
  const [transports, setTransports] = useState<tTransport[]>([]);

  const handleClick = () => {
    getTransportList({ pj_id: pjId })
      .then((res) => {
        if (res.status !== 200) throw new Error("API Error");
        setTransports(res.data);

        if (res.data.length === 1) {
          setFlgInstModal(true);
          setSelectTranNo(selectTranNo);
        } else {
          setFlgInstSelectModal(true);
        }
      })
      .catch((err) => {
        console.error(err);
        alert("運行指示情報の取得に失敗しました");
      });
  };

  return (
    <>
      <Modal
        title={`配車対象選択`}
        content={
          <>
            {transports.map((tran: tTransport) => (
              <Button
                key={`transport-${tran.no}`}
                onClick={() => {
                  setFlgInstSelectModal(false);
                  setSelectTranNo(tran.no);
                  setFlgInstModal(true);
                }}
              >
                {tran.no}
              </Button>
            ))}
          </>
        }
        open={flgInstSelectModal}
        onClose={() => setFlgInstSelectModal(false)}
        actions={
          <>
            <Button onClick={() => setFlgInstSelectModal(false)}>閉じる</Button>
          </>
        }
      />
      <InstructionModal
        open={flgInstModal}
        onClose={() => setFlgInstModal(false)}
        pjId={pjId}
        tranNo={selectTranNo}
        info={initInstruction}
        callbackNomal={() => {}}
        callbackError={() => {}}
      />
      <SecondButton label="配車手配" onClick={handleClick} />
    </>
  );
};
