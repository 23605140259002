import React from "react";
import { tTransportMethod } from "types/mst";
import * as cCommon from "components/Common";
import { days as closingDays } from "const/comapny/closingDate";
import { closingDateType } from "const/comapny/closingDateType";

interface GridItemFieldProps {
  values: tTransportMethod;
  setValues: React.Dispatch<React.SetStateAction<tTransportMethod>>;
}
export const Name = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="名称"
    name="name"
    value={values.name}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const Abbreviation = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="略称"
    name="abbreviation"
    value={values.abbreviation}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const ChipName = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTextFiled
    label="チップ名"
    name="name_chip"
    value={values.name_chip}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const StartTime = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTimeFiled
    label="開始時刻"
    name="start_time"
    value={values.start_time}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);

export const EndTime = ({ values, setValues }: GridItemFieldProps) => (
  <cCommon.ItemTimeFiled
    label="終了時刻"
    name="end_time"
    value={values.end_time}
    setValues={setValues}
    size={{ xs: 6, lg: 3, xl: 2 }}
  />
);
